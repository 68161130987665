import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useOffer from "../../api/hooks/useOffers";
import { logMsgBgColors } from "../../consts/misc";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { Influencer, Offer } from "../../interfaces";
/* import { offerRemappingFromResult } from "../../utils/offerRemapping"; */
import plus_icon from "../../assets/ico/plus.svg";
import { paths } from "../../consts/URLs";
import MainContext from "../../context/MainContext";
import { possible_statuses, subjects } from "../../consts/statuses";
import {
  influencerRemappingFromProposedList,
  listRemappedFromOfferProposedList,
  offerRemappingFromResult,
} from "../../utils/remapping";
import { forEachChild } from "typescript";

const InfluencerPageTopButton = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const { createOfferListHook, updateOfferListHook } = props;
  const { influencerSelector, offer, setOffer, setInfluencer, token } =
    useContext(MainContext);

  const { onInfluencerSelection, influencerList, setInfluencerList } =
    influencerSelector;

  const onDisableCondition =
    /* props.modelSelectedQuantity <= 0 || */
    influencerList.length <= 0 && offer?.proposed_list?.length === 0;

  const save = (list: Influencer[]) => {
    if (offer && offer?.id) {
      const id = offer.id;
      let finalObj: any = [];
      let influencers_array: any[] = [];
      if (list.length > 0) {
      }

      list.forEach((el) => {
        influencers_array.push({
          talent_id: el.id,
          status: possible_statuses.influencer.pending,
        });
      });

      finalObj = { proposed_talents: influencers_array };

      if (offer.proposed_list && offer.proposed_list?.length > 0) {
        /* set rejected all influencer not present in selected list */
        offer.proposed_list.forEach((item) => {
          const isPresent = list.some((t) => {
            return t.id === item.talent_id;
          });
          if (isPresent) {
          } else {
            /* console.log(finalObj, "UPDATE"); */
            updateOfferListHook.CTAs.updateOfferInfluencers(id, {
              proposed_talents: [
                {
                  talent_id: item.talent_id,
                  status: possible_statuses.influencer.rejected,
                },
              ],
            });
          }
        });

        /* set pending all influencer and insert into offer  */
        finalObj.proposed_talents.forEach((influencer: any) => {
          const isPresent = offer.proposed_list!.some((t) => {
            return t.talent_id === influencer.talent_id;
          });
          const objToSend = {
            proposed_talents: [
              {
                talent_id: influencer.talent_id,
                status: possible_statuses.influencer.pending,
              },
            ],
          };
          if (isPresent) {
            /*  console.log(finalObj, "UPDATE"); */
            updateOfferListHook.CTAs.updateOfferInfluencers(id, objToSend);
          } else {
            /* console.log(finalObj, "CREATE"); */
            createOfferListHook.CTAs.createOfferInfluencers(id, objToSend);
          }
        });
      } else if (offer.proposed_list && offer.proposed_list.length === 0) {
        /* console.log(finalObj, "CREATE"); */
        createOfferListHook.CTAs.createOfferInfluencers(id, finalObj);
      }
    }
  };

  /* ----------------CREATE influencer proposed list----------------- */
  useEffect(() => {
    /* console.log(
      createOfferListHook.ApiB.response,
      createOfferListHook.ApiB.result
    ); */
    if (createOfferListHook.ApiB.response?.ok) {
      const remappedOffer = offerRemappingFromResult(
        createOfferListHook.ApiB.result
          .talent /* .talent---have to be changed in ---.offer  */
      );
      setOffer(remappedOffer);
      setTimeout(() => {
        navigate(
          paths.pages.offer_detail.replace(
            ":id",
            createOfferListHook.ApiB.result.talent.id?.toString()
          )
        );
      }, 2000);
    }
  }, [createOfferListHook.ApiB.response]);
  /* ----------------------------------------------------------------------- */

  /* ----------------UPDATE influencer proposed list----------------- */
  useEffect(() => {
    /* console.log(
      updateOfferListHook.ApiB.response,
      updateOfferListHook.ApiB.result
    ); */
    if (updateOfferListHook.ApiB.response?.ok) {
      const remappedOffer = offerRemappingFromResult(
        updateOfferListHook.ApiB.result
          .talent /* .talent---have to be changed in ---.offer  */
      );
      setOffer(remappedOffer);
      setTimeout(() => {
        navigate(
          paths.pages.offer_detail.replace(
            ":id",
            updateOfferListHook.ApiB.result.talent.id?.toString()
          )
        );
      }, 2000);
    }
  }, [updateOfferListHook.ApiB.response]);
  /* ----------------------------------------------------------------------- */

  const handleAddModel = () => {
    setInfluencerList(influencerList);
    /* setInfluencerList([]); */
    save(influencerList);
  };

  const handleNewModel = () => {
    setInfluencer(null);
    navigate(paths.pages.influencer_edit);
  };

  return (
    <button
      className="add-button"
      style={{
        width: isMobile ? (onInfluencerSelection ? "56px" : "66px") : "",
      }}
      disabled={onInfluencerSelection && onDisableCondition}
      type="button"
      onClick={() => {
        onInfluencerSelection ? handleAddModel() : handleNewModel();
      }}
    >
      {!onInfluencerSelection ? (
        <div>
          {isMobile ? (
            <img className=" white" src={plus_icon} />
          ) : (
            <p className="add-buttonText"> Add Influencer</p>
          )}
        </div>
      ) : (
        <p className="add-buttonText">
          {`${isMobile ? "+ " : "Add"} ${props.modelSelectedQuantity} ${
            isMobile ? "" : "influencer"
          }${props.modelSelectedQuantity > 1 && !isMobile ? "s" : ""}`}
        </p>
      )}
    </button>
  );
};
export default InfluencerPageTopButton;
