import NavBar from "../../../../components/misc/NavBar";
import SideBar from "../../../../components/misc/SideBar";
import SettingsEditProfileModal from "../../../../components/modals/SettingsEditProfileModal";
import MainContext from "../../../../context/MainContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import useToggle from "../../../../hooks/useToggle";
import { useContext, useEffect } from "react";
import edit_icon from "../../../../assets/ico/edit.svg";
import back_icon from "../../../../assets/ico/small-left.svg";
import { possible_statuses, subjects } from "../../../../consts/statuses";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../consts/URLs";
import useUpdatePassord from "../../../../api/hooks/password/useUpdatePassword";
import useUser from "../../../../api/hooks/useUser";

const UserDetailsPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const menuToggler = useToggle(false);
  const navigate = useNavigate();

  /* modals */
  const editProfileModalToggler = useToggle(false);
  const createUserModalToggler = useToggle(false);
  /*  */

  const anyModalOpen =
    editProfileModalToggler.isToggled || createUserModalToggler.isToggled;

  const { userTreated, setUserTreated, setClient, token } =
    useContext(MainContext);

  /*  const updatePasswordHook = useUpdatePassord(token, subjects.user_update); */
  const updateUserHook = useUser(token, subjects.user_update);

  useEffect(() => {
    if (editProfileModalToggler.isToggled) {
      createUserModalToggler.toggle();
    } else if (createUserModalToggler.isToggled) {
      editProfileModalToggler.toggle();
    }
  }, []);

  useEffect(() => {
    if (userTreated === null && window.location.pathname.includes("/user/")) {
      navigate(paths.pages.settings);
    }
  }, []);

  const handleGoBack = () => {
    setClient(null);
    navigate(paths.pages.settings);
  };

  useEffect(() => {
    /* console.log(updateUserHook.ApiB.response, updateUserHook.ApiB.result); */
    if (updateUserHook.ApiB.response?.ok) {
      setUserTreated(updateUserHook.ApiB.result.user);
      editProfileModalToggler.setIsToggled(false)
    }
  }, [updateUserHook.ApiB.response]);

  /* useEffect(() => {
       console.log(
      updatePasswordHook.ApiB.response,
      updatePasswordHook.ApiB.result
    );  
    if (updatePasswordHook.ApiB.response?.ok) {
        if (user) {
        setTimeout(() => {
          CTAs.updateUser(objToUpdate, user?.id);
        }, 2000);
      }  
    }
  }, [updatePasswordHook.ApiB.response]); */

  return (
    <div className="page-wrapper">
      {updateUserHook.ApiB.isToastVisible && updateUserHook.ApiB.toast}
      {/* 
      {updatePasswordHook.ApiB.isToastVisible && updatePasswordHook.ApiB.toast} */}
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      {editProfileModalToggler.isToggled && (
        <SettingsEditProfileModal
          toggler={editProfileModalToggler}
          user={userTreated}
          isMainUser={false}
          updateUserHook={updateUserHook} /* 
          updatePasswordHook={updatePasswordHook} */
        />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "1105px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: anyModalOpen && isMobile ? "none" : "",
          }}
        >
          <div
            className="header-box"
            style={{
              width: isMobile ? "345px" : "",
              margin: isMobile ? "" : " 56px 64px 0",
            }}
          >
            <div
              className="general-header"
              style={{
                margin: isMobile ? "30px 15px" : "0",
                width: "90%",
              }}
            >
              <div
                className="Oval"
                onClick={handleGoBack}
                style={{
                  marginRight: isMobile ? "auto" : "",
                }}
              >
                <img src={back_icon} />
              </div>
            </div>
            <button
              className="button1"
              type="submit"
              onClick={editProfileModalToggler.toggle}
              style={{
                width: isMobile ? "56px" : "122px",
              }}
            >
              <img className="white" src={edit_icon} />
              {!isMobile && <p className="button1-text">Edit</p>}
            </button>
          </div>
          <div
            className="settings-card shadow"
            style={{
              width: isMobile ? "345px" : "",
              margin: isMobile ? "28px 15px" : "",
              height: isMobile ? "345px" : "",
            }}
          >
            <div
              className="settings-card-content-wrapper"
              style={{
                margin: isMobile ? "48px 20px" : "",
              }}
            >
              <div
                className="settings-card-header"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "",
                }}
              >
                <p
                  className="model-details-card-info-name"
                  style={{
                    fontSize: isMobile ? "32px" : "",
                  }}
                >{`${userTreated?.name} ${userTreated?.surname}`}</p>
                {userTreated?.role === possible_statuses.userType.admin && (
                  <div className="settings-card-header-role-box">
                    <p className="settings-card-header-role">
                      {userTreated?.role}
                    </p>
                  </div>
                )}
              </div>
              <div className="settings-card-info-box">
                <p className="settings-card-info-description">
                  Your login data
                </p>
                <div
                  className="client-details-card-row"
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "25px" : "",
                  }}
                >
                  <div className="client-details-card-info-spec-box">
                    <p className="client-details-card-info-spec-field">
                      E-mail
                    </p>
                    <p className="client-details-card-info-spec-value">
                      {userTreated?.email}
                    </p>
                  </div>
                  {/* <div className="client-details-card-info-spec-box">
                    <p className="client-details-card-info-spec-field">
                      Password
                    </p>
                    <input
                      className="client-details-card-info-spec-value"
                      type={"password"}
                      value={userTreated?.password}
                      disabled={true}
                      style={{
                        boxShadow: "none",
                        marginTop: "-8px",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetailsPage;
