import { useState, useEffect } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import { getSuccessRate } from "../../utils/getMethods";
import smallUp_icon from "../../assets/ico/small-up.svg";
import smallDown_icon from "../../assets/ico/small-down.svg";
import smallMenus_icon from "../../assets/ico/small-minus.png";

const ProposalStats = (props: any) => {
  const { isMobile } = useIsMobile();

  const { success_rate, trend } = props.proposal;

  const [sRate, setSRate] = useState<any>(null);

  useEffect(() => {
    if (Number.isInteger(success_rate)) {
      setSRate(success_rate);
    } else {
      const number = success_rate * 100; //had to *100 'couse it returns 0.99 % instead of 99 % --- remove this if it is correct at backend level
      const result = number.toFixed(2);
      setSRate(result);
    }
  }, [success_rate]);

  const trend_style = () => {
    if (Math.sign(trend) === 0) {
      return {
        icon: smallMenus_icon,
        color: "orange",
      };
    } else if (Math.sign(trend) === 1) {
      return {
        icon: smallUp_icon,
        color: "#4cba6b",
      };
    } else if (Math.sign(trend) === -1) {
      return {
        icon: smallDown_icon,
        color: "#d95b5b",
      };
    }
  };

  return (
    <div
      className="proposal"
      style={{
        width: isMobile ? "345px" : "",
        justifyContent: isMobile ? "center" : "",
      }}
    >
      <div
        className="proposal-wrapper"
        style={{
          gap: isMobile ? "15px" : "",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <div
          className="rate-box"
          style={{
            alignItems: isMobile ? "flex-end" : "",
          }}
        >
          <p
            className="rate-field"
            style={{
              width: isMobile ? "0" : "",
              fontSize: isMobile ? "16px" : "",
            }}
          >
            Proposal
          </p>
          <div
            className="rate-value-box"
            style={{
              gap: isMobile ? "0" : "",
            }}
          >
            <span
              className="rate-value"
              style={{
                marginLeft: isMobile ? "20px" : "",
              }}
            >
              <p
                className="rate-value-primary"
                style={{ marginLeft: isMobile ? "50px" : "" }}
              >
                {props.proposal.approved_times}
              </p>
              <p
                className="rate-value-secondary"
                style={{ marginRight: isMobile ? "19px" : "" }}
              >
                /{props.proposal.proposed_times}
              </p>
            </span>
            <span className="rate-trend-box">
              <div
                className="rate-trend-iconBG"
                style={{
                  width: isMobile ? "16px" : "",
                  padding: isMobile ? "0" : "",
                  backgroundColor: trend_style()?.color,
                }}
              >
                <img
                  className="rate-trend-icon white"
                  style={{
                    width: isMobile ? "16px" : "18px",
                    padding: isMobile ? "0" : "",
                  }}
                  src={trend_style()?.icon}
                />
              </div>
              <p
                className="rate-trend-percentage"
                style={{ color: trend_style()?.color }}
              >
                {trend}%
              </p>
            </span>
          </div>
        </div>
        <div
          className="rate-box"
          style={{ alignItems: isMobile ? "flex-end" : "" }}
        >
          <p
            className="rate-field"
            style={{
              width: isMobile ? "fit-content" : "",
              fontSize: isMobile ? "16px" : "",
              margin: isMobile ? "0 0 0 0" : "",
            }}
          >
            Success Rate
          </p>
          <div
            className="rate-value-box"
            style={{
              gap: isMobile ? "0" : "",
            }}
          >
            <span
              className="rate-value"
              style={{
                marginLeft: isMobile ? "20px" : "",
              }}
            >
              <p
                className="rate-value-primary"
                style={{ marginLeft: isMobile ? "0" : "" }}
              >
                {sRate}
              </p>
              <p
                className="rate-value-secondary"
                style={{ marginRight: isMobile ? "19px" : "" }}
              >
                %
              </p>
            </span>
            <span className="rate-trend-box">
              <div
                className="rate-trend-iconBG"
                style={{
                  width: isMobile ? "16px" : "",
                  padding: isMobile ? "0" : "",
                  backgroundColor: trend_style()?.color,
                }}
              >
                <img
                  className="rate-trend-icon white"
                  style={{
                    width: isMobile ? "16px" : "18px",
                    padding: isMobile ? "0" : "",
                  }}
                  src={trend_style()?.icon}
                />
              </div>
              <p
                className="rate-trend-percentage"
                style={{ color: trend_style()?.color }}
              >
                {trend}%
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProposalStats;
