import CheckboxFilterInput from "./CheckboxFilterInput";
import CheckboxInput from "./CheckboxInput";
import FileInput from "./FileInput";
import SelectInput from "./SelectInput";
import SwitchInput from "./SwitchInput";
import TextAreaInput from "./TextAreaInput";
import TextInput from "./TextInput";

const CustomInput = (props: any) => {
  const inputType = props.type;

  let inputDisplayed = () => {
    if (
      inputType === "text" ||
      inputType === "password" ||
      inputType === "Date" ||
      inputType === "time" ||
      inputType === "number" ||
      inputType === "float"
    ) {
      return <TextInput {...props} />;
    } else if (inputType === "textarea") {
      return <TextAreaInput {...props} />;
    } else if (inputType === "select") {
      return <SelectInput {...props} />;
    } else if (inputType === "file") {
      return <FileInput {...props} />;
    } else if (inputType === "switch") {
      return <SwitchInput {...props} />;
    } else if (inputType === "checkbox_filter") {
      return <CheckboxFilterInput {...props} />;
    } else if (inputType === "checkbox") {
      return <CheckboxInput {...props} />;
    }
  };
  return <div>{inputDisplayed()}</div>;
};
export default CustomInput;
