import { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { Offer } from "../../interfaces";
import {
  getCurrentMonth,
  getLastMonthsArray,
  getOffersByLastMonths,
} from "../../utils/getMethods";

const EarningsChart = (props: any) => {
  const [lastMonths, setLastMonths] = useState(3);
  const [dataForBarChart, setDataForBarChart] = useState<any[]>();
  const [offersFetched, setOfferFetched] = useState<Offer[] | null>([]);

  useEffect(() => {
    if (props.allOffers) {
      setOfferFetched(props.allOffers);
    }
  }, [props.allOffers]);

  const openLast3Months = getOffersByLastMonths(
    offersFetched,
    lastMonths,
    "issue_date"
  );

  const approvedLast3Months = getOffersByLastMonths(
    offersFetched,
    lastMonths,
    "approved_date"
  );

  const getData = (leftData: Offer[], rightData: Offer[], months: number) => {
    const lastMonths = getLastMonthsArray(months);
    let finalData: any[] = [];
    lastMonths.forEach((month) => {
      let leftCounter = 0;
      leftData.forEach((offer) => {
        if (getCurrentMonth(new Date(offer.issue_date!)).word === month) {
          leftCounter = leftCounter + 1;
        }
      });
      let rightCounter = 0;
      rightData.forEach((offer) => {
        if (getCurrentMonth(new Date(offer.approved_date!)).word === month) {
          rightCounter = rightCounter + 1;
        }
      });

      finalData.push({
        month: month.slice(0, 3),
        maxQuantity: leftCounter + Math.floor((leftCounter * 15) / 100),
        statsLeft: leftCounter,
        statsRight: rightCounter,
      });
    });
    return finalData;
  };

  useEffect(() => {
    /* 
    console.log(openLast3Months,approvedLast3Months) */
    setDataForBarChart(
      getData(openLast3Months, approvedLast3Months, lastMonths)
    );
  }, [offersFetched]);

  return (
    <BarChart width={props.width} height={props.height} data={dataForBarChart}>
      <XAxis
        dataKey="month"
        tickLine={false}
        tickMargin={15}
        axisLine={false}
      />
      <YAxis
        dataKey="maxQuantity"
        axisLine={false}
        tickLine={false}
        tickMargin={30}
      />
      <CartesianGrid vertical={false} stroke="#e4e4e4" />
      <Bar dataKey="statsLeft" fill="#6788a1" barSize={20} />
      <Bar dataKey="statsRight" fill="#a0d7e7" barSize={20} />
    </BarChart>
  );
};
export default EarningsChart;
