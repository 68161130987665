export const theme = {
  main_color: "#30b6ba",
  secondary_color: "#2a3f90",
  tertiary_color: "#c95197",
  fourth_color: "#43b0e5",
  main_bg_color: "#f0f4f6",
  secondary_bg_color: "#e3e6ecdc",
  main_text_color: "#fff",
  secondary_textcolor: "#0000",
};
