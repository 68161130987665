import { useEffect, useState } from "react";
import useRecoveryPassord from "../../../api/hooks/password/useRecoveryPassword";
import Footer from "../../../components/Footer";
import FeedMessage from "../../../components/misc/FeedMessage";
import LogMessage from "../../../components/misc/Toast";
import useIsMobile from "../../../hooks/useIsMobile";
import useLogMessage from "../../../hooks/useLogMessage";
import useToggle from "../../../hooks/useToggle";
import "../../../styles/Access.css";
import "../../../styles/General.css";
import logo_main from "../../../assets/logo_main.png";
import congrats_illustration from "../../../assets/illustration/congrats.svg";

const VerifyPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const showFeedMessage = useToggle(false);
  const { recoveryPassword, ApiB } = useRecoveryPassord();
/* 
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => { 
    if (email) {
      recoveryPassword(email);
    }
  }, [email]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tEmail = url.searchParams.get("email");
    if (tEmail) {
      setEmail(tEmail);
    } else if (!tEmail) {
       window.location.href = window.location.origin + "/";  
    }
  }, []); */

  return (
    <div className="access-background">
      <img className="logo-main" src={logo_main} />
      <FeedMessage
        imageBgClassName="mailImage-background"
        imageSrc={congrats_illustration}
        title="E-mail sent"
        description={`Verification success, we send you an email in order to reset your password.`}
        link={{ path: "/", text: "Return to Log In" }}
      />
      {/* {ApiB.callToggler.isToggled && ApiB.toast} */}
      <Footer />
    </div>
  );
};
export default VerifyPage;
