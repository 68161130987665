import { possible_statuses } from "../../consts/statuses";
import Avatar from "./Avatar";

const SideBarUserSection = (props: any) => {
  return (
    <div className="sidebar-user-box">
      <div className="sidebar-user-imageSpot-mask">
        {props.user && <Avatar user={props.user} size={40} />}
      </div>
      <div className="sidebar-user-info">
        <p className="sidebar-user-info-name">{`${props.user?.name} ${
          props.user?.surname && props.user?.surname.slice(0, 1)
        }.`}</p>
        <p className="sidebar-user-info-type">
          {props.user?.role === possible_statuses.userType.admin ? "admin" : ""}
        </p>
      </div>
      {/* <div className="sidebar-user-icon-combined">
        <img
          className="sidebar-user-icon-shape"
          src={`${toIcons}/small-up.svg`}
        />
        <img
          className="sidebar-user-icon-shape"
          src={`${toIcons}/small-down.svg`}
        />
      </div> */}
    </div>
  );
};
export default SideBarUserSection;
