/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import CustomInput from "../inputs/CustomInput";
import { useContext, useEffect, useState } from "react";
import close_icon from "../../assets/ico/close.svg";

const OfferShareModal = (props: any) => {
  const { isMobile } = useIsMobile();
  /*   const { offer } = useContext(MainContext); */
  const { form, toggler, link, setLink, isLinkDisabled } = props;

  const [isPasswordSelected, setIsPasswordSelected] = useState(false);
  const [isExpirationDateSelected, setIsExpirationDateSelected] =
    useState(false);

  useEffect(() => {
    if (!isPasswordSelected) {
      form.resetField("password");
    }
  }, [isPasswordSelected]);
  useEffect(() => {
    if (!isExpirationDateSelected) {
      form.resetField("expiration_date");
    }
  }, [isExpirationDateSelected]);

  useEffect(() => {
    form.reset();
    setLink("");
  }, []);

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        className={`offer-option-modal ${isMobile ? "scroll-y" : ""}`}
        style={{
          width: isMobile ? "290px" : "",
          height: isMobile ? "" : link ? "" : "fit-content",
        }}
      >
        <div className="modal-header">
          <p className="modal-title">Share offer</p>
          <div className="Oval" onClick={toggler.toggle}>
            <img src={close_icon} />
          </div>
        </div>
        <div className="offer-option-modal-informations">
          <p className="customInputText-title">
            Include the following information
          </p>
          <div style={{ marginTop: "20px" }}>
            <CustomInput
              width={40}
              type="switch"
              title="Mediakit"
              name={"mediakit_shared"}
              setValue={form.setValue}
            />
          </div>

          <div style={{ marginTop: "20px" }}>
            <CustomInput
              width={40}
              type="switch"
              title="Budget"
              name={"budget_shared"}
              setValue={form.setValue}
            />
          </div>
        </div>
        <div className="offer-option-modal-permissions">
          <p className="customInputText-title">Password and permission</p>
          <div style={{ marginTop: "20px" }}>
            <CustomInput
              width={174}
              type="switch"
              title="Set Password"
              name={"password_selected"}
              setValue={form.setValue}
              others={{ setAction: setIsPasswordSelected }}
            />
            {isPasswordSelected && (
              <div style={{ marginTop: "22px" }}>
                <CustomInput
                  width={isMobile ? "90%" : "95%"}
                  type="password"
                  title="Password"
                  name={"password"}
                  register={form.register}
                  errors={form.formState.errors}
                  constraints={{ required: true }}
                />
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px" }}>
            <CustomInput
              width={323}
              type="switch"
              title="Set an expiration date for this proposal"
              name={"expiration_date_selected"}
              setValue={form.setValue}
              others={{ setAction: setIsExpirationDateSelected }}
            />
            {isExpirationDateSelected && (
              <div style={{ marginTop: "22px" }}>
                <CustomInput
                  width={"100%"}
                  type="Date"
                  title="Expiration date"
                  name={"expiration_date"}
                  register={form.register}
                  errors={form.formState.errors}
                  constraints={{ required: true }}
                />
              </div>
            )}
          </div>
          {link && (
            <div className="offer-option-modal-link">
              <CustomInput
                width={"100%"}
                type="text"
                title="Link"
                name={"link"}
                value={link}
                copyIcon={true}
                others={{ disabled: isLinkDisabled }}
                /* auto generate link to share */
              />
            </div>
          )}
        </div>
        <button
          className="offer-option-modal-button"
          type="submit"
          disabled={form.readOnly}
          style={{
            width: isMobile ? "100%" : "",
            marginTop: isMobile ? "43px" : link ? "" : "43px",
          }}
        >
          <p className="offer-option-modal-button-text">Generate Link</p>
        </button>
      </div>
    </div>
  );
};

export default OfferShareModal;
