import { useEffect, useState } from "react";
import { Line, LineChart, XAxis, YAxis } from "recharts";
import { Offer } from "../../interfaces";
import {
  getCurrentMonth,
  getLastMonthsArray,
  getOffersByLastMonths,
} from "../../utils/getMethods";
import { theme } from "../../consts/palette";
/* import {
  getCurrentMonth,
  getLastMonthsArray,
  getOffersByLastMonths
} from "../../utils/getMethods"; */

const EarningsChart = (props: any) => {
  const [lastMonths, setLastMonths] = useState(4);
  const [dataLine, setDataLine] = useState<any[]>();
  const [offersFetched, setOfferFetched] = useState<Offer[] | null>([]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (props.allOffers) {
      setOfferFetched(props.allOffers);
    }
  }, [props.allOffers]);

  const approvedLast4Months = getOffersByLastMonths(
    offersFetched,
    lastMonths,
    "approved_date"
  );

  const getData = (offers: Offer[], months: number) => {
    const lastMonths = getLastMonthsArray(months);
    let finalData: any[] = [];
    lastMonths.forEach((month) => {
      let counter: number = 0;
      offers.forEach((offer) => {
        if (
          offer.budget &&
          offer.approved_date &&
          getCurrentMonth(new Date(offer.approved_date)).word === month
        ) {
          counter = +offer.budget;
        }
      });
      finalData.push({
        month: month.slice(0, 3),
        maxEarningValue: counter + (counter / 100) * 10,
        earning: counter,
      });
    });
    return finalData;
  };

  useEffect(() => {
    setDataLine(getData(approvedLast4Months, 4));
  }, [offersFetched]);

  useEffect(() => {
    setWidth(
      (document.getElementsByClassName(props.containerClassName)[0]
        .clientWidth /
        100) *
        90
    );
  });

  return (
    <LineChart width={width} height={props.height} data={dataLine}>
      {/* <Tooltip wrapperStyle={{ width: 150, backgroundColor: "none" }} />   */}
      <Line
        type="monotone"
        dataKey="earning"
        stroke={theme.tertiary_color}
        strokeWidth={4}
        dot={false}
      />
      <XAxis
        dataKey="month"
        axisLine={false}
        tickLine={false}
        tickMargin={10}
      />
      <YAxis
        dataKey="maxEarningValue"
        axisLine={false}
        tickLine={false}
        tickSize={0}
        tickMargin={20}
      />
    </LineChart>
  );
};
export default EarningsChart;
