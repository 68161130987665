import { useEffect } from "react";
import useToggle from "../../hooks/useToggle";

const CheckboxFilterInput = (props: any) => {
  const defaultValue = props.defaultValue;
  const isSelectAllToggled = props.others.selectAllToggler.isToggled;
  const isResetToggled = props.others.resetToggler.isToggled;
  const checkboxToggler = useToggle(false);

  useEffect(() => {
    if (isSelectAllToggled) {
      checkboxToggler.setIsToggled(isSelectAllToggled);
      props.others.setAction((prevState: any) => {
        return {
          ...prevState,
          [props.name]: isSelectAllToggled,
        };
      });
    } else if (isSelectAllToggled === false) {
      checkboxToggler.setIsToggled(isSelectAllToggled);
      props.others.setAction((prevState: any) => {
        return {
          ...prevState,
          [props.name]: false,
        };
      });
    }
  }, [isSelectAllToggled]);

  useEffect(() => {
    if (defaultValue && !isSelectAllToggled) {
      checkboxToggler.setIsToggled(true);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isResetToggled) {
      props.others.setAction({});
      checkboxToggler.setIsToggled(false);
    }
  }, [isResetToggled]);

  return (
    <label
      className={`container`}
      style={{
        width: `${props.width}px`,
      }}
    >
      <p className={`checkbox-value`}>{props.title}</p>
      <input
        type="checkbox"
        checked={checkboxToggler.isToggled}
        onChange={(e) => {
          checkboxToggler.toggle();
          props.others.setAction((prevState: any) => {
            return {
              ...prevState,
              [props.name]: e.target.checked,
            };
          });
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
};
export default CheckboxFilterInput;
