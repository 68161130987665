import { paths } from "../consts/URLs";
import { possible_statuses } from "../consts/statuses";
import { Influencer, Offer, Social, Verticality } from "../interfaces";
import { getSocials, getVerticalities } from "./getMethods";

/* Influencer */
export const influencerRemappingFromEditInputs = (
  data: any,
  profileImage64: string,
  mediaKitBase64: string
) => {
  let finalObj: any = {
    name: data?.name,
    surname: data?.surname,
    birth_date: data?.birth_date,
    city: data?.city,
    email: data?.email,
  };
  if (profileImage64) {
    finalObj["profileImage"] = profileImage64;
  }
  if (mediaKitBase64) {
    finalObj["mediaKit"] = mediaKitBase64;
  }

  return finalObj;
};

export const influencerRemappingFromGetAPI = (data: any) => {
  let finalObj: Influencer = {
    id: data.id ? data.id : "",
    name: data.name ? data.name : "",
    surname: data.surname ? data.surname : "",
    birth_date: data.birth_date ? data.birth_date : "",
    city: data.city ? data.city : "",
    email: data.email ? data.email : "",
    mediaKit: data.mediaKit_src ? data.mediaKit_src : "",
    social_infos: data.social_infos ? data.social_infos : [],
    verticalities: data.verticalities ? data.verticalities : [],
    proposal: {
      proposed_times: data.proposed_offers ? data.proposed_offers : "",
      approved_times: data.approved_offers ? data.approved_offers : "",
      success_rate: data.success_rate ? data.success_rate : "",
      trend: data.trend ? data.trend : "",
    },
    profileImage: data.profileImage_src ? data.profileImage_src : "",
  };
  return finalObj;
};

export const influencerRemappingFromProposedList = (data: any) => {
  let finalObj: any = {
    id: data.talent_id ? data.talent_id : "",
    name: data.talent_name ? data.talent_name : "",
    surname: data.talent_surname ? data.talent_surname : "",
    profileImage: data.talent_profileImage ? data.talent_profileImage : "",
  };
  return finalObj;
};

export const influencerRemappedFromShare = (data: any) => {
  const finalObj: any = {
    id: data.talent_id ? data.talent_id : "",
    name: data.talent_name ? data.talent_name : null,
    surname: data.talent_surname ? data.talent_surname : null,
    mediaKit: data.talent_mediaKit ? data.talent_mediaKit : null,
    profileImage: data.talent_profileImage ? data.talent_profileImage : null,
  };
  return finalObj;
};

/* Offer */

export const offerRemappedFromInputs = (data: any) => {
  let finalObj: any = {
    offer_name: data.offer_name,
    client_id: data.client_id,
    issue_date: data.issue_date,
    location: data.location,
    short_description: data.short_description,
  };
  return finalObj;
};

export const offerRemappingFromResult = (data: any) => {
  let finalObj: Offer = {
    id: data.id ? data.id : "",
    client: data.client ? data.client : "",
    requested_influencers: data.requested_talents ? data.requested_talents : "",
    short_description: data.short_description ? data.short_description : "",
    issue_date: data.issue_date ? data.issue_date : "",
    closed_date: data.closed_date ? data.closed_date : "",
    approved_date: data.approved_date ? data.approved_date : "",
    location: data.location ? data.location : "",
    offer_name: data.offer_name ? data.offer_name : "",
    budget: data.budget ? data.budget : "",
    budget_shared: data.budget_shared ? data.budget_shared : "",
    category: data.category ? data.category : "",
    composite_shared: data.composite_shared ? data.composite_shared : "",
    expiration_date: data.expiration_date ? data.expiration_date : "",
    link: data.link ? data.link : "",
    lookbook_shared: data.lookbook_shared ? data.lookbook_shared : "",
    password: data.password ? data.password : "",
    imageSrc: data.imageSrc ? data.imageSrc : "",
    long_description: data.long_description ? data.long_description : "",
    status: data.status ? data.status : "",
    proposed_list: data.proposed_talents ? data.proposed_talents : "",
    created_at: data.created_at ? data.created_at : "",
  };
  return finalObj;
};

export const listRemappedFromOfferProposedList = (data: any) => {
  let finalObj: any = [];
  data.proposed_talents.foreEach((el: any) => {
    finalObj.push({
      id: data.talent_id ? data.talent_id : "",
      status: data.status ? data.status : "",
    });
  });
  return finalObj;
};

/* CLIENT */

export const clientRemappedFromGetApi = (data: any) => {
  let finalObj: any = [];
  finalObj = {
    id: data.id ? data.id : "",
    brand_name: data.brand_name ? data.brand_name : "",
    final_client: data.final_client ? data.final_client : "",
    referent_name: data.referent_name ? data.referent_name : "",
    email: data.email ? data.email : "",
    amount_spent: data.amount_spent ? data.amount_spent : "",
    proposal: {
      proposed_times: data.proposed_offers ? data.proposed_offers : "",
      approved_times: data.approved_offers ? data.approved_offers : "",
      success_rate: data.success_rate ? data.success_rate : "",
      trend: data.trend ? data.trend : "",
    },
  };
  return finalObj;
};

/* export const dataRemappingForShareApi = (obj: any) => {
  let finalObj: any = {};
  if (obj.password_selected === null) {
    obj.password = "";
  }
  if (obj.expiration_date_selected === null) {
    obj.expiration_date = "";
  }
  const entries = Object.entries(obj);
  entries.forEach((entry) => {
    if (
      entry[0] === "password_selected" ||
      entry[0] === "expiration_date_selected"
    ) {
    } else if (
      (typeof entry[1] === typeof "String" && entry[1] != "") ||
      (typeof entry[1] === typeof false && entry[1] != false)
    ) {
      finalObj[entry[0]] = entry[1];
    }
  }); 
  if (Object.entries(finalObj).length === 0) {
    finalObj = null;
  } 
  return finalObj;
};
 */
/*



RESULTS FROM API UPDATE:

budget: null
budget_shared: null
category: null
client_id: 18
composite_shared: null
created_at: "2022-12-01T23:25:52.000000Z"
description: null
expiration_date: 1
id: 55
imageSrc: "https://cdn.tua.blacknachos.com/images/EIB7eMZw788pRQ7.png"
issue_date: "2022-12-12 00:00:00"
link: "www.AAAAA.it"
location: "Milan"
long_description: null
lookbook_shared: 0
models_accepted_list: Array [ {…} ]
models_proposed_list: Array [ {…}, {…} ]
offer_name: "Sfilata"
offer_status: null
password: 0
requested_models: 3
short_description: null
status: "pending"
updated_at: "2022-12-01T23:31:24.000000Z"
*/
