import { useEffect, useState } from "react";
/* import { Model } from "../interfaces"; */
import { getBase64, removeFromFilesArray } from "../utils/others";
import { Influencer } from "../interfaces";

const useLoader = (influencer: Influencer | null, refresh: boolean) => {
  const [filesOnInput, setFilesOnInput] = useState<any[]>([]);
  const [filesBase64, setFilesBase64] = useState<any[]>([]);
  const [filesPreviewSrc, setFilesPreviewSrc] = useState<any[]>([]);
  const [fileType, setFileType] = useState("");

   

  const getPreviewSrc = (file: File) => {
    let src = "";
    if (file) {
      src = URL.createObjectURL(file);
    }
    return src;
  };

  const getFiles = (e: any) => {
    const files: File[] = [];
    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }
    return filesOnInput.concat(files);
  };

  const process = (e: any, fileType?: string) => {
    if (fileType) setFileType(fileType);
    let files = [];
    if ((fileType && fileType === "profileImage") || fileType === "mediaKit") {
      files = e.target.files;
    } else files = getFiles(e);
    setFilesOnInput(files);
  };
  const remove = (index: number, file: any) => {
    const final = removeFromFilesArray(index, file, filesOnInput);
    setFilesOnInput(final);
  };

  useEffect(() => {
    const base64Array: any[] = [];
    if (fileType && (fileType === "profileImage" || fileType === "mediaKit")) {
      let b64 = getBase64(filesOnInput[0]);
      b64.then((data) => {
        return base64Array.push({ [fileType]: data });
      });
    } else {
      for (let i = 0; i < filesOnInput.length; i++) {
        const file = filesOnInput[i];
        let b64 = getBase64(file);
        b64.then((data) => {
          if (fileType === "document") {
            return base64Array.push({
              [fileType]: data,
              name: file.name,
              index: i,
            });
          } else {
            return base64Array.push({ [fileType]: data, index: i });
          }
        });
      }

      /* filesOnInput.forEach((file) => {
        let b64 = getBase64(file);
        b64.then((data) => {
          if (fileType === "document") {
            return base64Array.push({
              [fileType]: data,
              name: file.name,
            });
          } else {
            return base64Array.push({ [fileType]: data });
          }
        });
      }); */
    }
    setFilesBase64(base64Array);
  }, [filesOnInput]);

  useEffect(() => {
    const previewSrcArray: string[] = [];
    if (fileType && (fileType === "profileImage" || fileType === "mediaKit")) {
      previewSrcArray.push(getPreviewSrc(filesOnInput[0]));
    } else {
      filesOnInput.forEach((file) => {
        previewSrcArray.push(getPreviewSrc(file));
      });
    }
    setFilesPreviewSrc(previewSrcArray);
  }, [filesOnInput]);

  useEffect(() => {
    setFilesBase64([]);
    setFilesPreviewSrc([]);
  }, [refresh]);

  const reset = () => {
    setFilesOnInput([]);
  };

  /*  console.log(filesOnInput); */

  return { filesOnInput, filesBase64, filesPreviewSrc, process, remove, reset };
};
export default useLoader;

/* URL.revokeObjectURL(objFile) */
