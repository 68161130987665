export const setCookie = (cookieName: string, cookieValue: string) => {
  document.cookie = `${cookieName} = ${cookieValue}`;
};

export const getCookie = (cookieName: string) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cookieName: string) => {
  document.cookie = `${cookieName} =  `;
};
