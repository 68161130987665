import { useNavigate } from "react-router-dom";

/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { useContext } from "react";
import smallLeft_icon from "../../assets/ico/small-left.svg";
import plus_icon from "../../assets/ico/plus.svg";
import edit_icon from "../../assets/ico/edit.svg";
import { paths } from "../../consts/URLs";
import MainContext from "../../context/MainContext";

const ClientDetailsHeader = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const { setClient } = useContext(MainContext);

  const handleGoBack = () => {
    setClient(null);
    navigate(paths.pages.clients);
  };

  return (
    <div
      className="general-header"
      style={{
        width: isMobile ? "345px" : "90%",
        margin: isMobile ? "30px 15px" : " 56px 64px 0",
      }}
    >
      <div
        className="Oval"
        onClick={handleGoBack}
        style={{
          marginRight: isMobile ? "auto" : "",
        }}
      >
        <img src={smallLeft_icon} />
      </div>
      <div className="general-header-right">
        {/* <div className="header-right-box1">
          <div className="Oval">
            <img className="offLightBlue" src={`${toIcons}/more.svg`} />
          </div>
        </div> */}
        <div className="header-right-box2">
          <div
            className={`${isMobile ? "Oval" : "button3"}`}
            onClick={props.createProposalModalToggler.toggle}
          >
            {isMobile ? (
              <img className="offLightBlue" src={plus_icon} />
            ) : (
              <p className="button3-text">Create to proposal</p>
            )}
          </div>
          <button
            className="button1"
            style={{ width: isMobile ? "56px" : "122px" }}
            onClick={props.editClientModalToggler.toggle}
          >
            <img className="white" src={edit_icon} />
            {!isMobile && <p className="button1-text">Edit</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ClientDetailsHeader;
