import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useOffer from "../../api/hooks/useOffers";
import { logMsgBgColors } from "../../consts/misc";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import useLogMessage from "../../hooks/useLogMessage";
import useToggle from "../../hooks/useToggle";
import { Offer } from "../../interfaces";
import "../../styles/Modals.css";
/* import { getAvailableProposal } from "../../utils/getMethods"; */
/* import { offerRemappingFromResult } from "../../utils/offerRemapping"; */
import LogMessage from "../misc/Toast";
import close_icon from "../../assets/ico/close.svg";
import MainContext from "../../context/MainContext";
import { possible_statuses } from "../../consts/statuses";

const ProposalModal = (props: any) => {
  const { isMobile } = useIsMobile();
  const addToProposalForm = useForm();

  const { token, offer, setOffer, refresh } = useContext(MainContext);

  const { createHook, updateHook } = props;
  const offerHook = useOffer(token);

  const [openOffers, setOpenOffers] = useState<any[]>([]);

  useEffect(() => {
    offerHook.CTAs.getOffers();
  }, []);

  useEffect(() => {
    /* console.log(offerHook.ApiB.response, offerHook.ApiB.result); */
    if (offerHook.ApiB.response?.ok) {
      const result = offerHook.ApiB.result.offers;
      const availableOffers = result.filter(
        (offer: any) => offer.status === possible_statuses.offer.open
      );
      setOpenOffers(availableOffers);
    }
  }, [offerHook.ApiB.response]);

  const onSubmit = (data: any) => {
    const match = openOffers.find(
      (el) => el.id === Number.parseInt(data.offer_id)
    );

    const proposedList = match?.proposed_talents;

    const update = proposedList
      ? proposedList.some((item: any) => item.talent_id === props.influencer.id)
      : false;

    const objToSend = {
      proposed_talents: [
        {
          talent_id: props.influencer.id,
          status: possible_statuses.influencer.pending,
        },
      ],
    };
/* 
    console.log(JSON.stringify(objToSend)); */
    if (!update && match) {/* 
      console.log("CREATE"); */
      createHook.CTAs.createOfferInfluencers(match.id, objToSend);
    } else if (update && match) {/* 
      console.log("UPDATE"); */
      updateHook.CTAs.updateOfferInfluencers(match.id, objToSend);
    }
  };

  const renderOptions = openOffers.map((item: Offer, key: number) => {
    return (
      <option key={key} value={item.id}>
        {item.offer_name}
      </option>
    );
  });

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        id="#proposal_modal"
        className={`proposal-modal  ${
          isMobile && "general-modal-dimension-Mob"
        }`}
        style={{
          width: isMobile ? "calc(345px - 64px)" : "" /* 
          height: "75vh", */,
          padding: isMobile ? "25px 19px 0 21px" : "",
        }}
      >
        <form onSubmit={addToProposalForm.handleSubmit(onSubmit)}>
          <div className="proposal-modal-content">
            <div className="modal-header">
              <p className="modal-title">Add to proposal</p>
              <div className="Oval" onClick={props.togglerProposalModal.toggle}>
                <img src={close_icon} />
              </div>
            </div>
            <div
              className="customInputText-box"
              style={{ width: `100%`, marginTop: isMobile ? "25px" : "55px" }}
            >
              <p className="customInputText-title">Offer's name</p>
              <select
                className={`customInputText-input`}
                style={{ width: " 100% " }}
                {...addToProposalForm.register("offer_id", {
                  required: true,
                })}
              >
                <option></option>
                {renderOptions}
              </select>
            </div>
            <button
              className="button2 proposal-modal-button-addon"
              type="submit"
              style={{
                marginTop: isMobile ? "85px" : "53px",
                width: isMobile ? "100%" : "",
              }}
            >
              <p className="button2-text">Save</p>
            </button>
          </div>
        </form>
      </div>
      {/* {logMessage.isToggled && (
        <LogMessage
          text={{
            subject: logMessage.message.subject,
            other_text: logMessage.message.other_text,
            complement: logMessage.message.complement,
          }}
          bgColor={logMessage.message.bgColor}
        />
      )} */}
    </div>
  );
};
export default ProposalModal;
