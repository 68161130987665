import useIsMobile from "../../hooks/useIsMobile";
import { useState, useEffect } from "react";
import useToggle from "../../hooks/useToggle";
import congrats_icon from "../../assets/illustration/congrats.svg";
import sleep_icon from "../../assets/illustration/sleep.svg";
import rejected_icon from "../../assets/illustration/rejected.svg";
import { theme } from "../../consts/palette";

const OfferCloseModalsOptions = (props: any) => {
  const { isMobile } = useIsMobile();

  const noAnswer = useToggle(false);
  const rejected = useToggle(false);

  /* 
  console.log(props.closeStatus,props.togglers.confirmed.isToggled) */

  useEffect(() => {
    if (noAnswer.isToggled) {
      props.setOfferStatus("no_answer");
    }
    if (rejected.isToggled) {
      props.setOfferStatus("rejected");
    }
    if (
      !props.togglers.confirmed.isToggled &&
      !noAnswer.isToggled &&
      !rejected.isToggled
    ) {
      props.setOfferStatus("");
    }
  });

  return (
    <div className="offer-close-modal-options-wrapper">
      <div
        className="offer-close-modal-option option-shadow"
        onClick={() => {
          props.togglers.goBack.toggle();
          props.togglers.confirmed.toggle();
          rejected.setIsToggled(false);
          noAnswer.setIsToggled(false);
        }}
        style={{
          width: isMobile ? "305px" : "",
          height: isMobile ? "100px" : "",
        }}
      >
        <img
          className="offer-close-modal-option-image"
          src={congrats_icon}
          alt=""
        />
        <div
          className="offer-close-modal-option-info"
          style={{ height: isMobile ? "55px" : "" }}
        >
          <p className="offer-close-modal-option-title">Confirmed</p>
          <p
            className="offer-close-modal-option-description"
            style={{ width: isMobile ? "151px" : "" }}
          >
            Congrats! The client has accepted the offer
          </p>
        </div>
      </div>
      <div
        className="offer-close-modal-option option-shadow"
        onClick={() => {
          noAnswer.toggle();
          rejected.setIsToggled(false);
          props.togglers.confirmed.setIsToggled(false);
        }}
        style={{
          width: isMobile ? "305px" : "",
          height: isMobile ? "100px" : "",
          border: noAnswer.isToggled ? `2px solid ${theme.main_color}` : "",
        }}
      >
        <img
          className="offer-close-modal-option-image"
          src={sleep_icon}
          alt=""
        />
        <div className="offer-close-modal-option-info">
          <p className="offer-close-modal-option-title">No answer</p>
          <p className="offer-close-modal-option-description">
            Maybe the client is sleeping
          </p>
        </div>
      </div>
      <div
        className="offer-close-modal-option option-shadow"
        onClick={() => {
          rejected.toggle();
          noAnswer.setIsToggled(false);
          props.togglers.confirmed.setIsToggled(false);
        }}
        style={{
          width: isMobile ? "305px" : "",
          height: isMobile ? "100px" : "",
          border: rejected.isToggled ? `2px solid ${theme.main_color}` : "",
        }}
      >
        <img
          className="offer-close-modal-option-image"
          src={rejected_icon}
          alt=""
        />
        <div className="offer-close-modal-option-info">
          <p className="offer-close-modal-option-title">Rejected</p>
          <p className="offer-close-modal-option-description">
            The offer wasn’t in line with the client’s request
          </p>
        </div>
      </div>
    </div>
  );
};
export default OfferCloseModalsOptions;
