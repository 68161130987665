import { Influencer } from "../../interfaces";
import { getPropertiesNames } from "../getMethods";

export const filterByName = (list: Influencer[], inputValue: string) => {
  let finalArray: Influencer[] = [];
  list.forEach((model) => {
    if (
      model.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      model.surname.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      finalArray.push(model);
    }
  });
  return finalArray;
};

export const filterByVerts = (list: Influencer[], inputValue: any) => {
  let finalArray: Influencer[] = [];
  list.forEach((influencer) => {
    let proceed = false;
    influencer.verticalities.forEach((vert, index) => {
      let desc = vert.description;
      if (inputValue[desc]) {
        proceed = true;
      }
    });
    if (proceed) {
      finalArray.push(influencer);
    }
  });
  return finalArray;
};
export const filterByPlats = (list: Influencer[], inputValue: any) => {
  let finalArray: Influencer[] = [];
  list.forEach((influencer) => {
    let proceed = false;
    influencer.social_infos.forEach((social, index) => {
      let name = social.social_name;
      if (inputValue[name]) {
        proceed = true;
      }
    });
    if (proceed) {
      finalArray.push(influencer);
    }
  });
  return finalArray;
};

function calculate_age(dob: Date) {
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const filterByTarget = (list: Influencer[], inputValue: any) => {
  let finalArray: Influencer[] = [];
  list.forEach((influencer) => {
    let birthDate = new Date(influencer.birth_date);
    let age = calculate_age(birthDate);
    if (inputValue.gen_z && age <= 24 && age >= 18) {
      if (finalArray.some((item) => item.id === influencer.id)) {
      } else {
        finalArray.push(influencer);
      }
    }
    if (inputValue.millennials && age <= 35 && age >= 24) {
      if (finalArray.some((item) => item.id === influencer.id)) {
      } else {
        finalArray.push(influencer);
      }
    }
  });
  if (
    (inputValue.gen_z && inputValue.millennials && finalArray.length === 0) ||
    (!inputValue.gen_z && !inputValue.millennials)
  ) {
    return null;
  } else return finalArray;
};

export const finalFilter = (
  influencersFetched: Influencer[],
  filteredByName?: Influencer[],
  filterByVerts?: Influencer[],
  filterByPlats?: Influencer[],
  filterByTargets?: Influencer[] | null
) => {
  let influencersTreated = influencersFetched;

  if (filteredByName && filterByName.length > 0) {
    influencersTreated = filteredByName;
  }

  if (filterByVerts && filterByVerts.length > 0) {
    influencersTreated = influencersTreated.filter((influencer) =>
      filterByVerts.includes(influencer)
    );
  }

  if (filterByPlats && filterByPlats.length > 0) {
    influencersTreated = influencersTreated.filter((influencer) =>
      filterByPlats.includes(influencer)
    );
  }

  if (filterByTargets) {
    influencersTreated = influencersTreated.filter((influencer) =>
      filterByTargets.includes(influencer)
    );
  }

  return influencersTreated;
};
