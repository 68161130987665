import { createContext } from "react";

type FilterCtx = {
  isVerticalitiesOpen: boolean;
  setIsVerticalitiesOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPlatformsOpen: boolean;
  setIsPlatformsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isTargetOpen: boolean;
  setIsTargetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  verticalities: object;
  setVerticalities: React.Dispatch<React.SetStateAction<object>>;
  platforms: object;
  setPlatforms: React.Dispatch<React.SetStateAction<object>>;
  target: object;
  setTarget: React.Dispatch<React.SetStateAction<object>>;
};

const defaultContext = {
  isVerticalitiesOpen: false,
  setIsVerticalitiesOpen: () => {},
  isPlatformsOpen: false,
  setIsPlatformsOpen: () => {},
  isTargetOpen: false,
  setIsTargetOpen: () => {},
  name: "",
  setName: () => {},
  verticalities: {},
  setVerticalities: () => {},
  platforms: {},
  setPlatforms: () => {},
  target: {},
  setTarget: () => {},
};

const FilterContext = createContext<FilterCtx>(defaultContext);

export default FilterContext;
