import { useForm } from "react-hook-form";
import useUser from "../../api/hooks/useUser";
import { settingsInputs } from "../../consts/inputs";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { getDefaultValue } from "../../utils/getMethods";
import CustomInput from "../inputs/CustomInput";
import { useContext, useEffect, useState } from "react";
import useUpdatePassord from "../../api/hooks/password/useUpdatePassword";
import { validatePassword } from "../../utils/validations";
import useLogMessage from "../../hooks/useLogMessage";
import { logMsgBgColors } from "../../consts/misc";
import LogMessage from "../misc/Toast";
/* import { userRemappingFromResult } from "../../utils/userRemapping"; */
import close_icon from "../../assets/ico/close.svg";
import MainContext from "../../context/MainContext";
import { subjects } from "../../consts/statuses";
import useChanegPassword from "../../api/hooks/password/useChangePassword";

const SettingsEditProfileModal = (props: any) => {
  const { user, setUser, userTreated, setUserTreated, token } =
    useContext(MainContext);
  const { updateUserHook } = props;

  const changePasswordHook = useChanegPassword(token);

  const { isMobile } = useIsMobile();
  const editUserProfileForm = useForm();

  const [passwordFieldsVisible, setPasswordsFieldsVisible] = useState(false);

  const [objToUpdate, setObjToUpdate] = useState<any>();

  const onSubmit = (data: any) => {
    const obj = {
      name: data.name,
      surname: data.surname,
    }; 
    if (props.isMainUser) {
      if (
        user &&
        ((data.password === "" && data.password_confirmation === "") ||
          !passwordFieldsVisible)
      ) {
        updateUserHook.CTAs.updateUser(obj, user?.id);
      } else if (data.password !== "" || data.password_confirmation !== "") {
        changePWandUpdateUser(data.password, data.password_confirmation, obj);
      }
    } else if (userTreated && !props.isMainUser) {
      updateUserHook.CTAs.updateUser(obj, userTreated?.id);
    }
  };
  const changePWandUpdateUser = (
    password: string,
    password_confirmation: string,
    obj: any
  ) => {
    const validation = validatePassword(password, password_confirmation);
    const validationMsg = validation?.msg;
    const isPasswordValid = validation?.isValid;
    if (isPasswordValid) {
      changePasswordHook.changePassword(password, password_confirmation);
      setObjToUpdate(obj);
    }
  };

  useEffect(() => {
    /* console.log(
      changePasswordHook.ApiB.response,
      changePasswordHook.ApiB.result
    ); */
    if (changePasswordHook.ApiB.response?.ok) {
      changePasswordHook.ApiB.write({
        text: {
          subject: "Password",
          complement: " has been changed",
          other_text: "",
        },
        bgColor: logMsgBgColors.correct,
      });
      setTimeout(() => {
        updateUserHook.CTAs.updateUser(objToUpdate, user?.id);
      }, 2000);
    }
  }, [changePasswordHook.ApiB.response]);

  const renderInputs = settingsInputs.map((field, key) => {
    let defaultValue;
    if (field.name?.includes("password")) {
    } else defaultValue = getDefaultValue(props.user, field);
    if (!props.isMainUser && field.name?.includes("password")) {
    }
    if (!passwordFieldsVisible && field.name?.includes("password")) {
    } else {
      return (
        <CustomInput
          key={key}
          width={"100%"}
          title={field.title}
          name={field.name}
          type={field.type}
          register={editUserProfileForm.register}
          errors={editUserProfileForm.formState.errors}
          defaultValue={defaultValue}
          constraints={field.constraints}
          others={{ disabled: field.name === "email" }}
        />
      );
    }
  });

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      {changePasswordHook.ApiB.isToastVisible && changePasswordHook.ApiB.toast}
      <div
        className="settings-edit-modal scroll-y"
        style={{
          height: props.isMainUser ? (isMobile ? "75vh" : "fit-content") : "",
          width: isMobile ? "345px" : "",
        }}
      >
        <form onSubmit={editUserProfileForm.handleSubmit(onSubmit)}>
          <div className="settings-edit-modal-content">
            <div className="modal-header">
              <p className="modal-title">Edit your data</p>
              <div className="Oval" onClick={props.toggler.toggle}>
                <img src={close_icon} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                marginTop: "22px",
              }}
            >
              {renderInputs}
            </div>
            {props.isMainUser && (
              <div style={{ marginTop: "30px" }}>
                <CustomInput
                  width={174}
                  type="switch"
                  title="Set Password"
                  name={"password_selected"}
                  others={{ setAction: setPasswordsFieldsVisible }}
                />
              </div>
            )}
            <button
              style={{
                marginTop: "44px",
                marginRight: "0",
                width: isMobile ? "100%" : "105px",
              }}
              className="modal-button"
              type="submit"
            >
              <p className="modal-button-text">Save</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SettingsEditProfileModal;
