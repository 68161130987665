import { Offer } from "../../interfaces";

export const filterByName = (list: Offer[], inputValue: string) => {
  let finalArray: Offer[] = [];
  list.forEach((offer) => {
    if (
      offer.offer_name &&
      offer.offer_name.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      finalArray.push(offer);
    }
  });
  return finalArray;
};
