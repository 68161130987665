import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
/* import useLogout from "../../api/hooks/authentication/useLogout"; */
import useUser from "../../../api/hooks/useUser";
import edit_icon from "../../../assets/ico/edit.svg";
import NavBar from "../../../components/misc/NavBar";
import SideBar from "../../../components/misc/SideBar";
import DeleteModal from "../../../components/modals/DeleteModal";
import LogoutModal from "../../../components/modals/LogoutModal";
import SettingsCreateUserModal from "../../../components/modals/SettingsCreateUserModal";
import SettingsEditProfileModal from "../../../components/modals/SettingsEditProfileModal";
import UsersTable from "../../../components/user/UsersTable";
import useIsMobile from "../../../hooks/useIsMobile";
import useToggle from "../../../hooks/useToggle";
import "../../../styles/Clients.css";
import "../../../styles/Settings.css";
import MainContext from "../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../consts/URLs";
import { possible_statuses, subjects } from "../../../consts/statuses";
import useRegister from "../../../api/hooks/authentication/useRegister";

const SettingsPage = (props: any) => {
  const {
    user,
    userTreated,
    setUserTreated,
    setUser,
    token,
    setRefresh,
    refresh,
  } = useContext(MainContext);
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const resetListToggler = useToggle(false);
  const menuToggler = useToggle(false);
  const logoutToggler = useToggle(false);

  /*  */
  const registerHook = useRegister(token, subjects.register);
  const updateUserHook = useUser(token, subjects.user_update);
  const deleteHook = useUser(token, subjects.user_delete);

  /* modals */
  const editProfileModalToggler = useToggle(false);
  const createUserModalToggler = useToggle(false);
  const deleteUserModalToggler = useToggle(false);
  /*  */

  const anyModalOpen =
    editProfileModalToggler.isToggled ||
    createUserModalToggler.isToggled ||
    deleteUserModalToggler.isToggled;

  const deleteUserForm = useForm();

  const onDeleteUserSubmit = (data: any) => {
    const id = data.id;
    /* SOFT DELETE NOT READY, WAIT FOR BACKEND */
    deleteHook.CTAs.deleteUser(id);
  };

  useEffect(() => {
    /* console.log(deleteHook.ApiB.response, deleteHook.ApiB.result); */
    if (deleteHook.ApiB.response?.ok) {
      resetListToggler.toggle();
      deleteUserModalToggler.setIsToggled(false);
    }
  }, [deleteHook.ApiB.response]);

  useEffect(() => {
    /* console.log(updateUserHook.ApiB.response, updateUserHook.ApiB.result); */
    if (updateUserHook.ApiB.response?.ok) {
      editProfileModalToggler.setIsToggled(false);
      setUser(updateUserHook.ApiB.result.user);
    }
  }, [updateUserHook.ApiB.response]);

  useEffect(() => {
    if (editProfileModalToggler.isToggled) {
      createUserModalToggler.toggle();
    } else if (createUserModalToggler.isToggled) {
      editProfileModalToggler.toggle();
    }
  }, []);

  useEffect(() => {
    /* console.log(registerHook.ApiB.response, registerHook.ApiB.result); */
    if (registerHook.ApiB.response?.ok) {
      createUserModalToggler.setIsToggled(false);
    }
  }, [registerHook.ApiB.response]);

  useEffect(() => {
    if (
      user === null &&
      window.location.pathname.includes(paths.pages.settings)
    ) {
      /* navigate(paths.pages.login); */
    }
  }, []);

  return (
    <div className="page-wrapper">
      {deleteHook.ApiB.isToastVisible && deleteHook.ApiB.toast}
      {updateUserHook.ApiB.isToastVisible && updateUserHook.ApiB.toast}
      {registerHook.ApiB.isToastVisible && registerHook.ApiB.toast}
      {logoutToggler.isToggled && <LogoutModal toggler={logoutToggler} />}
      {editProfileModalToggler.isToggled && (
        <SettingsEditProfileModal
          toggler={editProfileModalToggler}
          user={user}
          isMainUser={true}
          updateUserHook={updateUserHook}
        />
      )}
      {createUserModalToggler.isToggled && (
        <SettingsCreateUserModal
          toggler={createUserModalToggler}
          resetList={resetListToggler}
          registerHook={registerHook}
        />
      )}
      <form onSubmit={deleteUserForm.handleSubmit(onDeleteUserSubmit)}>
        {deleteUserModalToggler.isToggled && (
          <DeleteModal
            id="modal"
            toggler={deleteUserModalToggler}
            register={deleteUserForm.register}
            reset={deleteUserForm.reset}
            user={userTreated}
            deleteType={"user"}
          />
        )}
      </form>
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "1139px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: anyModalOpen && isMobile ? "none" : "",
          }}
        >
          <div
            className="header-box"
            style={{
              width: isMobile ? "345px" : "",
            }}
          >
            <p className={isMobile ? `titlePage-Mob` : `titlePage`}>Settings</p>
            <button
              className="button1"
              type="submit"
              onClick={editProfileModalToggler.toggle}
              style={{
                width: isMobile ? "66px" : "122px",
              }}
            >
              <img className="white" src={edit_icon} />
              {!isMobile && <p className="button1-text">Edit</p>}
            </button>
          </div>
          <div
            className="settings-card shadow"
            style={{
              width: isMobile ? "345px" : "",
              margin: isMobile ? "28px 15px" : "",
              height: isMobile ? "345px" : "",
            }}
          >
            <div
              className="settings-card-content-wrapper"
              style={{
                margin: isMobile ? "48px 20px" : "",
              }}
            >
              <div
                className="settings-card-header"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "",
                }}
              >
                <p
                  className="model-details-card-info-name"
                  style={{
                    fontSize: isMobile ? "32px" : "",
                  }}
                >
                  {`${user?.name} ${
                    user?.surname !== null ? user?.surname : ""
                  }`}
                </p>
                {user?.role === possible_statuses.userType.admin && (
                  <div className="settings-card-header-role-box">
                    <p className="settings-card-header-role">admin</p>
                  </div>
                )}
              </div>
              <div className="settings-card-info-box">
                <p className="settings-card-info-description">
                  Your login data
                </p>
                <div
                  className="client-details-card-row"
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "25px" : "",
                  }}
                >
                  <div className="client-details-card-info-spec-box">
                    <p className="client-details-card-info-spec-field">
                      E-mail
                    </p>
                    <p className="client-details-card-info-spec-value">
                      {user?.email}
                    </p>
                  </div>
                  <div className="client-details-card-info-spec-box">
                    <p className="client-details-card-info-spec-field">
                      Password
                    </p>
                    <input
                      className="client-details-card-info-spec-value"
                      type={"password"}
                      value={"PASSWORD EXAMPLE"}
                      /*  value={
                        user?.password
                          ? user?.password.slice( */
                      /* slice only for better aesthetics, no utility */
                      /*   0,
                              user?.password.length / 3
                            )
                          : ""
                      } */
                      disabled={true}
                      style={{
                        boxShadow: "none",
                        marginTop: "-5px",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {user?.role === possible_statuses.userType.admin && (
            <UsersTable
              toggler={createUserModalToggler}
              deleteToggler={deleteUserModalToggler}
              resetList={resetListToggler}
            />
          )}
          <div style={{ width: isMobile ? "345px" : "80%" }}>
            <button
              className="news-panel-button shadow"
              style={{
                justifySelf: "flex-start",
                width: "101px",
                marginTop: isMobile ? "15px" : "34px",
              }}
              onClick={logoutToggler.toggle}
            >
              <p className="news-panel-buttonText offLightBlue">Log out</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingsPage;
