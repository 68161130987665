function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDateGGMMYYYY = (date: Date, separator?: string) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join(separator ? separator : "-");
};

export const formatDateGGMonthYYY = (date: Date) => {
  var d = new Date(date),
    month = "" + monthNames[d.getMonth() /*  + 1 */],
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month.substring(0, 3), year].join(" ");
};

export const formatDateYYYMMGG = (date: Date, separator?: string) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join(separator ? separator : "-");
};
