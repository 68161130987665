import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import LogMessage from "../../../components/misc/Toast";
import NavBar from "../../../components/misc/NavBar";
import SearchBar from "../../../components/misc/Searcbar";
import SideBar from "../../../components/misc/SideBar";
import { logMsgBgColors } from "../../../consts/misc";
import useIsMobile from "../../../hooks/useIsMobile";
import useToggle from "../../../hooks/useToggle";
import { Influencer } from "../../../interfaces";
import "../../../styles/Models.css";
/* import {
  filterByPlace,
  filterByName,
  filterBySliderField,
  filterByTraits,
  finalFilter,
} from "../../utils/filters/modelFilter"; */
/* import { modelRemappingFromResult } from "../../utils/modelRemapping"; */
/* import FilterContext from "../../context/FilterContext"; */
import {
  backToTopOfPage,
  sortByParamStringAscending,
} from "../../../utils/others";
import InfluencerPageTopButton from "../../../components/influencers/InfluencerPageTopButton";
import Filter from "../../../components/influencers/Filter";
import { influencersMoc } from "../../../consts/moc";
import InfluencerCard from "../../../components/influencers/InfluencerCard";
import {
  filterByName,
  filterByPlats,
  filterByTarget,
  filterByVerts,
  finalFilter,
} from "../../../utils/filters/modelFilter";
import MainContext from "../../../context/MainContext";
import FilterContext from "../../../context/FilterContext";
import useInfluencers from "../../../api/hooks/useInfluencers";
import { paths } from "../../../consts/URLs";
import smallLeft_icon from "../../../assets/ico/small-left.svg";
import { useNavigate } from "react-router-dom";
import { influencerRemappingFromGetAPI } from "../../../utils/remapping";
import useOffer from "../../../api/hooks/useOffers";
import { subjects } from "../../../consts/statuses";

const TalentPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const menuToggler = useToggle(false);

  const trigger = useToggle(false);

  const {
    token,
    refresh,
    influencerSelector,
    offer,
    influencer,
    pageHeight,
    setPageHeight,
  } = useContext(MainContext);
  const filterCTX = useContext(FilterContext);

  const getInfluencersHook = useInfluencers(token);
  const updateOfferInfluencersHook = useOffer(token, subjects.offer_update);
  const createOfferInfluencersHook = useOffer(token, subjects.offer_update);

  const verticalitiesToggler = useToggle(filterCTX.isVerticalitiesOpen);
  const platformsToggler = useToggle(filterCTX.isPlatformsOpen);
  const targetToggler = useToggle(filterCTX.isTargetOpen);
  const selectAllVerticalitiesToggler = useToggle(false);
  const selectAllPlatformsToggler = useToggle(false);
  const selectAllTargetsToggler = useToggle(false);
  const resetFiltersToggler = useToggle(false);

  const isAnyFilterOpen =
    verticalitiesToggler.isToggled ||
    platformsToggler.isToggled ||
    targetToggler.isToggled;

  const influencerFilterByNameForm = useForm();
  const [
    influencersFilterByVerticalitiesObject,
    setInfluencersFilterByVerticalitiesObject,
  ] = useState(filterCTX.isVerticalitiesOpen ? filterCTX.verticalities : {});

  const [
    influencersFilterByPlatformsObject,
    setInfluencersFilterByPlatformsObject,
  ] = useState(filterCTX.isPlatformsOpen ? filterCTX.platforms : {});

  const [influencersFilterByTargetObject, setInfluencersFilterByTargetObject] =
    useState(filterCTX.isTargetOpen ? filterCTX.target : {});

  const { influencerList } = influencerSelector;
  const [modelSelectedQuantity, setModelSelectQuantity] = useState(0);

  const [influencersFetched, setInfluencersFetched] = useState<Influencer[]>(
    []
  );

  const [influencersFilteredByName, setInfluencersFilteredByName] = useState<
    Influencer[]
  >([]);
  const [influencersFilteredByVerts, setInfluencersFilteredByVerts] = useState<
    Influencer[]
  >([]);
  const [influencersFilteredByPlats, setInfluencersFilteredByPlats] = useState<
    Influencer[]
  >([]);
  const [influencersFilteredByTarget, setInfluencersFilteredByTarget] =
    useState<Influencer[] | null>([]);

  const [influencersFiltered, setInfluencersFiltered] = useState<Influencer[]>(
    []
  );
  const [influencersDisplayed, setInfluencersDisplayed] = useState<
    Influencer[]
  >([]);

  /*  const [loadMoreCounter, setLoadMoreCounter] = useState<number>(1); */

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (window.location.pathname === paths.pages.influencers) {
      window.addEventListener("wheel", (e) => {
        if (window.scrollY === 0) {
          setPageHeight(window.scrollY);
        } else setPageHeight(e.pageY);
      });
    }
    if (window.location.pathname != paths.pages.influencers) {
      backToTopOfPage();
      window.addEventListener("wheel", () => setPageHeight(pageHeight));
    }
  });

  const [renderNumber, setRenderNumber] = useState(0);

  useEffect(() => {
    if (influencersDisplayed) {
      setRenderNumber((prevState) => prevState + 1);
    }
  }, [influencersDisplayed]);

  useEffect(() => {
    if (renderNumber < 9 && influencersDisplayed) {
      window.scrollTo(0, pageHeight);
    }
  }, [influencersDisplayed, renderNumber]);

  /* useEffect(() => {
    console.log(pageHeight);
  }, [pageHeight]); */

  useEffect(() => {
    const url = new URL(window.location.href);
    const isEmpty = url.searchParams.get("empty");
    if (isEmpty) {
      setIsEmpty(true);
    } else setIsEmpty(false);
  }, []);

  /* useEffect(() => {
    if (isEmpty) {
      navigateNow.setIsToggled(true);
      navigate(paths.pages.influencer_create);
    } else {
      navigate(`/offers/${offer?.id}`);
    }
  }, [isEmpty]); */

  useEffect(() => {
    if (influencersFiltered) {
      const influencersOnDisplay = influencersFiltered;
      setInfluencersDisplayed(influencersOnDisplay);
    }
  }, [influencersFiltered]);

  useEffect(() => {
    getInfluencersHook.CTAs.getAll();
  }, [refresh]);

  useEffect(() => {
    /* console.log(getInfluencersHook.ApiB.response, getInfluencersHook.ApiB.result); */
    const result = getInfluencersHook.ApiB.result?.talents;
    if (result) {
      let remappedArray: Influencer[] = [];
      result.forEach((influencer: Influencer) => {
        const remappedInfluencer = influencerRemappingFromGetAPI(influencer);
        remappedArray.push(remappedInfluencer);
      });
      setInfluencersFilteredByName(remappedArray);
      setInfluencersFetched(remappedArray);
    }
  }, [getInfluencersHook.ApiB.response]);

  useEffect(() => {
    if (resetFiltersToggler.isToggled) {
      resetFiltersToggler.toggle();
      setInfluencersFilteredByName(influencersFetched);
      const influencersOnDisplay = influencersFiltered;
      setInfluencersDisplayed(influencersOnDisplay);
    }
  }, [resetFiltersToggler]);

  useEffect(() => {
    setModelSelectQuantity(influencerList.length);
  }, [influencerList]);

  useEffect(() => {
    const finalFiltered = finalFilter(
      influencersFetched,
      influencersFilteredByName,
      influencersFilteredByVerts,
      influencersFilteredByPlats,
      influencersFilteredByTarget
    );
    setInfluencersFiltered(finalFiltered);
  }, [
    influencersFilteredByName,
    influencersFilteredByVerts,
    influencersFilteredByPlats,
    influencersFilteredByTarget,
  ]);

  useEffect(() => {
    trigger.setIsToggled((prevState) => !prevState);
  }, [influencersFiltered]);

  const onFilterNameChange = (data: any) => {
    filterCTX.setName(data.name);
    const filteredList = filterByName(influencersFetched, data.name);
    if (filteredList) {
      setInfluencersFilteredByName(filteredList);
    }
  };

  useEffect(() => {
    onFilterVerticalitiesChange();
  }, [influencersFilterByVerticalitiesObject]);

  const onFilterVerticalitiesChange = () => {
    filterCTX.setVerticalities({ ...influencersFilterByVerticalitiesObject });
    const filteredList = filterByVerts(
      influencersFetched,
      influencersFilterByVerticalitiesObject
    );
    setInfluencersFilteredByVerts(filteredList);
  };

  useEffect(() => {
    onFilterPlatformsChange();
  }, [influencersFilterByPlatformsObject]);

  const onFilterPlatformsChange = () => {
    filterCTX.setPlatforms({ ...influencersFilterByPlatformsObject });
    const filteredList = filterByPlats(
      influencersFetched,
      influencersFilterByPlatformsObject
    );
    setInfluencersFilteredByPlats(filteredList);
  };

  useEffect(() => {
    onFilterTargetChange();
  }, [influencersFilterByTargetObject]);

  const onFilterTargetChange = () => {
    filterCTX.setTarget({ ...influencersFilterByTargetObject });
    const filteredList = filterByTarget(
      influencersFetched,
      influencersFilterByTargetObject
    );
    setInfluencersFilteredByTarget(filteredList);
  };

  useEffect(() => {
    if (filterCTX.name) {
      influencerFilterByNameForm.setValue("name", filterCTX.name);
      const filteredList = filterByName(influencersFetched, filterCTX.name);
      if (filteredList) {
        setInfluencersFilteredByName(filteredList);
      }
    }
    if (filterCTX.isVerticalitiesOpen && filterCTX.verticalities) {
      setInfluencersFilterByVerticalitiesObject(filterCTX.verticalities);
    }

    if (filterCTX.isPlatformsOpen && filterCTX.platforms) {
      setInfluencersFilterByPlatformsObject(filterCTX.platforms);
    }

    if (filterCTX.isTargetOpen && filterCTX.target) {
      setInfluencersFilterByTargetObject(filterCTX.target);
    }
  }, [influencersFetched, isAnyFilterOpen]);

  const resetAllFilters = () => {
    resetFiltersToggler.toggle();
    influencerFilterByNameForm.resetField("name");
  };

  const renderInfluencersCard = sortByParamStringAscending(
    influencersDisplayed
  ).map((influencer: Influencer, key: number) => {
    return (
      <InfluencerCard
        key={key}
        influencer={influencer}
        setModelSelectQuantity={setModelSelectQuantity}
        updateOfferListHook={updateOfferInfluencersHook}
        createOfferListHook={createOfferInfluencersHook}
        trigger={trigger.isToggled}
      />
    );
  });

  const filterBox = (
    <Filter
      byVerticalitiesSetAction={setInfluencersFilterByVerticalitiesObject}
      byPlatformsSetAction={setInfluencersFilterByPlatformsObject}
      byTargetSetAction={setInfluencersFilterByTargetObject}
      selectAllVerticalitiesToggler={selectAllVerticalitiesToggler}
      selectAllPlatformsToggler={selectAllPlatformsToggler}
      selectAllTargetsToggler={selectAllTargetsToggler}
      resetAllFilters={resetAllFilters}
      verticalitiesToggler={verticalitiesToggler}
      platformsToggler={platformsToggler}
      targetToggler={targetToggler}
      resetFiltersToggler={resetFiltersToggler}
    />
  );

  return (
    <div className="page-wrapper">
      {updateOfferInfluencersHook.ApiB.isToastVisible &&
        updateOfferInfluencersHook.ApiB.toast}
      {createOfferInfluencersHook.ApiB.isToastVisible &&
        createOfferInfluencersHook.ApiB.toast}
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "775px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
          }}
        >
          <div
            className="header-box"
            style={{
              width: isMobile ? "345px" : "",
            }}
          >
            {influencerSelector.onInfluencerSelection && (
              <div
                className="Oval"
                onClick={() =>
                  navigate(
                    paths.pages.offer_detail.replace(
                      ":id",
                      offer!.id!.toString()
                    )
                  )
                }
              >
                <img src={smallLeft_icon} />
              </div>
            )}
            <p
              className={isMobile ? `titlePage-Mob` : `titlePage`}
              style={{
                width:
                  isMobile && influencerSelector.onInfluencerSelection
                    ? "fit-content"
                    : "",
                textAlign: isMobile ? "center" : "center",
              }}
            >
              {influencerSelector.onInfluencerSelection && "Add "}Influencers
            </p>
            {/*  {!isMobile && <SearchBar notify={true} />} */}
            {isMobile && (
              <InfluencerPageTopButton
                updateOfferListHook={updateOfferInfluencersHook}
                createOfferListHook={createOfferInfluencersHook}
                modelSelectedQuantity={modelSelectedQuantity}
                setModelSelectQuantity={setModelSelectQuantity}
              />
            )}
          </div>
          <div
            className="search-model-box shadow"
            style={{
              width: isMobile ? "345px" : "",
            }}
          >
            <div
              className="search-box-content"
              style={{ width: isMobile ? "" : "100%" }}
            >
              <form
                onChange={influencerFilterByNameForm.handleSubmit(
                  onFilterNameChange
                )}
              >
                <SearchBar
                  notify={false}
                  width={isMobile ? 230 : 332}
                  iconShift={isMobile ? 15 : 11}
                  register={influencerFilterByNameForm.register}
                  errors={influencerFilterByNameForm.formState.errors}
                />
              </form>
              {!isMobile && (
                <InfluencerPageTopButton
                  updateOfferListHook={updateOfferInfluencersHook}
                  createOfferListHook={createOfferInfluencersHook}
                  modelSelectedQuantity={modelSelectedQuantity}
                  setModelSelectQuantity={setModelSelectQuantity}
                />
              )}
            </div>
          </div>

          <div
            className="models-preview-section"
            style={{
              flexDirection: isMobile ? "column" : "row",
              width: isMobile ? "345px" : "",
            }}
          >
            {isMobile && filterBox}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "345px" : "100%",
              }}
            >
              <div
                id="cards-box"
                className="models-preview-list-box"
                style={{
                  width: isMobile ? "345px" : "",
                  display: isMobile ? "flex" : "",
                  flexDirection: isMobile ? "column" : "unset",
                }}
              >
                {renderInfluencersCard}
              </div>
              {/* <div style={{ margin: "80px auto" }}>
                {influencersDisplayed.length < influencersFiltered.length && (
                  <button
                    className="button2"
                    type="button"
                    onClick={() => {
                      setLoadMoreCounter((prevCounter) => prevCounter + 1);
                    }}
                  >
                    <p className="button2-text">Load More</p>
                  </button>
                )}
              </div> */}
            </div>
            {!isMobile && filterBox}
          </div>
        </div>
      </div>
    </div>
  );
};
export default TalentPage;
