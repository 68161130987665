import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useOffer from "../../../api/hooks/useOffers";
import LogMessage from "../../../components/misc/Toast";
import NavBar from "../../../components/misc/NavBar";
import SearchBar from "../../../components/misc/Searcbar";
import SideBar from "../../../components/misc/SideBar";
import OffersTable from "../../../components/offer/OffersTable";
import { logMsgBgColors } from "../../../consts/misc";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../../hooks/useIsMobile";
import useToggle from "../../../hooks/useToggle";
import "../../../styles/Content.css";
import "../../../styles/General.css";
import "../../../styles/Offers.css";
import { backToTopOfPage } from "../../../utils/others";
import plus_icon from "../../../assets/ico/plus.svg";
import OfferCreateModal from "../../../components/modals/OfferCreateModal";
import MainContext from "../../../context/MainContext";
import { subjects } from "../../../consts/statuses";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../consts/URLs";

const OffersPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const { token, setRefresh, setOffer } = useContext(MainContext);
  const createOfferHook = useOffer(token, subjects.offer_create);
  const menuToggler = useToggle(false);
  const navigate = useNavigate();

  /* Modal */
  const toggleOfferModal = useToggle(false);

  /* Forms */
  const createOfferForm = useForm();
  const searchOfferForm = useForm();

  const [offerOnSearchName, setOfferOnSearchName] = useState("");

  const loadMoreToggler = useToggle(false);
  const [loadMoreCounter, setLoadMoreCounter] = useState<number>(1);

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const isEmpty = url.searchParams.get("empty");
    if (isEmpty) {
      setIsEmpty(true);
    } else setIsEmpty(false);
  }, []);

  useEffect(() => {
    if (isEmpty) {
      toggleOfferModal.setIsToggled(true);
    }
  }, [isEmpty]);

  useEffect(() => {
    if (toggleOfferModal.isToggled) {
      backToTopOfPage();
    }
  }, [toggleOfferModal]);

  const onOfferSubmit = (data: any) => {
    createOfferHook.CTAs.createOffer(data);
  };

  useEffect(() => {
    /* console.log(createOfferHook.ApiB.response, createOfferHook.ApiB.result); */
    if (createOfferHook.ApiB.response?.ok) {
      setRefresh((prevState) => !prevState);
      toggleOfferModal.setIsToggled(false);
    }
  }, [createOfferHook.ApiB.response]);

  const onOfferSearch = (data: any) => {
    setOfferOnSearchName(data.name);
  };

  return (
    <div
      className="page-wrapper"
      style={
        {
          /* overflowY: isMobile && menuToggler.isToggled ? "hidden" : "scroll",   */
        }
      }
    >
      {createOfferHook.ApiB.isToastVisible && createOfferHook.ApiB.toast}
      <form onSubmit={createOfferForm.handleSubmit(onOfferSubmit)}>
        {toggleOfferModal.isToggled && (
          <OfferCreateModal
            toggler={toggleOfferModal}
            register={createOfferForm.register}
            errors={createOfferForm.formState.errors}
            onEdit={false}
          />
        )}
      </form>
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "1139px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: toggleOfferModal.isToggled && isMobile ? "none" : "",
          }}
        >
          <div
            className="header-box"
            style={{
              width: isMobile ? "345px" : "",
            }}
          >
            <p className={isMobile ? `titlePage-Mob` : `titlePage`}>Offers</p>
            {/*  {!isMobile && <SearchBar notify={true} />} */}
          </div>
          <div
            className="offers-content shadow"
            style={{
              width: isMobile ? "354px" : "",
              margin: isMobile ? "0 16px 0" : "",
            }}
          >
            <div
              className="search-box-content"
              style={{
                width: isMobile ? "305px" : "",
                margin: isMobile ? " 32px auto 0 " : "",
              }}
            >
              <form onChange={searchOfferForm.handleSubmit(onOfferSearch)}>
                <SearchBar
                  notify={false}
                  width={isMobile ? 170 : 332}
                  iconShift={isMobile ? 20 : 11}
                  register={searchOfferForm.register}
                />
              </form>
              <button
                className="add-button"
                type="button"
                onClick={toggleOfferModal.toggle}
                style={{ width: isMobile ? "56px" : "" }}
              >
                <img className="white" src={plus_icon} />
                {!isMobile && <p className="add-buttonText">New Offer</p>}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <OffersTable
                offerOnSearchName={offerOnSearchName}
                loadMoreToggler={loadMoreToggler}
                loadMoreCounter={loadMoreCounter}
              />
              {/*  <div
                style={{
                  marginTop: "20px",
                  marginBottom: "40px",
                }}
              >
                {!loadMoreToggler.isToggled && (
                  <button
                    className="button2"
                    type="button"
                    onClick={() => {
                      setLoadMoreCounter((prevCounter) => prevCounter + 1);
                    }}
                  >
                    <p className="button2-text">Load More</p>
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OffersPage;
