import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useInfluencers from "../../api/hooks/useInfluencers";
import { possible_statuses } from "../../consts/statuses";
import MainContext from "../../context/MainContext";
import useIsMobile from "../../hooks/useIsMobile";
import { Influencer } from "../../interfaces";
import InfluencerCard from "../influencers/InfluencerCard";
import InfluencerSelectorTag from "./InfluencerSelectorTag";
import { influencersMoc } from "../../consts/moc";
import { paths } from "../../consts/URLs";
import {
  influencerRemappingFromGetAPI,
  influencerRemappingFromProposedList,
  offerRemappingFromResult,
} from "../../utils/remapping";

const InfluencerSelector = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const { influencerSelector, offer, token, refresh } = useContext(MainContext);
  const { influencerList, setInfluencerList, setOnInfluencerSelection } =
    influencerSelector;

  const handleSelection = () => {
    setOnInfluencerSelection(true);
    navigate(paths.pages.influencers);
  };

  useEffect(() => {
    setOnInfluencerSelection(false);
  }, []);

  useEffect(() => {
    if (offer?.proposed_list) {
      const notRejectedList = offer?.proposed_list.filter(
        (influencer) =>
          influencer.status != possible_statuses.influencer.rejected
      );
      let finalList: any[] = [];
      notRejectedList.forEach((el) => {
        finalList.push(influencerRemappingFromProposedList(el));
      });
      setInfluencerList(finalList);
    }
  }, [offer]);

  const renderedInfluencers =
    offer?.proposed_list &&
    offer?.proposed_list
      .filter(
        (influencer) =>
          influencer.status != possible_statuses.influencer.rejected
      )
      .map((influencer, key) => {
        const remappedInfluencer =
          influencerRemappingFromProposedList(influencer);
        return isMobile ? (
          <InfluencerCard
            key={key}
            influencer={remappedInfluencer}
            readOnly={props.readOnly}
            selectorLayout={true}
            updateOfferListHook={props.updateOfferListHook}
            createOfferListHook={props.createOfferListHook}
          />
        ) : (
          <InfluencerSelectorTag
            key={key}
            influencer={remappedInfluencer}
            readOnly={props.readOnly}
            updateOfferListHook={props.updateOfferListHook}
            createOfferListHook={props.createOfferListHook}
          />
        );
      });

  return (
    <div
      className="models-selector shadow"
      style={{
        width: isMobile ? "345px" : "",
        minHeight: isMobile ? "269px" : "",
        maxHeight: isMobile ? "fit-content" : "",
        paddingBottom: renderedInfluencers
          ? isMobile
            ? "15px"
            : "0"
          : isMobile
          ? "0"
          : "15px",
      }}
    >
      <div
        className="models-selector-header"
        style={{
          flexDirection: isMobile ? "column" : "row",
          width: isMobile ? "303.2px" : "",
          margin: isMobile ? "0 auto" : "",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <div className="models-selector-title-box">
          <p
            className="models-selector-title"
            style={{ margin: isMobile ? "0 0 0 7px" : "" }}
          >
            {offer?.status === possible_statuses.offer.approved
              ? "Accepted influencers"
              : "Proposed influencers"}{" "}
            {renderedInfluencers ? renderedInfluencers.length : "0"}{" "}
            {!props.readOnly && "of"}{" "}
            {!props.readOnly && offer?.requested_influencers}
          </p>
          <p
            className="models-selector-description"
            style={{ margin: isMobile ? "0 0 0 7px" : "" }}
          >
            Add influencers in this proposal
          </p>
        </div>
        <button
          className="models-selector-addButton shadow"
          style={{ width: isMobile ? "100%" : "" }}
          type="button"
          disabled={props.readOnly}
          onClick={handleSelection}
        >
          <p className="models-selector-addButtonText">Add influencers</p>
        </button>
      </div>
      <div
        className="models-selector-viewer "
        style={{
          width: isMobile ? "305px" : "",
          minHeight: isMobile ? "56px" : "",
          maxHeight: isMobile ? "fit-content" : "",
          paddingBottom: isMobile ? "0" : "",
          gridTemplateColumns: isMobile ? "1fr" : "",
          backgroundColor: renderedInfluencers ? "transparent" : "",
          display: renderedInfluencers ? "grid" : "flex",
        }}
      >
        {renderedInfluencers || (
          <p className="models-selector-viewer-text">No influencers selected</p>
        )}
      </div>
    </div>
  );
};
export default InfluencerSelector;
