import { json } from "stream/consumers";
import { getPropertiesNames, getPropertiesValues } from "../utils/getMethods";

type OptionsParams = {
  method: string;
  body?: object;
  formData?: FormData;
  token?: string | null;
};

export const getOptions = (params: OptionsParams) => {
  const { method, body, formData, token } = params;
  let myHeaders = new Headers();
  myHeaders.append("Content-type", "application/json; charset=UTF-8");
  if (token) {
    myHeaders.append("Authorization", `Bearer ${token}`);
  }
  if (body) { 
    return {
      method: method,
      body: JSON.stringify(body),
      headers: myHeaders,
      redirect: "follow",
    };
  }
  if (formData) {
    return {
      method: method,
      body: formData,
      headers: myHeaders,
      redirect: "follow",
    };
  } else {
    return {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };
  }
};

export const fetchRequest = async (
  url: string,
  options: Object,
  setActionResponse: Function,
  setActionResult: Function
) => {
  try {
    const response = await fetch(url, options);
    const result = await response.json(); 
    setActionResponse(response);
    setActionResult(result);
  } catch (error) {
    setActionResponse(error);
  }
};

export const urlRequestParamBuilder = (object: Object) => {
  const keys = getPropertiesNames(object);
  const values = getPropertiesValues(object);
  let composedURL = "?";
  for (let index = 0; index < keys.length; index++) {
    composedURL += `${keys[index]}=${values[index]}`;
    if (index === keys.length - 1) {
    } else composedURL += "&";
  }
  return composedURL;
};
