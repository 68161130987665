import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile"; /* 
import { Offer } from "../../interfaces"; */
import { getEarningsCurrentMonth } from "../../utils/getMethods";
import DoubleBarChart from "../chart/DoubleBarChart";
import { transformBudget } from "../../utils/others";
/* import DoubleBarChart from "../chart/DoubleBarChart"; */

const EarningsSideBar = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const [offersFetched, setOfferFetched] = useState</* Offer */ any[] | null>(
    []
  );
  const [amountEarnedThisMonth, setAmountEarnedThisMonth] = useState(0);

  useEffect(() => {
    if (props.allOffers) {
      setOfferFetched(props.allOffers);
    }
  }, [props.allOffers]);

  useEffect(() => {
    const earnings = getEarningsCurrentMonth(offersFetched);
    setAmountEarnedThisMonth(Number.parseFloat(earnings));
  }, [offersFetched]);

  return (
    <div
      className="earnings-sidebar"
      style={{
        height: isMobile ? "fit-content" : "",
        borderLeft: isMobile ? "" : " 1px solid #e4e4e4",
      }}
    >
      <div style={{ margin: isMobile ? "0" : "0 64px 0px 49px" }}>
        <div style={{ margin: isMobile ? "" : "195px 0 0 0" }}>
          {/* {!isMobile && <SearchBar width={170} notify={true} />} */}
        </div>
        <div
          className="earnings-sidebar-month-box shadow"
          style={{
            width: isMobile ? "345px" : "",
          }}
        >
          <p className="earnings-sidebar-month-title">
            Your earning this month
          </p>
          <p className="earnings-sidebar-month-value">
            € {transformBudget(Number(amountEarnedThisMonth))}
          </p>
          <p className="earnings-sidebar-month-info">
            Remember to update the status of the offers
          </p>
          <button
            className="earnings-sidebar-month-button shadow"
            onClick={() => {
              navigate("/offers");
            }}
          >
            <p className="earnings-sidebar-month-buttonText">
              Verify the offers
            </p>
          </button>
        </div>
        <div
          className="earnings-sidebar-offersVSapproved-chart"
          style={{
            margin: isMobile ? "61px auto" : "69px 0",
            width: isMobile ? "345px" : "",
          }}
        >
          <p className="earnings-sidebar-offersVSapproved-chart-title">
            Offers vs approved
          </p>
          <div style={{ margin: "69px 0 0 -20px" }}>
            <DoubleBarChart
              width={isMobile ? 345 + 20 : 298 + 20}
              height={276}
              allOffers={props.allOffers}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default EarningsSideBar;
