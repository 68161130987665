import { useContext } from "react";
import { useNavigate } from "react-router-dom";
/* import MainContext from "../../context/MainContext"; */
import "../../styles/Content.css";
import MainContext from "../../context/MainContext";
import { paths } from "../../consts/URLs";

const Avatar = (props: any) => {
  const navigate = useNavigate();

  const { user, setUserTreated } = useContext(MainContext);

  const handleOnClick = () => {
    if (props.user?.id === user?.id) {
      navigate(paths.pages.settings);
    } else {
      setUserTreated(props.user);
      navigate(paths.pages.settings.replace(":id", props.user?.id));
    }
  };

  return (
    <div
      className="avatar"
      style={{
        height: `${props.size}px`,
        width: `${props.size}px`,
        cursor: "pointer",
        backgroundColor: props.bgColor ? props.bgColor : "",
      }}
      onClick={handleOnClick}
    >
      {false ? (
        <div />
      ) : (
        <p className="avatar-initals" style={{ cursor: "pointer" }}>
          {`${props.user?.name && props.user?.name.toString().slice(0, 1)}${
            props.user?.surname === null || props.user?.surname === undefined
              ? ""
              : props.user?.surname &&
                props.user?.surname.toString().slice(0, 1)
          }`}
        </p>
      )}
    </div>
  );
};
export default Avatar;
