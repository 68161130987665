import useIsMobile from "../../hooks/useIsMobile";

const InfluencerDetailsCard = (props: any) => {
  const { isMobile } = useIsMobile();
  return (
    <div
      className="model-details-card shadow"
      style={{
        justifyContent: isMobile ? "flex-start" : "",
        flexDirection: isMobile ? "column" : "row",
        width: isMobile ? "345px" : "",
        height: isMobile ? "500px" : "",
      }}
    >
      <div
        className="model-details-card-image"
        style={{
          marginTop: isMobile ? "20px" : "",
          backgroundImage: `url(${props.influencer?.profileImage})`,
          width: isMobile ? "85%" : "",
          height: isMobile ? "300px" : "",
          backgroundPosition: "center",
        }}
      />
      <div
        className="model-details-card-info"
        style={{
          marginTop: isMobile ? "16px" : "",
          width: isMobile ? "296px" : "",
          height: isMobile ? "fit-content" : "",
        }}
      >
        <p
          className="model-details-card-info-name"
          style={{
            width: isMobile ? "fit-content" : "" /* 
            height: isMobile ? "33px" : "", */,
            fontSize: isMobile ? "31px" : "",
          }}
        >{`${props.influencer?.name} ${props.influencer?.surname}`}</p>
        <div
          className="model-details-card-info-specs"
          style={{
            flexDirection: isMobile ? "column" : "row",
            width: isMobile ? "100%" : "",
            gap: isMobile ? "20px" : "30px",
          }}
        >
          {
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "row" : "row",
                gap: isMobile ? "70px" : "30px",
              }}
            >
              <div className="model-details-card-info-spec">
                <p className="model-details-card-info-spec-field">Birthday</p>
                <p className="model-details-card-info-spec-value">
                  {props.influencer?.birth_date}
                </p>
              </div>
              <div className="model-details-card-info-spec">
                <p className="model-details-card-info-spec-field">City</p>
                <p className="model-details-card-info-spec-value">
                  {props.influencer?.city}
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default InfluencerDetailsCard;
