import { apiURL } from "../../consts/URLs";
import { INFLUENCERS, POSTS, SOCIAL } from "../endpointList";
import { getOptions } from "../utils";
import useApiBuilder from "./useApiBuilder";

const useSoacialPost = (token: string | null) => {
  const ApiB = useApiBuilder();

  const getSocialPosts = (id: number, social_id: number) => {
    const url = `${apiURL}/${INFLUENCERS}/${id}/${SOCIAL}/${social_id}/${POSTS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  return { getSocialPosts, ApiB };
};
export default useSoacialPost;
