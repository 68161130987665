import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { possible_statuses } from "../../consts/statuses";
import useIsMobile from "../../hooks/useIsMobile";
import { getOfferStatus } from "../../utils/getMethods";
import MainContext from "../../context/MainContext";
import { paths } from "../../consts/URLs";
import { offerRemappingFromResult } from "../../utils/remapping";
import { formatDateGGMMYYYY } from "../../utils/reformatDate";
import closed from "../../assets/illustration/closed.jpg";
import { transformBudget } from "../../utils/others";

const OfferCard = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const offerStatus = getOfferStatus(props.offer.status);

  const { setOffer, token } = useContext(MainContext);

  const [offerImageSrc, setOfferImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (props.offer.proposed_list && props.offer.proposed_list.length > 0) {
      let profileImgSrc = "";
      if (
        props.offer.status === possible_statuses.offer.approved ||
        props.offer.status === possible_statuses.offer.substatus.confirmed
      ) {
        profileImgSrc = props.offer.proposed_list.filter(
          (item: any) => item.status === possible_statuses.influencer.approved
        )[0]?.talent_profileImage;
      } else if (props.offer.status === possible_statuses.offer.open) {
        profileImgSrc = props.offer.proposed_list.filter(
          (item: any) => item.status === possible_statuses.influencer.pending
        )[0]?.talent_profileImage;
      } else profileImgSrc = closed;
      setOfferImageSrc(profileImgSrc);
    }
  }, [props.offer]);

  const handleOnClick = () => {
    if (props.offer) {
      setOffer(props.offer);
      navigate(
        paths.pages.offer_detail.replace(":id", props.offer.id.toString())
      );
    }
  };
 
  return (
    <div
      className="offer-card"
      style={{ cursor: isMobile ? "pointer" : "pointer" }}
      onClick={handleOnClick}
    >
      <div
        className="offer-card-name-box"
        style={{ cursor: isMobile ? "pointer" : "" }}
      >
        {!isMobile && (
          <div
            className="offer-card-name-box-image"
            style={{
              backgroundImage: offerImageSrc ? `url(${offerImageSrc})` : "",
              backgroundPosition: "center",
            }}
          />
        )}
        <div
          className="offer-card-name-box-specs"
          style={{
            width: isMobile ? "90px" : "",
            justifyContent: "left",
            cursor: "pointer",
          }}
        >
          <p
            className="offer-card-name-box-title"
            style={{
              width: isMobile ? "140px" : "",

              cursor: "pointer",
            }}
          >
            {props.offer.offer_name}
          </p>
          <p
            className="offer-card-name-box-agency"
            style={{
              width: isMobile ? "fit-content" : "",
              cursor: "pointer",
            }}
          >
            {props.offer.client.brand_name}
          </p>
        </div>
      </div>

      {!isMobile && (
        <p className="offer-card-date" style={{ cursor: "pointer" }}>
          {formatDateGGMMYYYY(new Date(props.offer.issue_date))}
        </p>
      )}
      {!isMobile && (
        <p className="offer-card-models" style={{ cursor: "pointer" }}>
          {props.offer.requested_influencers}
        </p>
      )}
      {!isMobile && (
        <p
          className="offer-card-price"
          style={{ textAlign: "right", cursor: "pointer" }}
        >
          {props.offer.budget && "€  "}
          {transformBudget(Number(props.offer.budget))}
        </p>
      )}

      <div
        className="offer-card-status-box"
        style={{
          backgroundColor: offerStatus?.bgColor,
          cursor: "pointer",
        }}
      >
        <p
          className="offer-card-status-box-text"
          style={{
            color: offerStatus?.txtColor,
            cursor: "pointer",
          }}
        >
          {offerStatus?.txt}
        </p>
      </div>
    </div>
  );
};
export default OfferCard;
