import { useEffect } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import close_icon from "../../assets/ico/close.svg";
import delete_icon from "../../assets/illustration/delete.svg";

const DeleteModal = (props: any) => {
  const { isMobile } = useIsMobile();

  const typeTreated = props.deleteType;
  useEffect(() => {
    props.reset({ id: props[typeTreated].id });
  }, []);

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
        zIndex: "3",
      }}
    >
      <div
        className={`offer-delete-modal   ${
          isMobile && "general-modal-dimension-Mob"
        }`}
        style={{
          width: isMobile ? "345px" : "",
          height: isMobile ? "433px" : "",
          margin: "auto auto",
        }}
      >
        <input
          type="number"
          {...props.register("id")}
          value={props[typeTreated].id}
          style={{ display: "none" }}
        />
        <div
          className="offer-delete-modal-content"
          style={{
            marginTop: isMobile ? "28px" : "",
          }}
        >
          <div className="modal-header">
            <div>
              <p className="modal-title">Delete {typeTreated}</p>
            </div>
            <div className="Oval" onClick={props.toggler.toggle}>
              <img src={close_icon} />
            </div>
          </div>
          <div
            className="delete-image"
            style={{
              backgroundImage: `url(${delete_icon})`,
              width: isMobile ? "120px" : "",
              height: isMobile ? "118.4px" : "",
              marginTop: isMobile ? "29px" : "",
            }}
          />
          <p
            className="delete-title"
            style={{
              fontSize: isMobile ? "18px" : "",
              width: isMobile ? "299px" : "500px",
            }}
          >
            Are you sure you want to delete{" "}
            {typeTreated === "user"
              ? props[typeTreated].name + " " + props[typeTreated].surname
              : "this " + typeTreated}
            ?
          </p>
          <div className="delete-cta-box">
            <button
              className="delete-cta-button whiteBG shadow"
              type="button"
              onClick={props.toggler.toggle}
            >
              <p className="delete-cta-button-text offLightBlueTXT">Not now</p>
            </button>
            <button className="delete-cta-button offLightBlueBG" type="submit">
              <p className="delete-cta-button-text whiteTXT">Yes, delete</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeleteModal;
