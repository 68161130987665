import { useEffect, useState } from "react";
/* import { Input } from "../../interfaces"; */

const SwitchInput = (inputProps: any) => {
  const [isChecked, setIsChecked] = useState(false);

  const handelChangeValue = () => {
    setIsChecked((prevState) => !prevState);
  };

  useEffect(() => {
    if (inputProps.setValue && isChecked) {
      inputProps.setValue(`${inputProps.name}`, isChecked);
    } else if (inputProps.setValue && isChecked === false) {
      inputProps.setValue(`${inputProps.name}`, null);
    }
    if (inputProps?.others?.setAction) {
      inputProps?.others?.setAction(isChecked);
    }
  }, [isChecked]);

  /*  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
     
    inputProps.register(`${inputProps.name}`, { value: e.target.checked });
  }; */

  return (
    <div
      className="custom-input-switch-box"
      style={{ width: inputProps.width }}
    >
      {/* <input
        type="checkbox" 
        onChange={(e) => handleInputChange(e)}
        checked={isChecked}
      /> */}
      <div
        className={`switch-input-exterior ${
          isChecked ? "switch-exterior-on" : "switch-exterior-off"
        }`}
        onClick={handelChangeValue}
      >
        <div
          className={`switch-input-interior ${
            isChecked ? "switch-interior-on" : "switch-interior-off"
          }`}
        ></div>
      </div>
      <p className="custom-input-switch-label">{inputProps.title}</p>
    </div>
  );
};
export default SwitchInput;
