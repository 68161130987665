import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useClient from "../../api/hooks/useClients";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { Client } from "../../interfaces";
import "../../styles/Modals.css";
import { formatDateYYYMMGG } from "../../utils/reformatDate";
import CustomInput from "../inputs/CustomInput";
import close_icon from "../../assets/ico/close.svg";
import MainContext from "../../context/MainContext";

const OfferCreateModal = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { token, refresh, client } = useContext(MainContext);
  const getClientsHook = useClient(token);

  const [clientList, setClientList] = useState<Client[]>([]);
  const [clientDefaultValue, setClientDefaultValue] = useState<number | null>(
    null
  );

  useEffect(() => {
    getClientsHook.CTAs.getListAll();
  }, [refresh]);

  useEffect(() => {
    /* console.log(getClientsHook.ApiB.response, getClientsHook.ApiB.result); */
    if (getClientsHook.ApiB.response?.ok) {
      setClientList(getClientsHook.ApiB.result.clients);
    }
  }, [getClientsHook.ApiB.response]);

  useEffect(() => {
    if (props.client) {
      setClientDefaultValue(props.client.id);
    }
  });

  useEffect(() => {
    if (props.onEdit) {
      props.reset(props.offer);
    }
  }, [client]);

  useEffect(() => {
    if (!props.onEdit && props.client) {
      props.setValue("client", props.client.id);
    }
  }, [props.client]);

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        className={`offer-create-modal scroll-y  ${
          isMobile && "general-modal-dimension-Mob"
        }`}
        style={{
          width: isMobile ? "calc(345px - 64px)" : "" /* 
          height: "75vh", */,
          padding: isMobile ? "25px 19px 0 21px" : "",
        }}
      >
        <div
          className="offer-create-modal-content"
          style={{ margin: isMobile ? "0" : "" }}
        >
          <div className="modal-header">
            <p className="modal-title">
              {props.onEdit ? "Edit offer" : "New offer"}
            </p>
            <div className="Oval" onClick={props.toggler.toggle}>
              <img src={close_icon} />
            </div>
          </div>
          <div style={{ marginTop: isMobile ? "33px" : "" }}>
            <CustomInput
              title={"Offer's name"}
              name={"offer_name"}
              width={"100%"}
              type="text"
              register={props.register}
              errors={props.errors}
              defaultValue={props.onEdit ? props.offer.name : ""}
              constraints={{
                required: true,
              }}
            />
          </div>
          <div style={{ marginTop: "26px" }}>
            <CustomInput
              title={"Client"}
              name={"client_id"}
              type="select"
              register={props.register}
              errors={props.errors}
              options={clientList}
              defaultValue={clientDefaultValue}
              idValue={true}
              constraints={{
                required: true,
              }}
            />
          </div>
          <div
            className="offer-create-modal-date-location-inputs-box"
            style={{
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "20px" : "",
            }}
          >
            <CustomInput
              width={isMobile ? "100%" : 230}
              title={"Date"}
              name={"issue_date"}
              type="Date"
              register={props.register}
              errors={props.errors}
              defaultValue={
                props.onEdit
                  ? formatDateYYYMMGG(new Date(props.offer.issue_date))
                  : new Date()
              }
              constraints={
                {
                  /* required: true, */
                }
              }
            />
            <CustomInput
              width={isMobile ? "100%" : 254}
              title={"Location"}
              name={"location"}
              type="text"
              register={props.register}
              errors={props.errors}
              defaultValue={props.onEdit ? props.offer.location : ""}
              constraints={
                {
                  /*  required: true, */
                }
              }
            />
          </div>
          <div style={{ marginTop: isMobile ? "130px" : "26px" }}>
            <CustomInput
              height={isMobile ? 221 : 157}
              title={"Description"}
              name={"short_description"}
              type="textarea"
              register={props.register}
              errors={props.errors}
              defaultValue={props.onEdit ? props.offer.short_description : ""}
            />
          </div>
        </div>
        <button
          className="modal-button"
          type="submit"
          disabled={props.readOnly}
          style={{ width: isMobile ? "80%" : "" }}
        >
          <p className="modal-button-text">
            {props.onEdit ? "Save" : "Create Offer"}
          </p>
        </button>
      </div>
    </div>
  );
};
export default OfferCreateModal;
