import { useState, useEffect } from "react";
import { cookieName } from "./consts/cookie";
import MainContext from "./context/MainContext";
import { Client, Influencer, Offer, User } from "./interfaces";
import { getCookie } from "./utils/handleCookie";
import PageRouter from "./routing/PageRouter";

function App() {
  const [pageHeight, setPageHeight] = useState(0);

  const [tempInfluencerList, setTempInfluencerList] = useState<
    Influencer[] | []
  >([]);

  const [influencerList, setInfluencerList] = useState<Influencer[] | []>([]);

  const [onInfluencerSelection, setOnInfluencerSelection] =
    useState<boolean>(false);

  const [user, setUser] = useState<User | null>(null);

  const [userTreated, setUserTreated] = useState<User | null>(null);

  const [offer, setOffer] = useState<Offer | null>(null);

  const [client, setClient] = useState<Client | null>(null);

  const [influencer, setInfluencer] = useState<Influencer | null>(null);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [token, setToken] = useState<string | null>(
    getCookie(cookieName.token)
  );

  /*  const story = useStoryblok("home", { version: "draft" });

  if (!story || !story.content) {
    return <div></div>;
  } */

  return (
    <MainContext.Provider
      value={{
        pageHeight,
        setPageHeight,
        influencerSelector: {
          onInfluencerSelection,
          setOnInfluencerSelection,
          tempInfluencerList,
          setTempInfluencerList,
          influencerList,
          setInfluencerList,
        },
        refresh,
        setRefresh,
        token,
        setToken,
        user,
        userTreated,
        offer,
        client,
        influencer,
        setUser,
        setUserTreated,
        setOffer,
        setClient,
        setInfluencer,
      }}
    >
      {/* <StoryblokComponent blok={story.content} /> */}
      <PageRouter />
    </MainContext.Provider>
  );
}

export default App;
