import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useRecoveryPassord from "../../../api/hooks/password/useRecoveryPassword";
import email_illustration from "../../../assets/illustration/email.svg";
import logo_main from "../../../assets/logo_main.png";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/inputs/CustomInput";
import FeedMessage from "../../../components/misc/FeedMessage";
import { authInputs } from "../../../consts/inputs";
import useIsMobile from "../../../hooks/useIsMobile";
import useLogMessage from "../../../hooks/useLogMessage";
import useToggle from "../../../hooks/useToggle";
import "../../../styles/Access.css";
import "../../../styles/General.css";
import { getInputs } from "../../../utils/getMethods";

const RecoveryPasswordPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const { recoveryPassword, ApiB } = useRecoveryPassord(); 

  const emailSent = useToggle(false);

  useEffect(() => {
    /* console.log(ApiB.response,ApiB.result) */
    if (ApiB.response?.ok) {
      emailSent.toggle();
    }
  }, [ApiB.response]);

  const recoveryForm = useForm();
  const {
    formState: { errors },
  } = recoveryForm;

  const chosenInputs = getInputs(authInputs, ["email"]);
  const renderInputs = chosenInputs.map((input, key) => {
    return (
      <CustomInput
        key={key}
        {...input}
        register={recoveryForm.register}
        errors={errors}
      />
    );
  });

  const onSubmit = (data: any) => {
    recoveryPassword(data.email);
  };

  return (
    <div className="access-background">
     {ApiB.isToastVisible && ApiB.toast} 
      <img className="logo-main" src={logo_main} />
      {emailSent.isToggled ? (
        <FeedMessage
          imageBgClassName="mailImage-background"
          imageSrc={email_illustration}
          title="Congratulations!"
          description={`We have sent an e-mail to ${recoveryForm.getValues(
            "email"
          )} with the link to reset your password`}
          link={{ path: "/", text: "Go to Log In" }}
        />
      ) : (
        <div className={`access-content-box ${isMobile && "a-c-b-Mob"}`}>
          <div className="access-content-box-inner-wrapper">
            <form onSubmit={recoveryForm.handleSubmit(onSubmit)}>
              <div className="access-contentBox-header">
                <div className="cornerTitle">
                  {"Forgot Password"}
                  <p className="cornerSubTitle">
                    {"Enter the email you used to register"}
                  </p>
                </div>
              </div>
              <div style={{ marginTop: "32px" }}>
                <div style={{ marginTop: "-20px" }}>
                  {renderInputs}
                  {errors.email && (
                    <p className="errorInput">
                      {"Please provide a properly formatted e-mail address"}
                    </p>
                  )}
                </div>
              </div>
              <div
                className="contentBox-confirmationBox"
                style={{ marginTop: "50px" }}
              >
                <p
                  className="forgotpassword-link"
                  onClick={() => {
                    window.location.href = `/`;
                  }}
                >
                  {"Back"}
                </p>
                <button className="button1 b-short" type="submit">
                  <p className="button1-text">{"Recover"}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default RecoveryPasswordPage;
