import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useClient from "../../../api/hooks/useClients";
import plus_icon from "../../../assets/ico/plus.svg";
import ClientsTable from "../../../components/client/ClientsTable";
import NavBar from "../../../components/misc/NavBar";
import SearchBar from "../../../components/misc/Searcbar";
import SideBar from "../../../components/misc/SideBar";
import ClientCreateModal from "../../../components/modals/ClientCreateModal";
import { subjects } from "../../../consts/statuses";
import MainContext from "../../../context/MainContext";
import useIsMobile from "../../../hooks/useIsMobile";
import useToggle from "../../../hooks/useToggle";
import "../../../styles/Clients.css";

const ClientsPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const { token } = useContext(MainContext);
  const createHook = useClient(token, subjects.client_create);
  const resetListToggler = useToggle(false);
  const menuToggler = useToggle(false);
  const toggleNewClientModal = useToggle(false);

  const onSearchForm = useForm();
  const newClientForm = useForm();

  const [clientOnSearchName, setClientOnSearchName] = useState("");

  const loadMoreToggler = useToggle(false);
  const [loadMoreCounter, setLoadMoreCounter] = useState<number>(1);

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    const isEmpty = url.searchParams.get("empty");
    if (isEmpty) {
      setIsEmpty(true);
    } else setIsEmpty(false);
  }, []);

  useEffect(() => {
    if (isEmpty) {
      toggleNewClientModal.setIsToggled(true);
    }
  }, [isEmpty]);

  const onClientSearch = (data: any) => {
    setClientOnSearchName(data.name);
  };

  /*----------------Create Client----------------------  */
  const onNewClientSubmit = (data: any) => {
    let finalObj = {};
    const final = Object.entries(data).filter((o) => o[1] != "");

    final.forEach((keyvalue: any) => {
      let property: object = {};
      property = { [keyvalue[0]]: keyvalue[1] };
      finalObj = { ...finalObj, ...property };
    });
    console.log(JSON.stringify(finalObj));
    createHook.CTAs.createClient(finalObj);
  };
  /* ---------------------------------------------------------- */

  /* ----------------On Create Client Response------------------ */
  useEffect(() => {
    /* console.log(createHook.ApiB.response, createHook.ApiB.result); */
    if (createHook.ApiB.response?.ok) {
      toggleNewClientModal.setIsToggled(false);
      resetListToggler.toggle();
    }
  }, [createHook.ApiB.response]);
  /* ---------------------------------------------------------- */

  return (
    <div className="page-wrapper is-hidden is-visuallyHidden">
      {createHook.ApiB.isToastVisible && createHook.ApiB.toast}
      <form onSubmit={newClientForm.handleSubmit(onNewClientSubmit)}>
        {toggleNewClientModal.isToggled && (
          <ClientCreateModal
            toggler={toggleNewClientModal}
            register={newClientForm.register}
            errors={newClientForm.formState.errors}
          />
        )}
      </form>
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "805px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: toggleNewClientModal.isToggled && isMobile ? "none" : "",
          }}
        >
          <div
            className="header-box"
            style={{
              width: isMobile ? "345px" : "",
            }}
          >
            <p className={isMobile ? `titlePage-Mob` : `titlePage`}>Clients</p>
            {/*  <SearchBar notify={true} /> */}
          </div>
          <div
            className="clients-table-box shadow"
            style={{
              width: isMobile ? "345px" : "",
              margin: isMobile ? "0 16px 0" : "",
            }}
          >
            <div
              className="search-box-content"
              style={{
                margin: isMobile ? "32px 21px 0" : "",
                justifyContent: isMobile ? "space-between" : "",
              }}
            >
              <form onChange={onSearchForm.handleSubmit(onClientSearch)}>
                <SearchBar
                  notify={false}
                  width={isMobile ? 170 : 332}
                  iconShift={isMobile ? 20 : 11}
                  register={onSearchForm.register}
                />
              </form>
              <button
                className="add-button"
                type="button"
                onClick={toggleNewClientModal.toggle}
                style={{
                  width: isMobile ? "56px" : "",
                }}
              >
                <img className="white" src={plus_icon} />
                {!isMobile && <p className="add-buttonText">New Client</p>}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ClientsTable
                clientOnSearchName={clientOnSearchName}
                resetToggler={resetListToggler}
                loadMoreToggler={loadMoreToggler}
                loadMoreCounter={loadMoreCounter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientsPage;
