import { useContext, useEffect, useState } from "react";
import useOffer from "../../api/hooks/useOffers";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
/* import { Offer } from "../../interfaces"; */
/* import { filterByName } from "../../utils/filters/offerFilter"; */
import OfferCard from "./OfferCard";
import MainContext from "../../context/MainContext";
import { Offer } from "../../interfaces";
import { mockOffers } from "../../consts/moc";
import { filterByName } from "../../utils/filters/offerFilter";
import { subjects } from "../../consts/statuses";
import { offerRemappingFromResult } from "../../utils/remapping";

const OffersTable = (props: any) => {
  const { isMobile } = useIsMobile();
  const { offerOnSearchName } = props;

  const { token, refresh } = useContext(MainContext);
  const getOfferHook = useOffer(token, subjects.offer);

  const [offersFetched, setOffersFetched] = useState<Offer[]>([]);
  const [offersFiltered, setOffersFiltered] = useState<Offer[]>([]);
  const [offersDisplayed, setOffersDisplayed] = useState<Offer[]>([]);

  const { setIsToggled } = props.loadMoreToggler;
  const offersPerLoad = 4;

  useEffect(() => {
    getOfferHook.CTAs.getOffers();
  }, [refresh]);

  /* ------------On Get Offer Response------------ */

  useEffect(() => {
    /* console.log(getOfferHook.ApiB.response, getOfferHook.ApiB.result); */
    if (getOfferHook.ApiB.response?.ok) {
      let finalRemappedOffers: Offer[] = [];
      getOfferHook.ApiB.result.offers.forEach((o: any) => {
        const offerRemapped = offerRemappingFromResult(o);
        finalRemappedOffers.push(offerRemapped);
      });
      setOffersFetched(finalRemappedOffers);
    }
  }, [getOfferHook.ApiB.response]);
  /* ---------------------------------------------- */

  useEffect(() => {
    if (offerOnSearchName !== "") {
      const filteredList = filterByName(offersFetched, offerOnSearchName);
      setOffersFiltered(filteredList);
    } else setOffersFiltered(offersFetched);
  }, [offerOnSearchName]);

  useEffect(() => {
    if (offersDisplayed.length < offersFiltered.length) {
      setIsToggled(false);
    } else if (offersDisplayed.length === offersFiltered.length) {
      setIsToggled(true);
    }
  }, [offersDisplayed]);

  useEffect(() => {
    if (offersFiltered) {
      const offersOnDisplay = offersFiltered;
      setOffersDisplayed(offersOnDisplay);
    }
  }, [offersFiltered]);

  useEffect(() => {
    if (offersFetched) {
      setOffersFiltered(offersFetched);
    }
  }, [offersFetched]);

  const renderOffers = offersDisplayed?.map((offer, key) => {
    return (
      <div key={key} className="offers-table-row">
        <OfferCard offer={offer} />
      </div>
    );
  });

  return (
    <div className="offers-table">
      <div className="offers-table-fields">
        <div
          className="o-name"
          style={{
            display: "flex",
            width: isMobile ? "174px" : "",
            marginLeft: isMobile ? "0" : "",
          }}
        >
          <p className="field-label">Name</p>
        </div>
        {!isMobile && (
          <>
            <div className="o-date" style={{ display: "flex" }}>
              <p className="field-label">Date</p>
            </div>
            <div
              className="o-models"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <p className="field-label">Influencer</p>
            </div>
            <div
              className="o-price"
              style={{ display: "flex", justifyContent: "right" }}
            >
              <p className="field-label">Price</p>
            </div>
          </>
        )}
        <div
          className="o-status "
          style={{
            display: "flex",
            justifyContent: isMobile ? "left" : "center",
            width: isMobile ? "92px" : "",
          }}
        >
          <p className="field-label">Status</p>
        </div>
      </div>
      <div className="divider" style={{ width: "100%", marginTop: "10px" }} />
      <div className="offers-table-results scrollable-element">
        {renderOffers}
      </div>
    </div>
  );
};
export default OffersTable;
