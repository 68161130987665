import { useContext, useEffect } from "react";
import useLogout from "../../api/hooks/authentication/useLogout";
import { cookieName } from "../../consts/cookie";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { deleteCookie, setCookie } from "../../utils/handleCookie";
import close_icon from "../../assets/ico/close.svg";
import MainContext from "../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { paths } from "../../consts/URLs";

const LogoutModal = (props: any) => {
  const { token } = useContext(MainContext);
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const { logout, ApiB } = useLogout(token);

  const handleLogout = () => {
    logout();
    deleteCookie(cookieName.rememberMe);
    deleteCookie(cookieName.user_essential);
    deleteCookie(cookieName.token);
    window.location.reload();
  };

  useEffect(() => {
    /* console.log(ApiB.response, ApiB.result); */
    if (ApiB.response?.ok) {
      navigate(paths.pages.login);
    }
  }, [ApiB.response]);

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        className="logout-modal"
        style={{
          height: isMobile ? "352px" : "402px",
          width: isMobile ? "345px" : "628px",
        }}
      >
        <div className="logout-modal-content">
          <div className="modal-header">
            <div>
              <p
                className="modal-title"
                style={{
                  fontWeight: "600",
                }}
              >
                Log out
              </p>
            </div>
            <div className="Oval" onClick={props.toggler.toggle}>
              <img src={close_icon} />
            </div>
          </div>
          <p
            className="delete-title"
            style={{
              fontSize: isMobile ? "18px" : "",
              fontWeight: "600",
              width: isMobile ? "299px" : "400px",
              marginTop: "76px",
            }}
          >
            Are you sure you want to logout?
          </p>
          <div className="delete-cta-box" style={{ marginTop: "15px" }}>
            <button
              className="delete-cta-button whiteBG shadow"
              type="button"
              onClick={props.toggler.toggle}
            >
              <p className="delete-cta-button-text offLightBlueTXT">Not now</p>
            </button>
            <button
              className="delete-cta-button offLightBlueBG"
              onClick={handleLogout}
            >
              <p className="delete-cta-button-text whiteTXT">Yes, I'm sure</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogoutModal;
