import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OffersPage from "./pages/agency/OffersPage";
import OverviewPage from "./pages/agency/OverviewPage";
import FirstAccessPage from "./pages/authentication/FirstAccessPage";
import RecoveryPasswordPage from "./pages/authentication/RecoveryPasswordPage";
import ResetPasswordPage from "./pages/authentication/ResetPasswordPage";
import VerifyPage from "./pages/authentication/VerifyPage";
import LoginPage from "./pages/authentication/LoginPage";
import InfluencerDetailPage from "./pages/agency/subsections/InfluencerDetailPage";
import InfluencerEditPage from "./pages/agency/subsections/InfluencerEditPage";
import OfferDetailPage from "./pages/agency/subsections/OfferDetailPage";
import ShareOfferPage from "./pages/agency/subsections/ShareOfferPage";
import ClientsPage from "./pages/general/ClientsPage";
import ClientDetailPage from "./pages/general/subsections/ClientDetailPage";
import SettingsPage from "./pages/general/SettingsPage";
import UserDetailPage from "./pages/general/subsections/UserDetailPage";
import { paths } from "../consts/URLs";
import InfluencerPage from "./pages/agency/InfluencersPage";
import { useState, useEffect, useContext } from "react";
import FilterContext from "../context/FilterContext";
import MainContext from "../context/MainContext";
import useUser from "../api/hooks/useUser";
import { possible_statuses, subjects } from "../consts/statuses";
import { deleteCookie, getCookie } from "../utils/handleCookie";
import { cookieName } from "../consts/cookie";
import useLogin from "../api/hooks/authentication/useLogin";

const PageRouter = (props: any) => {
  const [fCtxIsVerticalitiesOpen, setFCtxIsVerticalitiesOpen] = useState(false);
  const [fCtxIsPlatformsOpen, setFCtxIsPlatformsOpen] = useState(false);
  const [fCtxIsTargetOpen, setFCtxIsTargetOpen] = useState(false);
  const [fCtxName, setFCtxName] = useState("");
  const [fCTxVerticalities, setFCtxVerticalities] = useState({});
  const [fCTxPlatforms, setFCtxPlatforms] = useState({});
  const [fCtxTarget, setFCtxTarget] = useState({});

  const { user, setUser, token } = useContext(MainContext);

  const getUserHook = useUser(token, subjects.user);

  useEffect(() => {
    if (token) {
      getUserHook.CTAs.getUserByToken();
    }
  }, []);

  useEffect(() => {
    /*  console.log(getUserHook.ApiB.response, getUserHook.ApiB.result); */
    if (getUserHook.ApiB.result?.status === possible_statuses.token.expired) {
      deleteCookie(cookieName.token);
    } else if (getUserHook.ApiB.response?.ok) {
      setUser(getUserHook.ApiB.result.user);
    } else if (getUserHook.ApiB.response?.ok == false) {
      window.location.href = window.location.origin + "/";
    }
  }, [getUserHook.ApiB.response]);

  useEffect(() => {
    if (token) {
    } else if (
      window.location.pathname === paths.pages.login ||
      window.location.pathname === paths.pages.reset_password ||
      window.location.pathname === paths.pages.recovery_password ||
      window.location.pathname === paths.pages.verify ||
      window.location.pathname.includes("/share")
    ) { 
    } else {
      window.location.href = window.location.origin + "/";
    }
  });

  return (
    <div>
      <Router>
        <Routes>
          <Route path={`/`} element={<LoginPage props={props} />} />
          <Route
            path={paths.pages.first_access}
            element={<FirstAccessPage props={props} />}
          />
          <Route
            path={paths.pages.recovery_password}
            element={<RecoveryPasswordPage props={props} />}
          />
          <Route
            path={paths.pages.reset_password}
            element={<ResetPasswordPage props={props} />}
          />
          <Route
            path={paths.pages.verify}
            element={<VerifyPage props={props} />}
          />

          {token && (
            <Route
              path={paths.pages.overview}
              element={<OverviewPage props={props} />}
            />
          )}

          {token && (
            <Route
              path={paths.pages.influencers}
              element={
                <FilterContext.Provider
                  value={{
                    isVerticalitiesOpen: fCtxIsVerticalitiesOpen,
                    isPlatformsOpen: fCtxIsPlatformsOpen,
                    isTargetOpen: fCtxIsTargetOpen,
                    setIsVerticalitiesOpen: setFCtxIsVerticalitiesOpen,
                    setIsPlatformsOpen: setFCtxIsPlatformsOpen,
                    setIsTargetOpen: setFCtxIsTargetOpen,
                    name: fCtxName,
                    setName: setFCtxName,
                    verticalities: fCTxVerticalities,
                    setVerticalities: setFCtxVerticalities,
                    platforms: fCTxPlatforms,
                    setPlatforms: setFCtxPlatforms,
                    target: fCtxTarget,
                    setTarget: setFCtxTarget,
                  }}
                >
                  <InfluencerPage props={props} />{" "}
                </FilterContext.Provider>
              }
            />
          )}

          {token && (
            <Route
              path={paths.pages.influencer_detail}
              element={<InfluencerDetailPage props={props} />}
            />
          )}

          {token && (
            <Route
              path={paths.pages.influencer_edit}
              element={<InfluencerEditPage props={props} />}
            />
          )}

          {token && (
            <Route
              path={paths.pages.offers}
              element={<OffersPage props={props} />}
            />
          )}

          {token && (
            <Route
              path={paths.pages.offer_detail}
              element={<OfferDetailPage props={props} />}
            />
          )}

          <Route
            path={paths.pages.offer_share}
            element={<ShareOfferPage props={props} />}
          />

          {token && (
            <Route
              path={paths.pages.clients}
              element={<ClientsPage props={props} />}
            />
          )}
          {token && (
            <Route
              path={paths.pages.client_detail}
              element={<ClientDetailPage props={props} />}
            />
          )}
          {token && (
            <Route
              path={paths.pages.settings}
              element={<SettingsPage props={props} />}
            />
          )}
          {token && (
            <Route
              path={paths.pages.user_detail}
              element={<UserDetailPage props={props} />}
            />
          )}
        </Routes>
      </Router>
    </div>
  );
};
export default PageRouter;
