import { useState } from "react";
import { apiURL } from "../../../consts/URLs";
import { RESET_PASSWORD } from "../../endpointList";
import { fetchRequest, getOptions } from "../../utils";
import useApiBuilder from "../useApiBuilder";
import { subjects } from "../../../consts/statuses";

const useResetPassword = () => {
  const ApiB = useApiBuilder(subjects.reset, true);

  const resetPassword = (obj: any) => {
    const url = `${apiURL}/${RESET_PASSWORD}`;
    const options = getOptions({ method: "POST", body: obj });
    ApiB.call(url, options);
  };

  return { resetPassword, ApiB };
};
export default useResetPassword;
