import { useEffect, useState } from "react";
import { fetchRequest, getOptions } from "../utils";
import useToggle from "../../hooks/useToggle";
import LogMessage from "../../components/misc/Toast";
import useLogMessage from "../../hooks/useLogMessage";
import { logMsgBgColors } from "../../consts/misc";

const useApiBuilder = (subject?: string, hideOnSuccess?: boolean) => {
  const callToggler = useToggle(false);
  const isToastVisible = callToggler.isToggled;
  const setToastVisibility = callToggler.setIsToggled;
  const [response, setResponse] = useState<any>();
  const [result, setResult] = useState<any>();
  const logMessage = useLogMessage(
    response,
    isToastVisible,
    setToastVisibility,
    subject,
    hideOnSuccess
  );
  const toast = logMessage.toast;
  const write = logMessage.write;

  const call = (url: string, options: Object) => {
    setToastVisibility(true);
    fetchRequest(url, options, setResponse, setResult);
  };

  return { response, result, call, isToastVisible, toast, write };
};
export default useApiBuilder;
