import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cookieName } from "../../consts/cookie";
import useIsMobile from "../../hooks/useIsMobile";
import { PageInterface, Tips } from "../../interfaces";
import { deleteCookie, getCookie, setCookie } from "../../utils/handleCookie";
import close from "../../assets/ico/close.svg";
import { pages } from "../../consts/misc";
import { paths } from "../../consts/URLs";

const OverviewNwes = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const getSection = (array: PageInterface[], sectionName: string) => {
    const result = array.filter((o) => o.name === sectionName)[0];
    return result;
  };

  const [news, setNews] = useState([
    {
      name: "tip_1",
      bg_imageSrc: paths.news_images.influencers,
      description: "It all starts with the creations of the influencer.",
      cta: {
        text: "Go to Influencers",
        section_link: getSection(pages, "influencers").pathName!,
      },
      title: "Start by entering the influencers",
      visible: getCookie(cookieName.tip_1),
    },
    {
      name: "tip_2",
      bg_imageSrc: paths.news_images.clients,
      description:
        "Create and manage your clients. Keep under control the results of their offers",
      cta: {
        text: "Go to Clients",
        section_link: getSection(pages, "clients").pathName!,
      },
      title: "Create the client",
      visible: getCookie(cookieName.tip_2),
    },
    {
      name: "tip_3",
      bg_imageSrc: paths.news_images.offers,
      description: "Create an offer and update it's status",
      cta: {
        text: "Go to Offers",
        section_link: getSection(pages, "offers").pathName!,
      },
      title: "keep in view the offers",
      visible: getCookie(cookieName.tip_3),
    },
  ]);
  const [indexSelected, setIndexSelected] = useState(0);
  const [refreshNews, setRefreshNews] = useState(false);

  useEffect(() => {
    if (refreshNews === true) {
      setRefreshNews(false);
    }
  }, [refreshNews]);

  useEffect(() => {
    const showedTips = news.filter((tip) => tip.visible != "false");
    setNews(showedTips);
  }, []);

  const removeNews = () => {
    const tempNews: Tips[] = [];
    for (let i = 0; i < news.length; i++) {
      const tip_name = news[indexSelected].name;
      setCookie(tip_name, "false");
      if (news[i] === news[indexSelected]) {
      } else {
        tempNews.push(news[i]);
      }
    }
    setNews(tempNews);
    setIndexSelected(0);
    if (news.length <= 1) {
      props.toggler.toggle();
    }
    setRefreshNews(true);
  };

  const renderPoints = news.map((news: Tips, key: number) => {
    return (
      <div
        className="news-point"
        key={key}
        onClick={() => setIndexSelected(key)}
      />
    );
  });

  const newsOnDisplay = (
    <div
      className={`news-panel-main ${isMobile && "n-p-m-Mob"}`}
      style={{
        backgroundImage: `url(${news[indexSelected]?.bg_imageSrc})`,
      }}
    >
      <div className={`news-panel-content ${isMobile && "n-p-c-Mob"}`}>
        <div
          className="Oval"
          style={{
            boxShadow: "none",
            margin: "18px 18px auto auto",
            cursor: "pointer",
          }}
          onClick={removeNews}
        >
          <img src={close} className="icoclose" />
        </div>
        <p className="news-panel-title highlighted">
          {news[indexSelected]?.title}
        </p>
        <p className="news-panel-description highlighted">
          {news[indexSelected]?.description}
        </p>
        <button
          className="news-panel-button"
          onClick={() => navigate(`${news[indexSelected]?.cta.section_link}`)}
        >
          <p className="news-panel-buttonText">
            {news[indexSelected]?.cta.text}
          </p>
        </button>
        <div
          className="news-points-wrapper"
          style={{ marginTop: isMobile ? "5px" : "" }}
        >
          {renderPoints}
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ width: isMobile ? "345px" : "80%" }}>
      {!refreshNews && (
        <div
          className={`overview-news-panels-box ${isMobile && "o-n-p-b-Mob"}`}
        >
          <div className={`news-panel-second ${isMobile && "n-p-s-Mob"}`}>
            <div
              className={`news-panel-third ${isMobile && "n-p-t-Mob"}`}
            ></div>
          </div>
          {newsOnDisplay}
        </div>
      )}
    </div>
  );
};
export default OverviewNwes;
