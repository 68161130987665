import { useContext } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import MainContext from "../../context/MainContext";
import { formatDateGGMonthYYY } from "../../utils/reformatDate";
const OfferCreateSpecs = (props: any) => {
  const { isMobile } = useIsMobile();
  const { shared } = props;

  const { offer } = useContext(MainContext);

  return (
    <div
      className={`offer-create-specs shadow ${isMobile && "o-c-s-Mob"}`}
      style={{ width: `${props.width}px` }}
    >
      <div className="offer-create-specs-positioner">
        <p
          className="offer-create-specs-title"
          style={{
            width: isMobile ? "305px" : "",
            fontSize: isMobile ? "32px" : "",
          }}
        >
          {shared ? props.offer?.offer_name : offer?.offer_name}
        </p>
        <p
          className="offer-create-specs-agency"
          style={{ margin: isMobile ? "13px auto 23px" : "" }}
        >
          {shared ? props.offer?.client.brand_name : offer?.client.brand_name}
        </p>
        <div className="offer-create-specs-date-place-positioner">
          <div className="offer-create-specs-date-place-box">
            <p className="offer-create-specs-placeholder">Date</p>
            <p className="offer-create-specs-value">
              {formatDateGGMonthYYY(
                shared ? props.offer?.issue_date : offer?.issue_date!
              )}
            </p>
          </div>
          <div className="offer-create-specs-date-place-box">
            <p className="offer-create-specs-placeholder">Location</p>
            <p className="offer-create-specs-value">
              {shared ? props.offer?.location : offer?.location}
            </p>
          </div>
        </div>
        <div className="offer-create-specs-description-box">
          <p className="offer-create-specs-placeholder">Description</p>
          <p className="offer-create-specs-value">
            {shared ? props.offer?.short_description : offer?.short_description}
          </p>
        </div>
      </div>
    </div>
  );
};
export default OfferCreateSpecs;
