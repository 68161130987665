import { Input } from "../interfaces";
import { validateEmail } from "../utils/validations";
import facebook_icon from "../assets/illustration/facebook.svg";
import instagram_icon from "../assets/illustration/instagram.svg";
import tiktok_icon from "../assets/illustration/tiktok.svg";
import twitch_icon from "../assets/illustration/twitch.svg";
import youtube_icon from "../assets/illustration/youtube.svg";
import linkedin_icon from "../assets/illustration/linkedin.svg";
import twitter_icon from "../assets/illustration/twitter.svg";

export const authInputs: Input[] = [
  {
    name: "email",
    type: "text",
    title: "E-mail",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "password",
    type: "password",
    title: "Password",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "confirmed_password",
    type: "password",
    title: "Repeat Password",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "new_password",
    type: "password",
    title: "New password",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "confirmed_new_password",
    type: "password",
    title: "Repeat new password",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
];

export const extraFilterInputs: Input[] = [
  {
    name: "target",
    title: "Target",
    fields: [
      {
        name: "gen_z",
        type: "checkbox",
        title: "Gen Z (18 - 24)",
      },
      {
        name: "millennials",
        type: "checkbox",
        title: "Millennials (24 - 35)",
      },
    ],
  },
];

export const editInputs: any[] = [
  {
    name: "bio",
    title: "Personal infromations",
    fields: [
      {
        name: "name",
        type: "text",
        title: "Name",
        placeholder: "Ex. Karol",
        constraints: {
          required: true,
          validate: (value: string) => value.trim() !== "",
        },
      },
      {
        name: "surname",
        type: "text",
        title: "Surname",
        placeholder: "Ex. Lins",
        constraints: {
          required: true,
          validate: (value: string) => value.trim() !== "",
        },
      },
      {
        name: "birth_date",
        type: "Date",
        title: "Birthday",
        constraints: {
          required: true,
          validate: (value: string) => value.trim() !== "",
        },
      },
      {
        name: "city",
        type: "text",
        title: "City",
        placeholder: "Ex. Italy",
        constraints: {
          required: true,
          validate: (value: string) => value.trim() !== "",
        },
      },
    ],
  },
  {
    name: "contacts",
    title: "Contacts",
    fields: [
      {
        name: "email",
        type: "text",
        title: "E-mail",
        placeholder: "Ex. karol.linz@gmail.com",
        constraints: {
          /* 
          required: true, */
          /* validate: (value: string) => value.trim() !== "" */
          /* &&
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value), */
        },
      },
    ],
  },
  {
    name: "social_info",
    title: "Socials",
    fields: [
      {
        name: "instagram",
        type: "text",
        title: "Instagram",
        placeholder: "Ex.  https://www.instagram.com/XXX",
        iconSrc: instagram_icon,
      },
      {
        name: "facebook",
        type: "text",
        title: "Facebook",
        placeholder: "Ex.  https://www.facebook.com/XXX",
        iconSrc: facebook_icon,
      },
      {
        name: "tiktok",
        type: "text",
        title: "Tiktok",
        placeholder: "Ex.  https://www.tiktok.com/XXX",
        iconSrc: tiktok_icon,
      },
      {
        name: "twitch",
        type: "text",
        title: "Twitch",
        placeholder: "Ex.  https://www.twitch.com/XXX",
        iconSrc: twitch_icon,
      },
      {
        name: "youtube",
        type: "text",
        title: "Youtube",
        placeholder: "Ex.  https://www.youtube.com/XXX",
        iconSrc: youtube_icon,
      },
      {
        name: "linkedin",
        type: "text",
        title: "Linkedin",
        placeholder: "Ex.  https://www.linkedin.com/XXX",
        iconSrc: linkedin_icon,
      },
      {
        name: "twitter",
        type: "text",
        title: "Twitter",
        placeholder: "Ex.  https://www.twitter.com/XXX",
        iconSrc: twitter_icon,
      },
    ],
  },
  {
    name: "verticalities",
    title: "Verticalities",
    fields: [
      {
        name: "fashion",
        type: "checkbox",
        title: "Fashion",
      },
      {
        name: "beauty",
        type: "checkbox",
        title: "Beauty",
      },
      {
        name: "sport",
        type: "checkbox",
        title: "Sport",
      },
      {
        name: "celebrities",
        type: "checkbox",
        title: "Celebrities",
      },
      {
        name: "vegan",
        type: "checkbox",
        title: "Vegan",
      },
      {
        name: "green",
        type: "checkbox",
        title: "Green",
      },
      {
        name: "food",
        type: "checkbox",
        title: "Food",
      },
      {
        name: "lifestyle",
        type: "checkbox",
        title: "Lifestyle",
      },
      {
        name: "educational",
        type: "checkbox",
        title: "Educational",
      },
      {
        name: "entertainement",
        type: "checkbox",
        title: "Entertainement",
      },
      {
        name: "radio",
        type: "checkbox",
        title: "Radio",
      },
      {
        name: "kids",
        type: "checkbox",
        title: "Kids",
      },
      {
        name: "stardust_house",
        type: "checkbox",
        title: "Stardust House",
      },
    ],
  },
  {
    name: "platforms",
    title: "Platforms",
    fields: [
      {
        name: "facebook",
        type: "checkbox",
        title: "Facebook",
        iconSrc: facebook_icon,
      },
      {
        name: "instagram",
        type: "checkbox",
        title: "Instagram",
        iconSrc: instagram_icon,
      },
      {
        name: "tiktok",
        type: "checkbox",
        title: "Tiktok",
        iconSrc: tiktok_icon,
      },
      {
        name: "twitch",
        type: "checkbox",
        title: "Twitch",
        iconSrc: twitch_icon,
      },
      {
        name: "youtube",
        type: "checkbox",
        title: "Youtube",
        iconSrc: youtube_icon,
      },
      {
        name: "linkedin",
        type: "checkbox",
        title: "Linkedin",
        iconSrc: linkedin_icon,
      },
      {
        name: "twitter",
        type: "checkbox",
        title: "Twitter",
        iconSrc: youtube_icon,
      },
    ],
  },
  {
    name: "files",
    title: "Files",
    fields: [
      {
        name: "mediaKit",
        type: "file",
        accept: "application/pdf,application/vnd.ms-excel",
        /* constraints: {
          required: true,
          validate: (value: string) => value.trim() !== "",
        }, */
      },
    ],
  },
];

export const clientInputs: Input[] = [
  {
    name: "brand_name",
    type: "text",
    title: "Brand’s name",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "final_client",
    type: "text",
    title: "Final client",
  },
  {
    name: "referent_name",
    type: "text",
    title: "Referent's name",
    /*  constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    }, */
  },
  {
    name: "email",
    type: "text",
    title: "E-mail",
    constraints: {
      required: true,
      validate: (value: string) =>
        value.trim() !== "" &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value),
    },
  },
];

export const settingsInputs: Input[] = [
  {
    name: "name",
    type: "text",
    title: "Name",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "surname",
    type: "text",
    title: "Surname",
    constraints: {
      required: true,
      validate: (value: string) => value.trim() !== "",
    },
  },
  {
    name: "email",
    type: "text",
    title: "E-mail", 
  },
  {
    name: "password",
    type: "password",
    title: "Password",
  },
  {
    name: "password_confirmation",
    type: "password",
    title: "Confirm Password",
  },
];
