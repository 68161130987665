import { useEffect, useState } from "react";
import Toast from "../components/misc/Toast";
import { logMsgBgColors } from "../consts/misc";
import { getApiMessage, waitTo } from "../utils/others";
import { LogMessage } from "../interfaces";

const useLogMessage = (
  res: Response,
  isToastVisible: boolean,
  setToastVisibility: Function,
  subject?: string,
  hideOnSuccess?: boolean
) => {
  const messageTimeOnDisplay = 3000; //millisecnds
  const [time, setTime] = useState<number | null>(null);
  const [message, setMessage] = useState<LogMessage>({
    text: { subject: "", other_text: "", complement: "" },
    bgColor: "",
  });

  const [islogoVisible, setIsLogoVisible] = useState(false);

  useEffect(() => {
    let mex: LogMessage;
    if (subject) {
      mex = getApiMessage(subject);
    } else {
      mex = { text: { subject: "", other_text: "", complement: "" } };
    }
    if (!res) {
      setMessage({
        text: {
          subject: "Loading",
          other_text: " ...",
          complement: "",
        },
        bgColor: logMsgBgColors.loading,
      });
      setIsLogoVisible(true);
    } else if (res && res.ok && !hideOnSuccess) {
      setMessage(mex);
      setIsLogoVisible(false);
    } else if (res && res.ok && hideOnSuccess) {
      setTime(1);
      setIsLogoVisible(false);
    } else if (res && !res.ok) {
      setMessage({
        text: {
          subject: res.status ? res.status.toString() : "",
          other_text: " : " + res.statusText,
          complement: "",
        },
        bgColor: logMsgBgColors.error,
      });
      setIsLogoVisible(false);
    }
  }, [res]);

  const write = (msg: LogMessage) => {
    //timeOnDisplay in milliseconds
    setToastVisibility(true);
    setMessage(msg);
  };

  useEffect(() => {
    if (message) {
      setTime(messageTimeOnDisplay);
    }
  }, [message]);

  const resetMessage = () => {
    setMessage({
      text: { subject: "", other_text: "", complement: "" },
      bgColor: "",
    });
    setToastVisibility(false);
  };

  useEffect(() => {
    if (isToastVisible) {
      if (time) {
        waitTo(time, () => resetMessage());
      }
    }
  }, [isToastVisible, time]);

  const toast = (
    <Toast
      text={{
        subject: message?.text.subject ? message.text.subject : "",
        other_text: message?.text.other_text ? message.text.other_text : "",
        complement: message?.text.complement ? message.text.complement : "",
      }}
      islogoVisible={islogoVisible}
      bgColor={message?.bgColor}
    />
  );

  return { message, write, toast };
};
export default useLogMessage;
