import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useOffer from "../../../../api/hooks/useOffers";
import useZip from "../../../../api/hooks/useZip";
import { getOptions } from "../../../../api/utils";
import Footer from "../../../../components/Footer";
import CustomInput from "../../../../components/inputs/CustomInput";
import LogMessage from "../../../../components/misc/Toast";
/* import ModelCard from "../../../components/models/ModelCard"; */
import OfferCreateSpecs from "../../../../components/offer/OfferCreateSpecs";
import { logMsgBgColors } from "../../../../consts/misc";
import useIsMobile from "../../../../hooks/useIsMobile";
import useLogMessage from "../../../../hooks/useLogMessage";
import useToggle from "../../../../hooks/useToggle";
import { Offer, Shared_Influencer_info } from "../../../../interfaces";
/* import { modelsRemappingFromSharedResponse } from " ../../../utils/modelRemapping"; */
import { formatDateGGMMYYYY } from "../../../../utils/reformatDate";
import InfluencerCard from "../../../../components/influencers/InfluencerCard";
import logo_main from "../../../../assets/logo_main.png";
import composite_icon from "../../../../assets/ico/composite.svg";
import lookbook_icon from "../../../../assets/ico/lookbook.svg";
import wallet_icon from "../../../../assets/ico/wallet.svg";
import {
  influencerRemappedFromShare,
  offerRemappingFromResult,
} from "../../../../utils/remapping";
import { possible_statuses } from "../../../../consts/statuses";

const ShareOfferPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const passwordForm = useForm();
  const url = new URL(window.location.href);
  const getSharedOfferHook = useOffer();
  const [dataFetched, setDataFetched] = useState<Offer | null>(null);
  const [offer, setOffer] = useState<Offer | null>(null);

  const zipHook = useZip();

  const [userSharing, setUserSharing] = useState({
    name: "",
    surname: "",
  });
  const [isPasswordNeeded, setIsPasswordNeeded] = useState(false);

  const [isDownloadAllMediaKitVisible, setIsDownloadAllMediaKitVisible] =
    useState(false);

  useEffect(() => {
    callGetSharedApi();
  }, []);

  useEffect(() => {
    if (dataFetched) {
      const offerRemapped = offerRemappingFromResult(dataFetched);
      setOffer(offerRemapped);

      if (offerRemapped && offerRemapped.proposed_list) {
        const someMediaKit = offerRemapped.proposed_list.some(
          (influencer) => influencer.talent_mediaKit != null
        );
        setIsDownloadAllMediaKitVisible(someMediaKit);
      }
    }
  }, [dataFetched]);

  const callGetSharedApi = (password?: string) => {
    const tToken = url.searchParams.get("token");
    const s = url.pathname;
    const id = Number.parseInt(s.slice(s.lastIndexOf(`/`) + 1, s.length));

    const finalObj: any = {};

    if (tToken && id) {
      finalObj["token"] = tToken;

      if (password) {
        finalObj["password"] = password;
      }
      getSharedOfferHook.CTAs.getSharedOffer(id, finalObj);
    }
  };

  const onPasswordSubmit = (data: any) => {
    callGetSharedApi(data.password);
  };

  useEffect(() => {
    /*  console.log(
      getSharedOfferHook.ApiB.response,
      getSharedOfferHook.ApiB.result
    ); */
    if (getSharedOfferHook.ApiB.response?.ok) {
      setIsPasswordNeeded(false);
      setDataFetched(getSharedOfferHook.ApiB.result.data);

      const user = {
        name: getSharedOfferHook.ApiB.result.data.user_sharing_name,
        surname: getSharedOfferHook.ApiB.result.data.user_sharing_surname,
      };
      setUserSharing(user);
    } else if (
      getSharedOfferHook.ApiB.response?.ok === false &&
      getSharedOfferHook.ApiB.response?.status === 401
    ) {
      setIsPasswordNeeded(true);
    }
  }, [getSharedOfferHook.ApiB.response]);

  const downloadAll = async (choice: string) => {
    const tToken = url.searchParams.get("token");
    if (tToken) {
      zipHook.CTAs.getSharedZip(tToken);
    }
  };

  useEffect(() => {
    /* console.log(zipHook.RESPONSES.getSharedZipResponse); */
    if (zipHook.RESPONSES.getSharedZipResponse) {
      const url = zipHook.RESPONSES.getSharedZipResponse.url;
      const link = document.createElement("a");
      link.href = url;
      link.click();
      window.open(url, "_blank");
    }
  }, [zipHook.RESPONSES.getSharedZipResponse]);

  const renderModelCards =
    offer &&
    offer?.proposed_list &&
    offer.proposed_list
      .filter((item) => item.status != possible_statuses.influencer.rejected)
      .map((influencer, key) => {
        const influencerRemapped = influencerRemappedFromShare(influencer);
        return (
          <InfluencerCard
            key={key}
            influencer={influencerRemapped}
            readOnly={props.readOnly}
            shareLayout={true}
            isDownloadAllMediaKitVisible={isDownloadAllMediaKitVisible}
          />
        );
      });

  return (
    <div className="access-background" style={{ overflow: "hidden" }}>
      <img
        style={{ marginTop: "45px", marginBottom: "60px" }}
        className="logo-main"
        src={logo_main}
      />

      {/*  */}
      {offer ? (
        <>
          <div className="offer-shared-advertise-box">
            <span className="offer-shared-advertise-text-box">
              <p className="offer-shared-advertise-text">
                <strong className="black">
                  {userSharing.name ? userSharing.name : ""}{" "}
                  {userSharing.surname ? userSharing.surname : ""}
                </strong>{" "}
                has shared this offer with you
              </p>
            </span>
          </div>
          <div
            className="offer-shared-advertise-date-box"
            style={{
              flexDirection: isMobile ? "column" : "row",
              gap: isMobile ? "0" : "",
              marginTop: isMobile ? "15px" : "",
            }}
          >
            {dataFetched?.created_at && (
              <>
                <p className="offer-shared-advertise-text">shared on:</p>
                <p className="offer-shared-advertise-date">
                  {formatDateGGMMYYYY(new Date(dataFetched?.created_at))}
                </p>
              </>
            )}
            {dataFetched?.expiration_date && (
              <>
                {isMobile ? "" : "-"}
                <p className="offer-shared-advertise-text">expire on:</p>
                <p className="offer-shared-advertise-date">
                  {formatDateGGMMYYYY(new Date(dataFetched?.expiration_date))}
                </p>
              </>
            )}
          </div>
          <div>
            <OfferCreateSpecs
              width={isMobile ? 345 : 870}
              offer={offer}
              shared={true}
            />
          </div>

          {offer.proposed_list && offer.proposed_list.length > 0 && (
            <div
              className="offer-shared-models-recap shadow"
              style={{
                width: isMobile ? "346.6px" : "",
                marginTop: isMobile ? "20px" : "",
              }}
            >
              <div
                className="offer-shared-models-recap-header"
                style={{
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? "0" : "",
                }}
              >
                <div className="offer-shared-models-recap-info-box">
                  <p className="offer-shared-models-recap-info-title">
                    Suggested Models{" "}
                    {offer.status === possible_statuses.offer.approved ||
                    offer.status === possible_statuses.offer.substatus.confirmed
                      ? offer.proposed_list?.filter(
                          (item) =>
                            item.status ===
                            possible_statuses.influencer.approved
                        ).length
                      : offer.proposed_list?.filter(
                          (item) =>
                            item.status === possible_statuses.influencer.pending
                        ).length}{" "}
                    for {offer.requested_influencers}
                  </p>
                  <p className="offer-shared-models-recap-info-description">
                    Our selection
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "15px",
                    marginLeft: isMobile ? "-50px" : "",
                  }}
                >
                  {isDownloadAllMediaKitVisible && (
                    <div
                      className="offer-shared-models-recap-cta-box offLightBlue"
                      onClick={() => downloadAll("mediaKit")}
                      style={{ margin: isMobile ? "20px auto" : "" }}
                    >
                      <div className="offer-shared-models-recap-cta-icon">
                        <img
                          className="offMainColor"
                          src={lookbook_icon}
                          alt=""
                        />
                      </div>

                      <p className="offer-shared-models-recap-cta-icon-text">
                        {"Download all MediaKit"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="offer-shared-models-recap-content"
                style={{
                  width: isMobile ? "305px" : "",
                  minHeight: isMobile ? "56px" : "",
                  maxHeight: isMobile ? "fit-content" : "",
                  padding: isMobile ? "" : "",
                  gridTemplateColumns: isMobile ? "1fr" : "",
                  display: isMobile ? "flex" : "grid",
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                {renderModelCards}
              </div>
            </div>
          )}
          {offer.long_description && (
            <div
              className="offer-shared-description-box shadow"
              style={{
                width: isMobile ? "344.9px" : "",
                height: isMobile ? "305.6px" : "",
                marginTop: isMobile ? "20px" : "",
              }}
            >
              <p
                className="offer-shared-description-title"
                style={{ margin: isMobile ? "24px 156px 16px 24px" : "" }}
              >
                Offer description
              </p>
              <div
                className="offer-shared-description-wrapper"
                style={{
                  width: isMobile ? "75%" : "",
                  height: isMobile ? "70%" : "",
                  margin: isMobile ? "19px auto" : "",
                }}
              >
                <p
                  className="offer-shared-description"
                  style={{
                    padding: isMobile ? "12px 0" : "",
                  }}
                >
                  {offer.long_description}
                </p>
              </div>
            </div>
          )}
          {offer.budget && (
            <div
              className="offer-shared-budget-box shadow"
              style={{
                width: isMobile ? "345px" : "",
                flexDirection: isMobile ? "column" : "row",
                height: isMobile ? "146.2px" : "",
                justifyContent: isMobile ? "flex-start" : "space-between",
                marginTop: isMobile ? "20px" : "",
                padding: isMobile ? "" : "25px 0",
              }}
            >
              <p
                className="offer-shared-budget-title"
                style={{ margin: isMobile ? "24px auto 16px 24px" : "" }}
              >
                Budget
              </p>
              <div
                className="offer-shared-budget-wrapper"
                style={{
                  width: isMobile ? "305px" : "",
                  margin: isMobile ? "0" : "",
                }}
              >
                <img
                  className="offer-shared-budget-icon"
                  src={wallet_icon}
                  alt=""
                />
                <p className="offer-shared-budget-value">€{offer.budget}</p>
              </div>
            </div>
          )}
        </>
      ) : isPasswordNeeded ? (
        <div
          className={`access-content-box ${isMobile && "a-c-b-Mob"}`}
          style={{ marginTop: isMobile ? "" : "49px" }}
        >
          <div className="access-content-box-inner-wrapper">
            <form onSubmit={passwordForm.handleSubmit(onPasswordSubmit)}>
              <div className="access-contentBox-header">
                <div className="cornerTitle">
                  {"Private Offer"}
                  <p className="cornerSubTitle">
                    {"Insert the correct password to see this offer"}
                  </p>
                </div>
              </div>
              <div style={{ marginTop: "32px" }}>
                <div style={{ marginTop: "-20px" }}>
                  <CustomInput
                    width={isMobile ? "90%" : "95%"}
                    name="password"
                    type="password"
                    register={passwordForm.register}
                    errors={passwordForm.formState.errors}
                  />
                </div>
              </div>
              <div
                className="contentBox-confirmationBox"
                style={{ justifyContent: "right", marginTop: "50px" }}
              >
                <button className="button1 b-short" type="submit">
                  <p className="button1-text">{"Confirm"}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          marginTop: isMobile ? "101.8px" : "82px",
          marginBottom: isMobile ? "31px" : "60px",
        }}
      >
        <Footer />
      </div>
    </div>
  );
};
export default ShareOfferPage;
