import { useState } from "react";
import { apiURL } from "../../consts/URLs";
import { INFLUENCERS } from "../endpointList";
import { fetchRequest, getOptions } from "../utils";
import useApiBuilder from "./useApiBuilder";
import { subjects } from "../../consts/statuses";

const useInfluencers = (token?: string | null, subject?: string) => {
  const ApiB = useApiBuilder(subject ? subject : subjects.influencer);

  const createInfluencers = (obj: any) => {
    const url = `${apiURL}/${INFLUENCERS}`;
    const options = getOptions({
      method: "POST",
      body: {
        ...obj,
      },
      token: token,
    });
    ApiB.call(url, options);
  };

  const updateInfluencers = (id: number, obj: any) => {
    const url = `${apiURL}/${INFLUENCERS}/${id}`;
    const options = getOptions({
      method: "PUT",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const deleteInfluencers = (id: number) => {
    const url = `${apiURL}/${INFLUENCERS}/${id}`;
    const options = getOptions({ method: "DELETE", token: token });
    ApiB.call(url, options);
  };

  const getInfluencerById = (id: number) => {
    const url = `${apiURL}/${INFLUENCERS}/${id}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const getPaginated = () => {
    const url = `${apiURL}/${INFLUENCERS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const getAll = () => {
    const url = `${apiURL}/${INFLUENCERS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const CTAs = {
    createInfluencers,
    updateInfluencers,
    deleteInfluencers,
    getInfluencerById,
    getPaginated,
    getAll,
  };

  return { CTAs, ApiB };
};
export default useInfluencers;
