import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useOffer from "../../api/hooks/useOffers";
import { logMsgBgColors } from "../../consts/misc";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";
import { Influencer } from "../../interfaces";
/* import { offerRemappingFromResult } from "../../utils/offerRemapping"; */
import inconlyLightMenu_icon from "../../assets/ico/iconly-light-menus.svg";
import inconlyLightPlus_icon from "../../assets/ico/iconly-light-plus.svg";
import close_icon from "../../assets/ico/close.svg";
import high_icon from "../../assets/ico/high.svg";
import measurements_icon from "../../assets/ico/measurements.svg";
import { theme } from "../../consts/palette";
import { paths } from "../../consts/URLs";
import MainContext from "../../context/MainContext";
import { possible_statuses, subjects } from "../../consts/statuses";
import {
  influencerRemappingFromProposedList,
  offerRemappingFromResult,
} from "../../utils/remapping";

const InfluencerCard = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const isIconShowUp = useToggle(false);

  const { updateOfferListHook } = props;

  const { setInfluencer, influencerSelector, token, offer, setOffer } =
    useContext(MainContext);
  const { onInfluencerSelection, influencerList, setInfluencerList } =
    influencerSelector;

  const onSelctorCompnent = props.selectorLayout;

  const isThisModelSelected = influencerList.some(
    (influencer) =>
      influencer.id === props.influencer.id && onInfluencerSelection
  );

  /* ----------------------------------------------- */
  useEffect(() => {
    if (offer && offer.proposed_list && offer.proposed_list?.length > 0) {
      let temp: any[] = [];
      offer.proposed_list.forEach((item) => {
        if (item.status != possible_statuses.influencer.rejected) {
          temp.push({
            id: item.talent_id,
            status: item.status,
          });
        }
      });
      setInfluencerList(temp);
    }
  }, [offer]);

  const handleOnClick = () => {
    if (onSelctorCompnent) {
    } else if (!onSelctorCompnent) {
      setInfluencer(props.influencer);
      navigate(`${paths.pages.influencers}/${props.influencer.id}`);
    }
  };

  const handleOnShare = () => {
    if (props.influencer.mediaKit && props.isDownloadAllMediaKitVisible) {
      window.open(props.influencer.mediaKit, "blank");
    }
  };

  const handleSelect = () => {
    if (onInfluencerSelection && !isThisModelSelected) {
      props.setModelSelectQuantity((prevCounter: number) => prevCounter + 1);
      addModel();
    }
    if (onInfluencerSelection && isThisModelSelected) {
      props.setModelSelectQuantity((prevCounter: number) => prevCounter - 1);
      removeModel();
    }
  };

  const addModel = () => {
    setInfluencerList((prevList: Influencer[]) => {
      const finalList: Influencer[] = [];
      prevList.forEach((influencer) => {
        finalList.push(influencer);
      });
      finalList.push(props.influencer);
      return finalList;
    });
  };

  const removeModel = () => {
    setInfluencerList((prevList: Influencer[]) => {
      const finalList: Influencer[] = [];
      prevList.forEach((influencer) => {
        if (influencer.id === props.influencer.id) {
        } else {
          finalList.push(influencer);
        }
      });
      return finalList;
    });
  };

  /* ----------------------------------------------------------------------------------- */
  /* This is used in selector component on Mobile view to remove the model from the offer */

  const removeInfluencerWithCTA = () => {
    const finalList: Influencer[] = [];
    influencerList.forEach((influencer) => {
      if (influencer.id === props.influencer.id && offer?.id) {
        updateOfferListHook.CTAs.updateOfferInfluencers(offer.id, {
          proposed_talents: [
            {
              talent_id: influencer.id,
              status: possible_statuses.influencer.rejected,
            },
          ],
        });
      } else {
        finalList.push(influencer);
      }
    });
    setInfluencerList(finalList);
  };
  /* ----------------------------------------------------------------------------------- */

  useEffect(() => {
    /* console.log(
      updateOfferListHook.ApiB.response,
      updateOfferListHook.ApiB.result
    ); */
    if (updateOfferListHook && updateOfferListHook.ApiB.response?.ok) {
      const offerRemapped = offerRemappingFromResult(
        updateOfferListHook.ApiB.result.talent
      );
      setOffer(offerRemapped);
    }
  }, [updateOfferListHook && updateOfferListHook.ApiB.response]);

  return (
    <div
      className="model-card-preview shadow"
      style={{
        width: isMobile ? "100%" : "",
        height: isMobile ? "440px" : "",
        backgroundColor:
          onInfluencerSelection && isThisModelSelected ? theme.main_color : "",
        cursor: props.isDownloadAllMediaKitVisible
          ? "pointer"
          : props.shareLayout
          ? "default"
          : "",
      }}
      onClick={() =>
        onInfluencerSelection
          ? handleSelect()
          : props.shareLayout
          ? handleOnShare()
          : handleOnClick()
      }
      onMouseEnter={() => isIconShowUp.setIsToggled(true)}
      onMouseLeave={() => isIconShowUp.setIsToggled(false)}
    >
      {!onSelctorCompnent &&
        onInfluencerSelection &&
        (isMobile ? true : isIconShowUp.isToggled) && (
          <div
            className="model-selector-icon-wrapper"
            style={{
              backgroundColor:
                onInfluencerSelection && isThisModelSelected
                  ? theme.main_color
                  : "#fff",
              top: isMobile ? "34px" : "",
              right: isMobile ? "34px" : "",
            }}
          >
            <img
              className={`model-selector-icon ${
                onInfluencerSelection && isThisModelSelected ? "white" : "black"
              }`}
              src={
                onInfluencerSelection && isThisModelSelected
                  ? inconlyLightMenu_icon
                  : inconlyLightPlus_icon
              }
              alt=""
            />
          </div>
        )}
      {onSelctorCompnent && isIconShowUp.isToggled && (
        <div
          className="model-selector-icon-wrapper"
          style={{
            backgroundColor:
              onInfluencerSelection && isThisModelSelected
                ? theme.main_color
                : "#fff",
            top: isMobile ? "34px" : "",
            right: isMobile ? "34px" : "",
          }}
          onClick={removeInfluencerWithCTA}
        >
          <img
            className={`model-selector-icon ${
              onInfluencerSelection && isThisModelSelected ? "white" : "black"
            }`}
            src={close_icon}
            alt=""
          />
        </div>
      )}
      <div
        className="model-preview-image"
        style={{
          margin: isMobile ? "24px auto 0" : "",
          backgroundImage: `url(${props.influencer.profileImage})`,
        }}
      />
      <div className="model-card-info">
        <p
          className="model-card-name"
          style={{
            color: onInfluencerSelection && isThisModelSelected ? "#fff" : "",
          }}
        >
          {props.influencer?.name} {props.influencer?.surname}
        </p>

        {/* <div className="model-card-specs-box">
            <span className="model-card-spec">
            <img
              className={`model-card-spec-icon ${
                onInfluencerSelection && isThisModelSelected ? "#fff" : "grey"
              }`}
              src={high_icon}
              alt=""
            />
            <p
              className="model-card-spec-text"
              style={{
                color: onInfluencerSelection && isThisModelSelected ? "#fff" : "",
              }}
            ></p>
          </span> 
           <span className="model-card-spec">
            <img
                className={`model-card-spec-icon ${
                onInfluencerSelection && isThisModelSelected ? "white" : "grey"
              }`}  
              src={measurements_icon}
              alt=""
            />
            <p
              className="model-card-spec-text"
               style={{
                color: onInfluencerSelection && isThisModelSelected ? "white" : "",
              }}  
            > 
            </p>
          </span>  
        </div> */}
      </div>
    </div>
  );
};
export default InfluencerCard;
