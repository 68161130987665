import { useContext } from "react";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { PageInterface } from "../../interfaces";
import "../../styles/Content.css";
import { getPage } from "../../utils/getMethods";
import SideBarItem from "./SideBarItem";
import { pages } from "../../consts/misc";
import { toAssets } from "../../consts/URLs";
import logo_main from "../../assets/logo_main.png";
import MainContext from "../../context/MainContext";
import SideBarUserSection from "./SideBarUserSection";

const SideBar = (props: any) => {
  const { isMobile } = useIsMobile();
  const { user } = useContext(MainContext);
  const { isToggledSecondary } = props.toggler;

  const agencySection = getPage(pages, ["overview", "influencers", "offers"]);

  const renderAgencyItems = agencySection.map(
    (page: PageInterface, key: number) => {
      return <SideBarItem key={key} {...page} />;
    }
  );

  const generalSection = getPage(pages, ["clients", "settings"]);
  const renderGeneralItems = generalSection.map(
    (page: PageInterface, key: number) => {
      return <SideBarItem key={key} {...page} />;
    }
  );

  return (
    <div
      className={`${
        isMobile ? (isToggledSecondary ? "slide-right" : "slide-left") : ""
      } sidebar `}
    >
      <img className="Logo_2-POS4x sidebar-logo" src={logo_main} />
      <div className="sidebar-section">
        <p className="sidebar-sectionName">Agency</p>
        {renderAgencyItems}
      </div>
      <div className="divider-box">
        <div className="divider" />
      </div>
      <div className="sidebar-section">
        <p className="sidebar-sectionName">General</p>
        {renderGeneralItems}
      </div>
      <SideBarUserSection user={user} />
    </div>
  );
};
export default SideBar;
