import { useEffect, useState } from "react";
import { isConstructorDeclaration } from "typescript";
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";

const InfluencerConfirmTag = (props: any) => {
  const { isMobile } = useIsMobile();

  const selectedInfluencer = useToggle(false);
  const { isToggled } = selectedInfluencer;

  const [indexInfluencerChosen, setIndexInfluencerChosen] = useState<number>();

  useEffect(() => {
    let index = 0;
    index = props.chosenList.indexOf(props.influencer.id);
    setIndexInfluencerChosen(index + 1);
  });

  const handleSelection = () => {
    selectedInfluencer.toggle();
  };

  useEffect(() => {
    if (selectedInfluencer.isToggled) {
      props.addModel(props.influencer.id);
    } else {
      props.removeModel(props.influencer.id);
    }
  }, [isToggled]);

  return (
    <div
      className="models-confirm-model-tag scrollable-element"
      onClick={handleSelection}
      style={{
        width: isMobile ? "305px" : "",
        backgroundColor: selectedInfluencer.isToggled ? "#e0fae3" : "#f7f6f6",
      }}
    >
      <div className="models-confirm-model-preview">
        <div
          className="models-confirm-model-preview-image"
          style={{
            backgroundImage: `url(${props.influencer.profileImage})`,
          }}
        />
        <p className="models-confirm-model-preview-fullname">
          {props.influencer.name + " " + props.influencer.surname}
        </p>
      </div>
      {selectedInfluencer.isToggled && (
        <div className="models-confirm-model-index-bg">
          <p className="models-confirm-model-index-number">
            {indexInfluencerChosen}
          </p>
        </div>
      )}
    </div>
  );
};
export default InfluencerConfirmTag;
