import { useState } from "react";
import { apiURL } from "../../../consts/URLs";
import { LOGOUT } from "../../endpointList";
import { fetchRequest, getOptions } from "../../utils";
import useApiBuilder from "../useApiBuilder";
import { subjects } from "../../../consts/statuses";

const useLogout = (token: string | null) => {
  const ApiB = useApiBuilder(subjects.logout);

  const logout = () => {
    const url = `${apiURL}/${LOGOUT}`;
    const options = getOptions({
      method: "POST",
      token: token,
    });
    ApiB.call(url, options);
  };

  return { logout, ApiB };
};
export default useLogout;
