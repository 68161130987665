import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { OFFERS, SHARE } from "../../../../api/endpointList";
import useOffer from "../../../../api/hooks/useOffers";
import delete_icon from "../../../../assets/ico/delete.svg";
import NavBar from "../../../../components/misc/NavBar";
import SideBar from "../../../../components/misc/SideBar";
import DeleteModal from "../../../../components/modals/DeleteModal";
import OfferCloseModal from "../../../../components/modals/OfferCloseModal";
import OfferCreateModal from "../../../../components/modals/OfferCreateModal";
import OfferShareModal from "../../../../components/modals/OfferShareModal";
import OfferCreateHeader from "../../../../components/offer/OfferCreateHeader";
import OfferCreateSpecs from "../../../../components/offer/OfferCreateSpecs";
import OfferDescriptionInput from "../../../../components/offer/OfferDescriptionInput";
import OfferDetailsResume from "../../../../components/offer/OfferDetailsResume";
import InfluencerSelector from "../../../../components/selector/InfluencerSelector";
import { appHostURL, paths } from "../../../../consts/URLs";
import { possible_statuses, subjects } from "../../../../consts/statuses";
import MainContext from "../../../../context/MainContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import useToggle from "../../../../hooks/useToggle";
import { backToTopOfPage } from "../../../../utils/others";
import { offerRemappingFromResult } from "../../../../utils/remapping";

const OfferDetailsPage = (props: any) => {
  const { isMobile } = useIsMobile();

  const navigate = useNavigate();
  const menuToggler = useToggle(false);

  const { offer, setOffer, influencerSelector, token } =
    useContext(MainContext);
  const updateOfferHook = useOffer(token, subjects.offer_update);
  const updateOfferInfluencersHook = useOffer(token, subjects.offer_update);
  const createOfferInfluencersHook = useOffer(token, subjects.offer_update);
  const deleteOfferHook = useOffer(token, subjects.offer_delete);
  const shareOfferHook = useOffer(token);

  const readOnly = offer?.status != possible_statuses.offer.open;

  /* Modals */
  const sharedModalToggler = useToggle(false);
  const closeOfferModalToggler = useToggle(false);
  const deleteOfferModalToggler = useToggle(false);
  const editOfferModalToggler = useToggle(false);

  const anyModalOpen =
    sharedModalToggler.isToggled ||
    closeOfferModalToggler.isToggled ||
    deleteOfferModalToggler.isToggled ||
    editOfferModalToggler.isToggled;

  /* Forms */
  const editOfferHeadersForm = useForm();
  const editOfferDetailsForm = useForm();
  const shareOfferForm = useForm();
  const deleteOfferForm = useForm();

  const [link, setLink] = useState("");

  useEffect(() => {
    if (
      sharedModalToggler.isToggled ||
      closeOfferModalToggler.isToggled ||
      deleteOfferModalToggler.isToggled ||
      editOfferModalToggler.isToggled
    ) {
      backToTopOfPage();
    }
  }, [
    sharedModalToggler,
    closeOfferModalToggler,
    deleteOfferModalToggler,
    editOfferModalToggler,
  ]);

  useEffect(() => {
    if (
      offer === null &&
      window.location.pathname.includes(paths.pages.offers)
    ) {
      navigate(paths.pages.offers);
    }
  }, []);

  const saveTempOffer = (data: any) => {
    setOffer((prevOffer) => ({ ...prevOffer, ...data }));
  };

  /* --------------Share Offer Handler----------------------- */
  const onShareOffer = (data: any) => {
    let finalObj: any = {};
    if (data.mediakit_shared) {
      finalObj["mediaKit_shared"] = 1;
    } else {
      finalObj["mediaKit_shared"] = 0;
    }
    if (data.budget_shared) {
      finalObj["budget_shared"] = 1;
    } else {
      finalObj["budget_shared"] = 0;
    }

    if (data.password) {
      finalObj["password"] = data.password;
    }
    if (data.expiration_date) {
      finalObj["expiration_date"] = data.expiration_date;
    }

    if (offer && offer.id) {
      /* console.log(offer.id, JSON.stringify(finalObj)); */
      shareOfferHook.CTAs.createSharedOffer(offer?.id, finalObj);
    }
  };

  useEffect(() => {
    /*  console.log(shareOfferHook.ApiB.response, shareOfferHook.ApiB.result); */
    if (shareOfferHook.ApiB.response?.ok) {
      const linkFromResult = new URL(shareOfferHook.ApiB.result.link);

      const param_to_search = "token";
      const token = linkFromResult.searchParams.get(param_to_search);
      let finalLink =
        appHostURL +
        `/${OFFERS}/${SHARE}/` +
        offer?.id +
        `?${param_to_search}=` +
        token;
      setLink(finalLink);
    }
  }, [shareOfferHook.ApiB.response]);
  /* ---------------------------------------------------------- */

  /* --------------Update Offer Handler----------------------- */
  const [client_id, setClient_id] = useState(offer?.client.id);

  /*-------------ONLY FOR CLIENT ID------------ */
  const handleOnFormChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "client_id") {
      setClient_id(value);
    }
  };
  /* ------------------------------------------- */
  const onEditSpecsOffer = (data: any) => {
    if (offer) {
      const id = offer?.id;
      const finalObj = {
        offer_name: data.offer_name,
        client_id: client_id,
        issue_date: data.issue_date,
        location: data.location,
        short_description: data.short_description,
      };
      /* console.log(id, finalObj); */
      updateOfferHook.CTAs.updateOffer(id!, finalObj);
    }
  };

  const onEditOfferDetails = (data: any) => {
    const id = offer?.id;
    let finalObj = data;
    /* console.log(finalObj); */
    updateOfferHook.CTAs.updateOffer(id!, finalObj);
  };

  useEffect(() => {
    /* console.log(updateOfferHook.ApiB.response, updateOfferHook.ApiB.result); */
    if (updateOfferHook.ApiB.response?.ok) {
      const remappedObj = offerRemappingFromResult(
        updateOfferHook.ApiB.result.offer
      );
      setOffer(remappedObj);
      editOfferModalToggler.setIsToggled(false);
    }
  }, [updateOfferHook.ApiB.response]);
  /* ---------------------------------------------------------- */

  /* --------------Delete Offer Handler----------------------- */
  const onDeleteOffer = (data: any) => {
    if (offer && offer.id) {
      deleteOfferHook.CTAs.deleteOffer(offer.id);
    }
  };
  useEffect(() => {
    /* console.log(deleteOfferHook.ApiB.response, deleteOfferHook.ApiB.result); */
    if (deleteOfferHook.ApiB.response?.ok) {
      deleteOfferModalToggler.toggle();
      setTimeout(() => {
        navigate(paths.pages.offers);
      }, 3000);
    }
  }, [deleteOfferHook.ApiB.response]);
  /* -------------------------------------------------------------- */

  return (
    <div className="page-wrapper">
      {updateOfferHook.ApiB.isToastVisible && updateOfferHook.ApiB.toast}
      {deleteOfferHook.ApiB.isToastVisible && deleteOfferHook.ApiB.toast}
      {updateOfferInfluencersHook.ApiB.isToastVisible &&
        updateOfferInfluencersHook.ApiB.toast}
      {createOfferInfluencersHook.ApiB.isToastVisible &&
        createOfferInfluencersHook.ApiB.toast}

      {closeOfferModalToggler.isToggled && (
        <OfferCloseModal
          closeOfferModalToggler={closeOfferModalToggler}
          updateOfferHook={updateOfferHook}
          updateOfferInfluencersHook={updateOfferInfluencersHook}
          offer={offer}
        />
      )}

      <form
        onSubmit={editOfferHeadersForm.handleSubmit(onEditSpecsOffer)}
        onChange={handleOnFormChange}
      >
        {editOfferModalToggler.isToggled && (
          <OfferCreateModal
            toggler={editOfferModalToggler}
            register={editOfferHeadersForm.register}
            errors={editOfferHeadersForm.formState.errors}
            reset={editOfferHeadersForm.reset}
            setValue={editOfferHeadersForm.setValue}
            onEdit={true}
            offer={offer}
            client={offer?.client}
            readOnly={readOnly}
          />
        )}
      </form>

      <form onSubmit={shareOfferForm.handleSubmit(onShareOffer)}>
        {sharedModalToggler.isToggled && (
          <OfferShareModal
            toggler={sharedModalToggler}
            form={shareOfferForm}
            offer={offer}
            isLinkDisabled={true}
            link={link}
            setLink={setLink}
            /* readOnly={readOnly} */
          />
        )}
      </form>

      <form onSubmit={deleteOfferForm.handleSubmit(onDeleteOffer)}>
        {deleteOfferModalToggler.isToggled && (
          <DeleteModal
            toggler={deleteOfferModalToggler}
            register={deleteOfferForm.register}
            reset={deleteOfferForm.reset}
            offer={offer}
            deleteType={"offer"}
          />
        )}
      </form>
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "620px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: anyModalOpen && isMobile ? "none" : "",
          }}
        >
          <OfferCreateHeader
            shareToggler={sharedModalToggler}
            editToggler={editOfferModalToggler}
            readOnly={readOnly}
          />
          <OfferCreateSpecs />

          <form
            className="offers-create-form-wrapper"
            style={{ width: isMobile ? "345px" : "" }}
            onSubmit={editOfferDetailsForm.handleSubmit(onEditOfferDetails)}
          >
            <OfferDetailsResume
              register={editOfferDetailsForm.register}
              errors={editOfferDetailsForm.formState.errors}
              setValue={editOfferDetailsForm.setValue}
              reset={editOfferDetailsForm.reset}
              readOnly={readOnly}
            />
            <InfluencerSelector
              readOnly={readOnly}
              updateOfferListHook={updateOfferInfluencersHook}
              createOfferListHook={createOfferInfluencersHook}
            />
            <OfferDescriptionInput
              register={editOfferDetailsForm.register}
              errors={editOfferDetailsForm.formState.errors}
              getValues={editOfferDetailsForm.getValues}
              setValue={editOfferDetailsForm.setValue}
              readOnly={readOnly}
            />
            <div
              className="offer-ctas-box"
              style={{
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "center" : "",
                gap: isMobile ? "42px" : "",
              }}
            >
              <div
                className="offer-ctas-delete-box offLightBlue"
                onClick={() => {
                  if (readOnly) {
                  } else {
                    deleteOfferModalToggler.toggle();
                  }
                }}
                style={{ opacity: readOnly ? "0.4" : "1" }}
              >
                <img
                  className="offer-ctas-delete-icon offMainColor"
                  src={delete_icon}
                />
                <p className="offer-ctas-delete-text">Delete offer</p>
              </div>
              <div className="offer-ctas-save-close-box">
                <button
                  className="offer-ctas-save-button shadow"
                  type="submit"
                  disabled={readOnly}
                >
                  <p className="offer-ctas-save-buttonText">Save</p>
                </button>
                <button
                  className="offer-ctas-close-button"
                  type="button"
                  disabled={readOnly}
                  onClick={closeOfferModalToggler.toggle}
                >
                  <p className="offer-ctas-close-buttonText">Close offer</p>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default OfferDetailsPage;
