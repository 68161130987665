import { useState } from "react";
import { apiURL } from "../../consts/URLs";
import { urlRequestParamBuilder } from "../../utils/transformMethods";
import { OFFERS, SHARED_ZIP } from "../endpointList";

const useZip = (token?: string | null) => {
  const [getSharedZipResponse, setGetSharedZipResponse] = useState<any>();

  const getSharedZip = (tempToken: string /* , type: string */) => {
    const params = urlRequestParamBuilder({
      /* type: type, */ token: tempToken,
    });
    const url = `${apiURL}/${OFFERS}/${SHARED_ZIP}${params}`;
    let myHeaders = new Headers();
    myHeaders.append("Content-type", "application/x-zip; charset=UTF-8");
    fetch(url, { method: "GET", headers: myHeaders }).then((res) =>
      setGetSharedZipResponse(res)
    );
  };

  const RESPONSES = {
    getSharedZipResponse,
  };

  const RESULTS = {};

  const CTAs = {
    getSharedZip,
  };

  return { RESPONSES, RESULTS, CTAs };
};
export default useZip;
