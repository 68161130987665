import { useForm } from "react-hook-form";
import useRegister from "../../api/hooks/authentication/useRegister";
import { settingsInputs } from "../../consts/inputs";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { getInputs } from "../../utils/getMethods";
import CustomInput from "../inputs/CustomInput";
import { useContext, useEffect } from "react";
import useLogMessage from "../../hooks/useLogMessage";
import useToggle from "../../hooks/useToggle";
import LogMessage from "../misc/Toast";
import { logMsgBgColors } from "../../consts/misc";
import close_icon from "../../assets/ico/close.svg";
import MainContext from "../../context/MainContext";

const SettingsCreateUserModal = (props: any) => {
  const { token } = useContext(MainContext);
  const { registerHook } = props;
  const { isMobile } = useIsMobile();
  const createNewUserForm = useForm();

  const onSubmit = (data: any) => {
   /*  console.log( JSON.stringify(data)); */
    registerHook.register(data);
  };


  const inputsArray = getInputs(settingsInputs, ["name", "surname", "email"]);
  const renderInputs = inputsArray.map((input, key) => {
    return (
      <CustomInput
        key={key}
        width={"100%"}
        title={input.title}
        name={input.name}
        type={input.type}
        register={createNewUserForm.register}
        errors={createNewUserForm.formState.errors}
        {...input}
      />
    );
  });

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        className="settings-edit-modal "
        style={{
          height: props.isMainUser
            ? isMobile
              ? "75vh"
              : "fit-content"
            : "543px",
          width: isMobile ? "345px" : "",
        }}
      >
        <form onSubmit={createNewUserForm.handleSubmit(onSubmit)}>
          <div
            className="settings-edit-modal-content"
            style={{ margin: isMobile ? "35px 19px 0 21px" : "" }}
          >
            <div className="modal-header">
              <p className="modal-title">Add user</p>
              <div className="Oval" onClick={props.toggler.toggle}>
                <img src={close_icon} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                marginTop: "22px",
              }}
            >
              {renderInputs}
            </div>
            <button
              style={{
                marginTop: "44px",
                marginRight: "0",
                width: isMobile ? "100%" : "105px",
              }}
              className="modal-button"
              type="submit"
            >
              <p className="modal-button-text">Add</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SettingsCreateUserModal;
