import { createContext } from "react";
import { Client, Influencer, Offer, User } from "../interfaces";

type MainCtx = {
  pageHeight: number;
  setPageHeight: React.Dispatch<React.SetStateAction<number>>;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  influencerSelector: {
    onInfluencerSelection: boolean;
    setOnInfluencerSelection: React.Dispatch<React.SetStateAction<boolean>>;
    tempInfluencerList: Influencer[];
    setTempInfluencerList: React.Dispatch<
      React.SetStateAction<Influencer[] | []>
    >;
    influencerList: Influencer[];
    setInfluencerList: React.Dispatch<React.SetStateAction<Influencer[] | []>>;
  };
  user: User | null;
  userTreated: User | null;
  offer: Offer | null;
  client: Client | null;
  influencer: Influencer | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setUserTreated: React.Dispatch<React.SetStateAction<User | null>>;
  setOffer: React.Dispatch<React.SetStateAction<Offer | null>>;
  setClient: React.Dispatch<React.SetStateAction<Client | null>>;
  setInfluencer: React.Dispatch<React.SetStateAction<Influencer | null>>;
};

const defaultContext = {
  pageHeight: 0,
  setPageHeight: () => {},
  refresh: false,
  setRefresh: () => {},
  token: null,
  setToken: () => {},
  influencerSelector: {
    onInfluencerSelection: false,
    setOnInfluencerSelection: () => {},
    tempInfluencerList: [],
    setTempInfluencerList: () => {},
    influencerList: [],
    setInfluencerList: () => {},
  },
  user: null,
  userTreated: null,
  offer: null,
  client: null,
  influencer: null,
  setSelectorList: () => {},
  setUser: () => {},
  setUserTreated: () => {},
  setOffer: () => {},
  setClient: () => {},
  setInfluencer: () => {},
};

const MainContext = createContext<MainCtx>(defaultContext);

export default MainContext;
