const TextAreaInput = (props: any) => {
  return (
    <div
      className={`customInputText-box`}
      style={{
        width: `${props.width}px`,
      }}
    >
      <p className={`customInputText-title`}>{props.title}</p>
      <div style={{ display: "flex" }}>
        <textarea
          style={{
            height: `${props.height - 34}px`,
          }} /* 34 is the length of total padding of both sides */
          className={`customInputText-input textarea-addon ${
            props.errors[props.name] && "onError"
          }`}
          {...props.register(props.name, props.constraints)}
          defaultValue={props.defaultValue}
          value={props.value}
        />
      </div>
    </div>
  );
};
export default TextAreaInput;
