import { useContext } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import MainContext from "../../context/MainContext";

const OverviewHeader = (props: any) => {
  const { isMobile } = useIsMobile();

  const { user } = useContext(MainContext);

  return (
    <div className={`overview-header ${isMobile && "o-h-Mob"}`}>
      <p className={`overview-header-greet`}>
        {"Hi"} {user?.name} {user?.surname},
      </p>
      <p className={`overview-header-title`}>Overview</p>
    </div>
  );
};
export default OverviewHeader;
