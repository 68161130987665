import { useState } from "react";
import { apiURL } from "../../../consts/URLs";
import { FORGOT_PASSWORD } from "../../endpointList";
import { fetchRequest, getOptions } from "../../utils";
import useApiBuilder from "../useApiBuilder";
import { subjects } from "../../../consts/statuses";

const useRecoveryPassord = () => {
  const ApiB = useApiBuilder(subjects.recovery, true);

  const recoveryPassword = (email: string) => {
    const url = `${apiURL}/${FORGOT_PASSWORD}`;
    const options = getOptions({ method: "POST", body: { email } });
    ApiB.call(url, options);
  };

  return { recoveryPassword, ApiB };
};
export default useRecoveryPassord;
