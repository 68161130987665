import { useContext, useEffect } from "react";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import iconlyLight_icon from "../../assets/ico/iconly-light-category.svg";
import profile_icon from "../../assets/ico/profile.svg";
import wallet_icon from "../../assets/ico/wallet.svg";
import { getInputs } from "../../utils/getMethods";
import { editInputs } from "../../consts/inputs";
import MainContext from "../../context/MainContext";

const OfferDetailsResume = (props: any) => {
  const { isMobile } = useIsMobile();

  const { offer } = useContext(MainContext);

  useEffect(() => {
    props.reset();
  }, []);

  const options = getInputs(editInputs, ["verticalities"])[0];

  return (
    <div className={`offer-create-resume shadow ${isMobile && "o-c-r-Mob"}`}>
      <div
        className="offer-create-resume-field-box"
        style={{
          marginTop: isMobile ? "-20px" : "",
          justifyContent: isMobile ? "center" : "space-between",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <p className="offer-create-resume-field-name">Category</p>
        <div
          className="offer-create-resume-field-input-box"
          style={{
            margin: isMobile ? "-10px 0 0 -25px" : "",
            width: isMobile ? "auto" : "",
          }}
        >
          <img
            className="offer-create-resume-field-icon"
            src={iconlyLight_icon}
          />
          <select
            disabled={props.readOnly}
            className="offer-create-select"
            {...props.register("category")}
            defaultValue={offer?.category}
          >
            {options.fields.map((item: any, key: number) => {
              return (
                <option key={key} value={item.name}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div
        className="offer-create-resume-field-box"
        style={{
          marginTop: isMobile ? "-60px" : "",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <p className="offer-create-resume-field-name">Requested influencers</p>
        <div
          className="offer-create-resume-field-input-box"
          style={{
            margin: isMobile ? "-10px 0 0 -25px" : "",
            width: isMobile ? "auto" : "",
          }}
        >
          <img className="offer-create-resume-field-icon" src={profile_icon} />
          <input
            className="offer_create_input"
            disabled={props.readOnly}
            type="number"
            {...props.register(
              "requested_talents" /* {
              min: 1,
              max: offer?.requested_influencers,
            } */
            )}
            placeholder="0"
            defaultValue={offer?.requested_influencers}
          />
        </div>
      </div>
      <div
        className="offer-create-resume-field-box"
        style={{
          marginTop: isMobile ? "-60px" : "",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <p className="offer-create-resume-field-name">Budget</p>
        <div
          className="offer-create-resume-field-input-box"
          style={{
            margin: isMobile ? "-10px 0 0 -25px" : "",
            width: isMobile ? "auto" : "",
          }}
        >
          <img className="offer-create-resume-field-icon" src={wallet_icon} />
          <p className="euro">€</p>
          <input
            className="offer_create_input"
            disabled={props.readOnly}
            type="number"
            {...props.register("budget")}
            placeholder="0.00"
            pattern="^\d*(\.\d{0,2})?$"
            defaultValue={offer?.budget}
            step={"any"}
          />
        </div>
      </div>
    </div>
  );
};
export default OfferDetailsResume;
