import { useState } from "react";
import { apiURL } from "../../consts/URLs";
import { INFLUENCERS, VERTICALITIES } from "../endpointList";
import { fetchRequest, getOptions } from "../utils";
import useApiBuilder from "./useApiBuilder";

const useVerticalities = (token?: string | null) => {
  const ApiB = useApiBuilder();

  const createVerticality = (influencerId: number, obj: any) => {
    const url = `${apiURL}/${INFLUENCERS}/${influencerId}/${VERTICALITIES}`;
    const options = getOptions({
      method: "POST",
      body: {
        ...obj,
      },
      token: token,
    });
    ApiB.call(url, options);
  };

  const deleteVerticality = (influencerId: number, vertId: number) => {
    const url = `${apiURL}/${INFLUENCERS}/${influencerId}/${VERTICALITIES}/${vertId}`;
    const options = getOptions({ method: "DELETE", token: token });
    ApiB.call(url, options);
  };

  const CTAs = {
    createVerticality,
    deleteVerticality,
  };

  return { CTAs, ApiB };
};
export default useVerticalities;
