import { useState } from "react";
import { apiURL } from "../../consts/URLs";
import {
  GET_OFFERS_BY_ACCEPTED_INFLUENCERS,
  INFLUENCERS,
  OFFERS,
  SHARE,
} from "../endpointList";
import { fetchRequest, getOptions, urlRequestParamBuilder } from "../utils";
import useApiBuilder from "./useApiBuilder";
import { subjects } from "../../consts/statuses";

const useOffer = (token?: string | null, subject?: string) => {
  const ApiB = useApiBuilder(subject ? subject : subjects.offer);

  const createOffer = (obj: any) => {
    const url = `${apiURL}/${OFFERS}`;
    const options = getOptions({
      method: "POST",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const updateOffer = (id: number, obj: any) => {
    const url = `${apiURL}/${OFFERS}/${id}`;
    const options = getOptions({
      method: "PUT",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const createOfferInfluencers = (id: number, obj: any) => {
    const url = `${apiURL}/${OFFERS}/${id}/${INFLUENCERS}`;
    const options = getOptions({
      method: "POST",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const updateOfferInfluencers = (id: number, obj: any) => {
    const url = `${apiURL}/${OFFERS}/${id}/${INFLUENCERS}`;
    const options = getOptions({
      method: "PUT",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const deleteOffer = (id: number) => {
    const url = `${apiURL}/${OFFERS}/${id}`;
    const options = getOptions({ method: "DELETE", token: token });
    ApiB.call(url, options);
  };

  const getOfferById = (id: number) => {
    const url = `${apiURL}/${OFFERS}/${id}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  /*  const getOffersByAcceptedModelId = (model_id: number) => {
    const url = `${apiURL}/${GET_OFFERS_BY_ACCEPTED_INFLUENCERS}/${model_id}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };
 */
  const getOffers = () => {
    const url = `${apiURL}/${OFFERS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const getOffersListPaginated = () => {
    const url = `${apiURL}/${OFFERS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const getSharedOffer = (id: number, obj?: any) => {
    let params = null;
    if (obj != null) {
      params = urlRequestParamBuilder(obj);
    }
    const url = `${apiURL}/${OFFERS}/${id}/${SHARE}${params ? params : ""}`;
    const options = getOptions({
      method: "GET",
    });
    ApiB.call(url, options);
  };

  const createSharedOffer = (id: number, obj: any) => {
    /* 
    const params = urlRequestParamBuilder(obj); */
    const url = `${apiURL}/${OFFERS}/${id}/${SHARE}`;
    const options = getOptions({
      method: "POST",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const CTAs = {
    createOffer,
    updateOffer,
    deleteOffer,
    getOfferById,
    getOffers,
    getOffersListPaginated,
    createOfferInfluencers,
    updateOfferInfluencers,
    getSharedOffer,
    createSharedOffer,
  };

  return { CTAs, ApiB };
};
export default useOffer;
