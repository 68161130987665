import search from "../../assets/ico/search.svg";
import notifications from "../../assets/ico/notifications.svg";

const SearchBar = (props: any) => {
  const isStringType = () => {
    if (typeof props.width === typeof "string") {
      return true;
    } else return false;
  };
  return (
    <div className="searchbar-notify">
      {props.register ? (
        <input
          style={{
            width: isStringType() ? `${props.width}` : `${props.width}px`,
          }}
          className="searchbar-input"
          {...props.register("name")}
          placeholder="Search"
        />
      ) : (
        <input
          style={{ width: `${props.width}px` }}
          className="searchbar-input"
        />
      )}
      <img
        className="searchbar-icon"
        src={search}
        style={{
          transform: `translatex(-${
            props.width +
            (props.width / 100) * (15 + props.iconShift ? props.iconShift : 0)
          }px)`,
        }}
      />
      {props.notify && (
        <div className="Oval">
          <img className="notify-icon" src={notifications} />
        </div>
      )}
    </div>
  );
};
export default SearchBar;
