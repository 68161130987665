import { useEffect } from "react";
import useToggle from "../../hooks/useToggle";
import password_icon from "../../assets/ico/password.svg";
import calendar_icon from "../../assets/ico/calendar.svg";
import copy_icon from "../../assets/ico/copy.svg";
import iconlyLightLocation_icon from "../../assets/ico/iconly-light-location.svg";
import { editInputs } from "../../consts/inputs";
import { fnBrowserDetect } from "../../utils/others";

const TextInput = (props: any) => {
  const passwordToggler = useToggle(false);
  const copyToggler = useToggle(false);
  const copyAnimationToggler = useToggle(false);

  const copyIconChangeColor = () => {
    copyToggler.toggle();
  };
  useEffect(() => {
    if (copyToggler.isToggled) {
      copyToggler.setIsToggledSecondary(true);
      setTimeout(() => {
        copyToggler.setIsToggledSecondary(false);
      }, 3000);
    }
  }, [copyToggler.isToggled]);

  useEffect(() => {
    if (copyToggler.isToggledSecondary) {
      copyAnimationToggler.setIsToggled(true);
      setTimeout(() => {
        copyAnimationToggler.setIsToggled(false);
      }, 2000);
    }
  }, [copyToggler.isToggledSecondary]);

  const widthTypeString = typeof props.width === typeof "string";

  const isSocial =
    props.title === "Tiktok" ||
    props.title === "Facebook" ||
    props.title === "Instagram" ||
    props.title === "Twitch" ||
    props.title === "Youtube" ||
    props.title === "Linkedin" ||
    props.title === "Twitter";
  const isPasswordType = props.type === "password";
  const isDateType = props.type === "Date";
  const isLocationType = props.locationIcon;
 

  return (
    <div
      className={`customInputText-box`}
      style={{
        width: widthTypeString
          ? `calc(${props.width} ${isPasswordType ? " + 30px" : ""})`
          : `${props.width + 30}px`,
        marginBottom: isSocial ? "-10px" : "",
      }}
    >
      {!isSocial && props.title && (
        <p className={`customInputText-title`}>{props.title}</p>
      )}
      <div style={{ display: "flex", gap: isSocial ? "22px" : "" }}>
        {isSocial && <img className="social-icon" src={props.iconSrc} />}

        {props.register ? (
          <input
            className={`customInputText-input ${
              props.errors[props.name] && "onError"
            }`}
            placeholder={props.placeholder}
            {...props.register(props.name, props.constraints)}
            type={
              isPasswordType
                ? passwordToggler.isToggled
                  ? "text"
                  : props.type
                : props.type
            }
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props?.others?.disabled}
          />
        ) : (
          <input
            className={`customInputText-input`}
            placeholder={props.placeholder}
            type={
              isPasswordType
                ? passwordToggler.isToggled
                  ? "text"
                  : props.type
                : props.type
            }
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props?.others?.disabled}
          />
        )}

        {props.name && props.name.includes("password") && (
          <img
            className={`inputIcon ${
              passwordToggler.isToggled ? "offMainColor" : ""
            }`}
            style={{
              transform: `translateY(13px)`,
            }}
            src={password_icon}
            onClick={passwordToggler.toggle}
          />
        )}
        {isDateType && !props?.noIcon && fnBrowserDetect() === "chrome" && (
          <img
            className="inputIcon"
            style={{
              cursor: "default",
              transform: `translateY(13px)`,
            }}
            src={calendar_icon}
          />
        )}
        {props.copyIcon && !props?.noIcon && (
          <div className="tooltipCopyIcon">
            <img
              className={`inputIcon ${
                copyToggler.isToggled && "offMainColor"
              } `}
              style={{
                cursor: "pointer",
                transform: `translatex(-45px) translateY(13px)`,
                backgroundColor: "rgba(228, 228, 228)",
                padding: "2px 4px ",
                borderRadius: "12px",
              }}
              src={copy_icon}
              onMouseDown={copyIconChangeColor}
              onMouseUp={copyIconChangeColor}
              onClick={() => {
                let tempvalue = "";
                if (props.watch) {
                  tempvalue = props.watch(`${props.name}`);
                } else tempvalue = props.value;
                navigator.clipboard.writeText(tempvalue);
              }}
            />
            {copyToggler.isToggledSecondary && (
              <span
                className={`tooltipCopyIconText ${
                  copyAnimationToggler.isToggled
                    ? "shiftUpFadeIn"
                    : "shiftDownFadeOut"
                } `}
              >
                link copied!
              </span>
            )}
          </div>
        )}
        {isLocationType && !props?.noIcon && (
          <img
            className="inputIcon black"
            style={{
              cursor: "pointer",
              transform: `translatex(-45px) translateY(13px)`,
            }}
            src={iconlyLightLocation_icon}
          />
        )}
      </div>
    </div>
  );
};
export default TextInput;
