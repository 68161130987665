import { useState } from "react";
import { apiURL } from "../../../consts/URLs";
import { CHANGE_PASSWORD, FORGOT_PASSWORD, USER } from "../../endpointList";
import { fetchRequest, getOptions } from "../../utils";
import useApiBuilder from "../useApiBuilder";
import { subjects } from "../../../consts/statuses";

const useChanegPassword = (token?: string | null, subject?: string) => {
  const ApiB = useApiBuilder(subject ? subject : subjects.change);

  const changePassword = (password: string, password_confirmation: string) => {
    const url = `${apiURL}/${USER}/${CHANGE_PASSWORD}`;
    const options = getOptions({
      method: "POST",
      body: {
        password,
        password_confirmation,
      },
      token: token,
    });
    ApiB.call(url, options);
  };

  return { changePassword, ApiB };
};
export default useChanegPassword;
