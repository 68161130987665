export const REGISTER = "register";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const FORGOT_PASSWORD = "password/forgot";
export const UPDATE_PASSWORD = "update_password";
export const RESET_PASSWORD = "password/reset";
export const CHANGE_PASSWORD = "change_password";
export const USER = "user";
export const USERS = "users";
export const VERIFICATION_USER = "email/verify/";
export const CLIENTS = "clients";
export const INFLUENCERS = "talents";
export const SOCIAL = "social_infos"; 
export const VERTICALITIES = "verticalities";
export const OFFERS = "offers";
export const SHARE = "share";
export const GET_OFFERS_BY_ACCEPTED_INFLUENCERS = "offert_influencers_filtered";
export const GET_EVENTS_BY_INFLUENCER_ID = "influencer_events";
export const EVENTS = "events";
export const POSTS= "posts"
export const SHARED_ZIP = "get_shared_offert_zip";
export const CDN_URL= "proxy/fetch_cdn"
