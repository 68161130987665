import { useContext, useEffect } from "react";
import { editInputs, extraFilterInputs } from "../../consts/inputs";
/* import FilterContext from "../../context/FilterContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";
import { getInputs } from "../../utils/getMethods";
import CustomInput from "../inputs/CustomInput";
import small_up from "../../assets/ico/small-up.svg";
import small_down from "../../assets/ico/small-down.svg";
import FilterContext from "../../context/FilterContext";

const Filter = (props: any) => {
  const { isMobile } = useIsMobile();

  const {
    resetFiltersToggler,
    verticalitiesToggler,
    platformsToggler,
    targetToggler,
  } = props;

  const filterCTX = useContext(FilterContext);

  const verticalitiesHandler = () => {
    filterCTX.setIsVerticalitiesOpen((prevState) => !prevState);
    verticalitiesToggler.toggle();
  };
  const platformHandler = () => {
    filterCTX.setIsPlatformsOpen((prevState) => !prevState);
    platformsToggler.toggle();
  };
  const targetHandler = () => {
    filterCTX.setIsTargetOpen((prevState) => !prevState);
    targetToggler.toggle();
  };

  const resetHandler = () => {
    resetFiltersToggler.toggle();
  };

  const vert_plat_inputs = getInputs(editInputs, [
    "verticalities",
    "platforms",
  ]);
  const target_inputs = getInputs(extraFilterInputs, ["target"]);

  const sections = vert_plat_inputs.concat(target_inputs);

  const renderSections = sections.map((input: any, key: number) => {
    let action: any;
    let selectAllToggler: any;
    let sectionToggler: any;
    let setAction: any;
    let ctxObj = {};

    if (input.name === "verticalities") {
      action = verticalitiesHandler;
      selectAllToggler = props.selectAllVerticalitiesToggler;
      setAction = props.byVerticalitiesSetAction;
      sectionToggler = props.verticalitiesToggler;
      ctxObj = filterCTX.verticalities;
    }
    if (input.name === "platforms") {
      action = platformHandler;
      selectAllToggler = props.selectAllPlatformsToggler;
      setAction = props.byPlatformsSetAction;
      sectionToggler = props.platformsToggler;
      ctxObj = filterCTX.platforms;
    }
    if (input.name === "target") {
      action = targetHandler;
      selectAllToggler = props.selectAllTargetsToggler;
      setAction = props.byTargetSetAction;
      sectionToggler = props.targetToggler;
      ctxObj = filterCTX.target;
    }

    return (
      <div key={key}>
        <div className="features-filter-box">
          <div className="features-filter-title-box">
            <p
              className="features-filter-title"
              style={{
                marginLeft: "22px",
                cursor: "pointer",
              }}
              onClick={action}
            >
              {input.title}
            </p>
            <img
              className="features-filter-title-icon"
              src={sectionToggler.isToggled ? small_up : small_down}
              alt=""
              onClick={action}
              style={{
                marginRight: "22px",
              }}
            />
          </div>
          {sectionToggler.isToggled && (
            <div className="features-filter-inputs">
              {input.fields.map((field: any, key: number) => {
                let startingValue: any = false;

                Object.entries(ctxObj).forEach(([a, b]) => {
                  if (a == field.name) {
                    startingValue = b;
                  }
                });

                return (
                  <CustomInput
                    key={key}
                    {...field}
                    type="checkbox_filter" // override input type
                    defaultValue={startingValue}
                    others={{
                      setAction: setAction,
                      selectAllToggler: selectAllToggler,
                      resetToggler: props.resetFiltersToggler,
                    }}
                  />
                );
              })}

              <p className="selectAll" onClick={selectAllToggler.toggle}>
                {selectAllToggler.isToggled ? "Deselect" : "Select"} All
              </p>
            </div>
          )}
        </div>
        <div
          className="divider"
          style={{ width: isMobile ? "345px" : "100%", margin: "10px 0" }}
        />
      </div>
    );
  });

  return (
    <div
      className="models-filter-box shadow"
      style={{
        width: isMobile ? "335px" : "",
        margin: isMobile ? "0 auto 20.5px" : "",
      }}
    >
      {renderSections}
      <p className="reset-filters" onClick={resetHandler}>
        Reset all filters
      </p>
    </div>
  );
};
export default Filter;
