import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageInterface } from "../../interfaces";
import { theme } from "../../consts/palette";

const SideBarItem = (props: PageInterface) => {
  const navigate = useNavigate();
  const [isOnHover, setIsOnHover] = useState(false);
  const [isOnPage, setIsOnPage] = useState(false);

  useEffect(() => {
    /* console.log(isOnPage, window.location.pathname, props.pathName); */
    if (window.location.pathname.includes(props.pathName!)) {
      setIsOnPage(true);
    } else {
      setIsOnPage(false);
    }
  });

  /*  const { modelSelector } = useContext(MainContext); */

  const handelOnItemClick = () => {
    /* modelSelector.setOnModelSelection(false);
    modelSelector.setModelList([]);*/
    navigate(`${props.pathName}`);
  };

  const styleCondition = isOnHover || isOnPage;

  return (
    <div
      className={` sidebar-item `}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
      onClick={handelOnItemClick}
      style={{
        backgroundColor: styleCondition ? theme.main_color : "",
        color: styleCondition ? "#fff" : "",
        borderRadius: styleCondition ? "12px" : "",
      }}
    >
      <img
        className={`sidebar-icon ${styleCondition && "white"}`}
        src={props.iconPath}
      />
      <p className={`sidebar-link ${styleCondition && "white"}`}>
        {props.displayName}
      </p>
    </div>
  );
};
export default SideBarItem;
