import { useForm } from "react-hook-form";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/inputs/CustomInput";
import { authInputs } from "../../../consts/inputs";
import useIsMobile from "../../../hooks/useIsMobile";
import { getInputs } from "../../../utils/getMethods";
import logo_main from "../../../assets/logo_main.png";

const FirstAccessPage = (props: any) => {
  const { isMobile } = useIsMobile();

  const firstAccessForm = useForm();
  const {
    formState: { errors },
  } = firstAccessForm;

  const chosenInputs = getInputs(authInputs, [
    "password",
    "confirmed_password",
  ]);
  const renderInputs = chosenInputs.map((input, key) => {
    return (
      <CustomInput
        key={key}
        width={isMobile ? 305 : 460}
        {...input}
        register={firstAccessForm.register}
        errors={errors}
      />
    );
  });

  const onSubmit = (data: any) => console.log(data);

  return (
    <div className="access-background">
      <img className="logo-main" src={logo_main} />
      <div className={`access-content-box ${isMobile && "a-c-b-Mob"}`}>
        <div className="access-content-box-inner-wrapper">
          <form onSubmit={firstAccessForm.handleSubmit(onSubmit)}>
            <div className="access-contentBox-header">
              <div className="cornerTitle">
                {`Welcome ${"userName here"}`}
                <p className="cornerSubTitle">Enter your password to log in</p>
              </div>
            </div>
            <div
              className="input-positioner"
              style={{ gap: "32px", marginTop: "26px" }}
            >
              {renderInputs}
            </div>
            <div
              className="contentBox-confirmationBox"
              style={{ marginTop: "52px" }}
            >
              <span></span>
              <button className="button1 b-short" type="submit">
                <p className="button1-text">{"Confirm"}</p>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default FirstAccessPage;
