import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useClient from "../../api/hooks/useClients";
import smallRight_icon from "../../assets/ico/small-right.svg";
import MainContext from "../../context/MainContext";
import { offerRemappingFromResult } from "../../utils/remapping";
import { paths } from "../../consts/URLs";
import { possible_statuses } from "../../consts/statuses";
import closed from "../../assets/illustration/closed.jpg";

const OpenOfferTag = (props: any) => {
  const navigate = useNavigate();

  const { setOffer } = useContext(MainContext);

  const handleRedirectToOffer = () => {
    const remappedOffer = offerRemappingFromResult(props.offer);
    /* console.log(remappedOffer); */
    setOffer(remappedOffer);
    navigate(paths.pages.offer_detail.replace(":id", props.offer.id));
  };

  const [offerImageSrc, setOfferImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (props.offer.proposed_list && props.offer.proposed_list.length > 0) {
      let profileImgSrc = "";
      if (
        props.offer.status === possible_statuses.offer.approved ||
        props.offer.status === possible_statuses.offer.substatus.confirmed
      ) {
        profileImgSrc = props.offer.proposed_list.filter(
          (item: any) => item.status === possible_statuses.influencer.approved
        )[0].talent_profileImage;
      } else if (props.offer.status === possible_statuses.offer.open) {
        profileImgSrc = props.offer.proposed_list.filter(
          (item: any) => item.status === possible_statuses.influencer.pending
        )[0].talent_profileImage;
      } else profileImgSrc = closed;
      setOfferImageSrc(profileImgSrc);
    }
  }, [props.offer]);
 

  return (
    <div className="open-offer-tag" onClick={handleRedirectToOffer}>
      <img
        className="open-offer-image"
        style={{
          backgroundImage:offerImageSrc ? `url(${offerImageSrc})`:"",
          border: "1px solid lightgrey",
        }}
      />
      <div className="open-offer-info">
        <p className="open-offer-title">{props.offer.offer_name}</p>
        <p className="open-offer-agency">{props.offer.client.brand_name}</p>
      </div>
      <img className="open-offer-icon" src={smallRight_icon} />
    </div>
  );
};
export default OpenOfferTag;
