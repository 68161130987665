import { useContext, useEffect, useState } from "react";
import useUser from "../../api/hooks/useUser";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import { User } from "../../interfaces";
import { getRandomBgColor } from "../../utils/others";
import UserCard from "./UserCard";
import "../../styles/Offers.css";
import MainContext from "../../context/MainContext";

const UsersTable = (props: any) => {
  const { isMobile } = useIsMobile();

  const { isToggled } = props.resetList;

  const { user, userTreated, token } = useContext(MainContext);

  const getUsersHook = useUser(token);

  const [usersList, setUsersList] = useState<User[]>([]);
  const [usersListBgColors, setUsersListBgColors] = useState<string[]>([]);

  useEffect(() => {
    getUsersHook.CTAs.getUsersList();
  }, [isToggled, user, userTreated]);

  useEffect(() => {
    /* console.log(getUsersHook.ApiB.response, getUsersHook.ApiB.result);   */
    if (getUsersHook.ApiB.response?.ok) {
      const result = getUsersHook.ApiB.result?.users;
      setUsersList(result);
      const finalBgColors: string[] = [];
      if (result) {
        result.forEach((element: any) => {
          const bg = getRandomBgColor();
          finalBgColors.push(bg);
        });
        setUsersListBgColors(finalBgColors);
      }
    }
  }, [getUsersHook.ApiB.response]);

  const renderUsers = usersList.map((user, key) => {
    const bgColor = usersListBgColors[key];
    return (
      <div key={key} className="offers-table-row">
        <UserCard
          user={user}
          deleteToggler={props.deleteToggler}
          bgColor={bgColor}
        />
      </div>
    );
  });

  return (
    <div
      className="users-table shadow"
      style={{
        width: isMobile ? "345px" : "",
        margin: isMobile ? "35px 16px" : "",
      }}
    >
      <div
        className="user-table-header"
        style={{
          justifyContent: isMobile ? "space-between" : "",
          margin: "31px 25px",
        }}
      >
        <div className="user-table-header-title-box">
          <p className="user-table-header-title">Other users</p>
          <p className="user-table-header-description">Manage users</p>
        </div>
        <button
          className="user-table-header-button shadow"
          type="button"
          onClick={props.toggler.toggle}
        >
          <p className="user-table-header-button-text">Add user</p>
        </button>
      </div>
      <div className="user-table-content">
        <div className="offers-table">
          <div
            className="offers-table-fields"
            style={{
              width: isMobile ? "" : "90%",
            }}
          >
            <div
              className="u-name"
              style={{
                margin: isMobile ? "0" : " 0 0 0 90px",
              }}
            >
              <p className="field-label">Name</p>
            </div>
            {!isMobile && (
              <div className="u-mail">
                <p className="field-label">E-mail</p>
              </div>
            )}
            <div
              className="u-status"
              style={{
                marginLeft: isMobile ? "105px" : "",
              }}
            >
              <p className="field-label">Status</p>
            </div>
          </div>
          <div
            className="divider"
            style={{ width: "100%", marginTop: "10px" }}
          />
          <div className="users-table-results scroll-y">{renderUsers}</div>
        </div>
      </div>
    </div>
  );
};
export default UsersTable;
