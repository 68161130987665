import { endOfWeek, getDay, getMonth, startOfWeek } from "date-fns"; /*
import { days_of_the_week, month_names, workCategories } from "../consts/misc";
import { possible_statuses } from "../consts/possible_statuses";
import {
  Category,
  Event,
  Input,
  Model,
  Offer,
  PageInterface,
} from "../interfaces";
 */
/* export const getSubSections = (list: PageInterface[]) => {
  let finalArray: PageInterface[] = [];
  list.map((page: PageInterface) => {
    page.subsection?.forEach((element) => {
      finalArray.push(element);
    });
  });
  return finalArray;
}; */

import { possible_statuses } from "../consts/statuses";
import {
  Input,
  Offer,
  PageInterface,
  Social,
  Verticality,
} from "../interfaces";
import { month_names } from "../consts/misc";
import { paths } from "../consts/URLs";

export const getInputs = (
  inputsArray: any[],
  stringArray: string[],
  type?: string
) => {
  let results: any[] = [];
  inputsArray.forEach((input) => {
    for (let i = 0; i < stringArray.length; i++) {
      if (input.name === stringArray[i]) {
        results.push(input);
      }
    }
  });
  if (stringArray.length === 0) {
    results = inputsArray;
  }
  if (type) {
    let temp: any[] = [];
    results.forEach((input) => {
      if (input.type === type) {
        temp.push(input);
      }
    });
    results = temp;
  }
  return results;
};

export const getPage = (pagesArray: PageInterface[], stringArray: string[]) => {
  let results: any = [];
  pagesArray.forEach((page) => {
    for (let i = 0; i < stringArray.length; i++) {
      if (page.name === stringArray[i]) {
        results.push(page);
      }
    }
  });
  return results;
};

export const getOfferStatus = (status: string) => {
  let statusTreated = status ? status.toLocaleLowerCase() : "";
  if (
    statusTreated === possible_statuses.offer.approved ||
    statusTreated === possible_statuses.offer.substatus.confirmed
  ) {
    return { bgColor: "#d4ffd0", txtColor: "#0da900", txt: "Approved" };
  } else if (statusTreated === possible_statuses.offer.open) {
    return { bgColor: "#e4e4e4", txtColor: "#11142d", txt: "Open" };
  } else if (statusTreated === possible_statuses.offer.substatus.rejected) {
    return { bgColor: "#ffbebe", txtColor: "#da3200", txt: "Rejected" };
  } else if (statusTreated === possible_statuses.offer.substatus.no_answer) {
    return { bgColor: "#ffbebe", txtColor: "#da3200", txt: "No_answer" };
  } else if (statusTreated === possible_statuses.offer.closed) {
    return { bgColor: "#ffbebe", txtColor: "#da3200", txt: "Closed" };
  } else if (statusTreated === null) {
    return { bgColor: "#e4e4e4", txtColor: "#11142d", txt: "Open" };
  }
};

export const getUserStatusStyle = (status: string) => {
  if (status === possible_statuses.userStatus.pending) {
    return { bgColor: "#ffe2ac", textColor: "#ff7850" };
  } else if (status === possible_statuses.userStatus.active) {
    return { bgColor: "#d4ffd0", textColor: "#0da900" };
  }
};

export const getDefaultValue = (
  obj: Object,
  field: Input
): string | number | Date => {
  let defaultValue: string | number | Date = "";
  if (obj) {
    const clientEntries: any[] = Object.entries(obj);
    clientEntries.forEach((entry) => {
      if (entry[0] === field.name) defaultValue = entry[1];
    });
  }
  return defaultValue;
};

export const getPropertiesNames = (
  object: Object,
  preference?: string,
  propertyNames?: string[]
) => {
  let fieldsNames: string[] = [];
  const keys = Object.keys(object);
  if (preference && propertyNames) {
    if (propertyNames.every((elem) => keys.includes(elem))) {
      if (preference === "only") {
        fieldsNames = propertyNames;
      } else if (preference === "exclude") {
        fieldsNames = keys.filter((elem) => {
          return !propertyNames.includes(elem);
        });
      } else fieldsNames = ["prop doesn't match"];
    }
  } else fieldsNames = keys;

  return fieldsNames;
};

export const getPropertiesValues = (
  object: Object,
  excludePropertyName?: String
) => {
  const fieldsValues: string[] = [];
  for (const [key, value] of Object.entries(object)) {
    if (key === excludePropertyName) {
    } else fieldsValues.push(`${value}`);
  }
  return fieldsValues;
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};
/* 
export const getCurrentWeek = (startDate: Date, endDate: Date) => {
  const start = startOfWeek(startDate, { weekStartsOn: 1 });
  const end = endOfWeek(endDate, { weekStartsOn: 1 });
  return getDatesInRange(start, end);
}; */

export const getCurrentMonth = (date: Date) => {
  const month = getMonth(date);
  return { number: month, word: month_names[month] };
};
/* 
export const getCurrentDay = (date: Date) => {
  const day = getDay(date);
  return { number: day, word: days_of_the_week[day - 1 < 0 ? 6 : day - 1] };
}; */
/* 
export const getDayIndex = (dayNumber: number) => {
  if (dayNumber === 0) return 6;
  if (dayNumber === 1) return 0;
  if (dayNumber === 2) return 1;
  if (dayNumber === 3) return 2;
  if (dayNumber === 4) return 3;
  if (dayNumber === 5) return 4;
  if (dayNumber === 6) return 5;
}; */
/* 
export const getCategory = (category: string) => {
  let matchedCategory: Category = {
    bgColor: "",
    textColor: "",
    displayName: "",
    name: "",
    value: "",
  };
  workCategories.forEach((workCategory) => {
    if (workCategory.name === category) {
      matchedCategory = workCategory;
    }
  });
  return matchedCategory;
}; */

/* export const getOffersByStatus = (
  offers: Offer[],
  statusOnSearch: string,
  preference?: string
) => {
  let result: Offer[] | null = offers;
  result =
    offers &&
    offers.filter((offer: Offer) => {
      if (!preference) {
        return offer.status?.toLowerCase() === statusOnSearch.toLowerCase();
      } else if (preference && preference === "exclude") {
        return offer.status?.toLowerCase() !== statusOnSearch.toLowerCase();
      }
    });
  return result;
}; */
/* 
export const getOffersBySpecificDateRange = (
  offers: Offer[],
  from: Date,
  to: Date,
  propDateToUse: string
) => {
  let result: Offer[] | null = offers;
  result =
    result &&
    result.filter((offer: Offer) => {
      if (propDateToUse === "issue_date" && offer.issue_date) {
        return (
          from.getTime() <= offer.issue_date.getTime() &&
          offer.issue_date.getTime() <= to.getTime()
        );
      } else if (propDateToUse === "close_date" && offer.close_date) {
        return (
          from.getTime() <= offer.close_date.getTime() &&
          offer.close_date.getTime() <= to.getTime()
        );
      } else if (propDateToUse === "approved_date" && offer.approved_date) {
        return (
          from.getTime() <= offer.approved_date.getTime() &&
          offer.approved_date.getTime() <= to.getTime()
        );
      }
    });
  return result;
}; */
/* 
export const getEventsBySpecificDateRange = (
  events: Event[],
  from?: Date,
  to?: Date
) => {
  let finalArray: Event[] = [];
  if (from && to) {
    events.forEach((event) => {
      if (
        (event.starting_date.getTime() >= from.getTime() &&
          event.starting_date.getTime() <= to.getTime()) ||
        (event.ending_date.getTime() >= from.getTime() &&
          event.starting_date.getTime() <= from.getTime())
      ) {
        finalArray.push(event);
      }
    });
  } else if (from && !to) {
    events.forEach((event) => {
      if (event.starting_date.getTime() >= from.getTime()) {
        finalArray.push(event);
      }
    });
  } else if (!from && !to) {
    events.forEach((event) => {
      if (event.starting_date.getTime() >= new Date().getTime()) {
        finalArray.push(event);
      }
    });
  }
  return finalArray;
};
 */
export const subtractMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() - months);
  return date;
};

export const getOffersByLastMonths = (
  offers: Offer[] | null,
  numberOfMonths: number,
  propDate: string
) => {
  const result: Offer[] = [];
  const now = new Date();
  const date = subtractMonths(now, numberOfMonths);
  const dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
  const offersTreated: Offer[] | null = offers;

  offersTreated &&
    offersTreated.forEach((offer) => {
      let stringDate: any = null;
      if (propDate === `issue_date`) {
        stringDate = offer[`issue_date`];
      }
      if (propDate === "closed_date") {
        stringDate = offer["closed_date"];
      }
      if (propDate === "approved_date") {
        stringDate = offer["approved_date"];
      }
      let d = new Date();
      if (stringDate) {
        d = new Date(stringDate);

        if (d.getTime() >= dateStart.getTime()) {
          result.push(offer);
        }
      }
    });
  return result;
};

export const getLastMonthsArray = (numberOfMonths: number) => {
  let monthProcessed: string[] = [];
  const now = new Date();
  const startDate = subtractMonths(new Date(), numberOfMonths - 1);
  const datesProcessed = getDatesInRange(startDate, now);
  datesProcessed.forEach((date) => {
    let tempMonth = getCurrentMonth(date).word;
    if (monthProcessed.includes(tempMonth)) {
    } else monthProcessed.push(tempMonth);
  });
  return monthProcessed;
};

/* SE POSSIBILE TRASFORMARE IN UNA CHIAMATA A BE */

export const getEarningsCurrentMonth = (
  offerList: any[] /* Offer[] */ | null
) => {
  let amountEarned: number = 0;
  const now = new Date();
  const startMontheDate = new Date(now.getFullYear(), now.getMonth(), 1);
  offerList?.forEach((offer) => {
    if (
      offer.approved_date &&
      new Date(offer.approved_date).getMonth() === startMontheDate.getMonth() &&
      new Date(offer.approved_date).getFullYear() ===
        startMontheDate.getFullYear()
    ) {
      amountEarned = amountEarned + Number.parseFloat(offer.budget);
    }
  });
  return amountEarned.toFixed(2);
};

/* 
export const getOffersByClient = (list: Offer[], client: string) => {
  return list.filter(
    (offer) => offer.client?.name.toLowerCase() === client?.toLowerCase()
  );
};
 */ /* 
export const getOffersByStatus = (list: Offer[], status: string) => {
  return list.filter(
    (offer) => offer.status?.toLowerCase() === status?.toLowerCase()
  );
};
 */
export const getSuccessRate = (
  proposed_times: number,
  approved_times: number
) => {
  if (proposed_times === 0 || approved_times === 0) {
    return 0;
  } else return (approved_times / proposed_times) * 100;
};
/* 
export const getAvailableProposal = (list: Offer[]) => {
  let proposalArray: any[] = [];
  list.map((offer) => {
    if (offer.status === possible_statuses.offer.open) {
      proposalArray.push(offer);
    }
  });
  return proposalArray;
};
 */
export const getSocialName = (link: string) => {
  const parts = link.split(`/`);
  const name = parts[3];
  return name;
};

export const getSocials = (obj: any) => {
  const finalArraySocial: any[] = [];
  paths.social_icons.forEach((s) => {
    Object.entries(obj).forEach((o) => {
      const key = o[0];
      const value = o[1];
      if (key === s.name && value) {
        let username = "";
        if (typeof value === "string") {
          username = getSocialName(value);
        }
        const objToPush = {
          social_name: s.name,
          username: username,
          link: value,
        };
        finalArraySocial.push(objToPush);
      }
    });
  });
  return finalArraySocial;
};

export const getVerticalities = (obj: any) => {
  const finalArrayVerts: Verticality[] = [];
  Object.entries(obj).forEach((o) => {
    const key = o[0];
    const value = o[1];
    if (value === "true") {
      finalArrayVerts.push({ description: key });
    }
  });
  return finalArrayVerts;
};

export const getVerticalitiesValues = (obj: any) => {
  const finalArrayVerts: any[] = [];
  Object.entries(obj).forEach((o) => {
    const key = o[0];
    const value = o[1];
    finalArrayVerts.push({ key: value });
  });
  return finalArrayVerts;
};
