import { useState } from "react";
import { apiURL } from "../../../consts/URLs";
import { REGISTER } from "../../endpointList";
import { fetchRequest, getOptions } from "../../utils";
import useApiBuilder from "../useApiBuilder";
import { subjects } from "../../../consts/statuses";

const useRegister = (token?: string | null, subject?: string) => {
  const ApiB = useApiBuilder(subject ? subject : subjects.register);

  const register = (obj: any) => {
    const finalObj = {
      name: obj.name,
      surname: obj.surname,
      email: obj.email,
      role: 0,
    };
    const url = `${apiURL}/${REGISTER}`;
    const options = getOptions({
      method: "POST",
      body: finalObj,
      token: token,
    });
    ApiB.call(url, options);
  };

  return { register, ApiB };
};
export default useRegister;
