import { editInputs } from "../../consts/inputs";
import { theme } from "../../consts/palette";
import useIsMobile from "../../hooks/useIsMobile";

const InfluencerSpecsBar = (props: any) => {
  const { isMobile } = useIsMobile();

  const renderSpecs = props.influencer?.verticalities.map(
    (vert: any, key: number) => {
      return (
        <div
          key={key}
          className="model-details-card-info-spec-box"
          style={{ margin: isMobile ? "0 auto" : "10px auto" }}
        >
          <p
            className="model-details-card-info-spec-value"
            style={{
              width: "fit-content",
              backgroundColor: "#f7f9fa",
              textAlign: "center",
              borderRadius: "12px",
              padding: "15px 16px",
              color: theme.main_color,
            }}
          >
            {vert.description[0].toUpperCase() +
              vert.description.slice(1, vert.description.length)}
          </p>
        </div>
      );
    }
  );

  return (
    <div
      className="model-specs-bar-positioner shadow"
      style={{
        padding: isMobile ? "18px 0" : "",
        width: isMobile ? "345px" : "",
      }}
    >
      <div
        className="model-specs-bar"
        style={{
          display: isMobile ? "grid" : "",
          gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "",
          gap: isMobile ? "15px" : "",
        }}
      >
        {renderSpecs}
      </div>
    </div>
  );
};
export default InfluencerSpecsBar;
