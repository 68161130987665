import useIsMobile from "../../hooks/useIsMobile";
import { LogMessage } from "../../interfaces";
import logo from "../../assets/logo_only_image.png";

const Toast = (props: LogMessage) => {
  const { isMobile } = useIsMobile();
  return (
    <div className="log-message-wrapper">
      <div
        className={`log-message ${props.shifted && "shifted"}`}
        style={{
          backgroundColor: props.bgColor,
          width: isMobile ? "calc(332px - 40px)" : "",
          padding: isMobile ? "0 20px" : "",
          marginBottom: isMobile ? "40px" : "",
        }}
      >
        <span>
          <strong>{props.text.subject}</strong>
          {props.text.other_text}
          <strong> {props.text.complement}</strong>
        </span>
        {props.islogoVisible && (
          <img
            className="loading-logo"
            src={logo}
            alt="stardust_logo"
            style={{ marginLeft: "10px" }}
          />
        )}
      </div>
    </div>
  );
};
export default Toast;
