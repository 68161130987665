import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useClient from "../../../../api/hooks/useClients";
import useOffer from "../../../../api/hooks/useOffers";
import ClientDetailsHeader from "../../../../components/client/ClientDetailsHeader";
import NavBar from "../../../../components/misc/NavBar";
import ProposalStats from "../../../../components/misc/ProposalStats";
import SideBar from "../../../../components/misc/SideBar";
import ClientCreateModal from "../../../../components/modals/ClientCreateModal";
import DeleteModal from "../../../../components/modals/DeleteModal";
import OfferCreateModal from "../../../../components/modals/OfferCreateModal";
import { paths } from "../../../../consts/URLs";
import { subjects } from "../../../../consts/statuses";
import MainContext from "../../../../context/MainContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import useToggle from "../../../../hooks/useToggle";
import { offerRemappedFromInputs } from "../../../../utils/remapping";
import { transformBudget } from "../../../../utils/others";

const ClientDetailsPage = (props: any) => {
  const { isMobile } = useIsMobile();

  const navigate = useNavigate();
  const menuToggler = useToggle(false);

  const { client, setClient, token } = useContext(MainContext);

  const updateClientHook = useClient(token, subjects.client_update);
  const deleteClientHook = useClient(token, subjects.client_delete);
  const offerCreateHook = useOffer(token, subjects.offer_create);

  /* Modals */
  const editClientModalToggler = useToggle(false);
  const deleteClientModalToggler = useToggle(false);
  const createProposalModalToggler = useToggle(false);

  const anyModalOpen =
    editClientModalToggler.isToggled ||
    deleteClientModalToggler.isToggled ||
    createProposalModalToggler.isToggled;

  /* Forms */
  const deleteClientForm = useForm();
  const editClientForm = useForm();
  const createProposalForm = useForm();

  /*------ UPDATE CLIENT ----------*/
  const onClientUpdateSubmit = (data: any) => {
    if (client) {
      const id = client?.id;
      updateClientHook.CTAs.updateClient(id, data);
    }
  };
  /* ------------------------------- */

  /*------ DELETE CLIENT ----------*/
  const onDeleteClientSubmit = (data: any) => {
    if (client) {
      const id = client.id;
      deleteClientHook.CTAs.deleteClient(id);
    }
  };
  /* ------------------------------- */

  /* ----------------On Update Client Response------------------ */
  useEffect(() => {
    /* console.log(updateClientHook.ApiB.response, updateClientHook.ApiB.result); */
    if (updateClientHook.ApiB.response?.ok) {
      setClient(updateClientHook.ApiB.result?.client);
      editClientModalToggler.setIsToggled(false);
    }
  }, [updateClientHook.ApiB.response]);
  /* ---------------------------------------------------------- */

  /* ----------------On Delete Client Response------------------ */
  useEffect(() => {
    /*  console.log(deleteClientHook.ApiB.response, deleteClientHook.ApiB.result); */
    if (deleteClientHook.ApiB.response?.ok) {
      deleteClientModalToggler.setIsToggled(false);
      setTimeout(() => {
        navigate(paths.pages.clients);
      }, 3000);
    }
  }, [deleteClientHook.ApiB.response]);
  /* ---------------------------------------------------------- */

  /* CREATE PROPOSAL using useOffer/create Api
  useEffect(() => {
     console.log();  
  }, []); */

  /*------ CREATE OFFER ----------*/
  const onProposalCreateSubmit = (data: any) => {
    const remappedObj = offerRemappedFromInputs(data);
    /*  console.log(JSON.stringify(remappedObj),data);   */
    offerCreateHook.CTAs.createOffer(remappedObj);
  };
  /* ----------------------------- */
  /* ----------------On Create Offer Response------------------ */
  useEffect(() => {
    /* console.log(offerCreateHook.ApiB.response, offerCreateHook.ApiB.result); */
    if (offerCreateHook.ApiB.response?.ok) {
      createProposalModalToggler.setIsToggled(false);
      /* setTimeout(() => {
        navigate(paths.pages.clients);
      }, 3000); */
    }
  }, [offerCreateHook.ApiB.response]);
  /* ----------------------------------------------------------- */

  useEffect(() => {
    if (
      client === null &&
      window.location.pathname.includes(paths.pages.clients)
    ) {
      navigate(paths.pages.clients);
    }
  }, []);
 

  return (
    <div className="page-wrapper">
      {updateClientHook.ApiB.isToastVisible && updateClientHook.ApiB.toast}
      {deleteClientHook.ApiB.isToastVisible && deleteClientHook.ApiB.toast}
      {offerCreateHook.ApiB.isToastVisible && offerCreateHook.ApiB.toast}

      <form onSubmit={createProposalForm.handleSubmit(onProposalCreateSubmit)}>
        {createProposalModalToggler.isToggled && (
          <OfferCreateModal
            toggler={createProposalModalToggler}
            register={createProposalForm.register}
            getValues={createProposalForm.getValues}
            setValue={createProposalForm.setValue}
            errors={createProposalForm.formState.errors}
            client={client}
          />
        )}
      </form>
      <form onSubmit={editClientForm.handleSubmit(onClientUpdateSubmit)}>
        {editClientModalToggler.isToggled && (
          <ClientCreateModal
            toggler={editClientModalToggler}
            register={editClientForm.register}
            errors={editClientForm.formState.errors}
            reset={editClientForm.reset}
            onEdit={true}
            client={client}
            deleteToggler={deleteClientModalToggler}
          />
        )}
      </form>
      <form onSubmit={deleteClientForm.handleSubmit(onDeleteClientSubmit)}>
        {deleteClientModalToggler.isToggled && (
          <DeleteModal
            toggler={deleteClientModalToggler}
            register={deleteClientForm.register}
            reset={deleteClientForm.reset}
            client={/* client */ {}}
            deleteType={"client"}
          />
        )}
      </form>

      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "985px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: anyModalOpen && isMobile ? "none" : "",
          }}
        >
          <ClientDetailsHeader
            {...client}
            createProposalModalToggler={createProposalModalToggler}
            editClientModalToggler={editClientModalToggler}
          />

          <div
            className="client-details-card shadow"
            style={{
              width: isMobile ? "345px" : "",
              margin: isMobile ? "28px 15px" : "",
              height: isMobile ? "fit-content" : "",
            }}
          >
            <div
              className="client-details-card-info"
              style={{
                margin: isMobile ? "48px 24px" : "",
              }}
            >
              <p
                className="model-details-card-info-name"
                style={{ fontSize: isMobile ? "32px" : "" }}
              >
                {client?.brand_name}
              </p>
              <div className="client-details-card-info-spec-wrapper">
                <div className="client-details-card-info-spec-box">
                  <p className="client-details-card-info-spec-field">
                    Final client’s Name
                  </p>
                  <p className="client-details-card-info-spec-value">
                    {client?.final_client}
                  </p>
                </div>

                <div
                  className="client-details-card-row "
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "10px" : "",
                  }}
                >
                  <div className="client-details-card-info-spec-box">
                    <p className="client-details-card-info-spec-field">
                      Referent’s Name
                    </p>
                    <p className="client-details-card-info-spec-value">
                      {client?.referent_name}
                    </p>
                  </div>
                </div>
                <div className="client-details-card-info-spec-box">
                  <p className="client-details-card-info-spec-field">E-mail</p>
                  <p className="client-details-card-info-spec-value">
                    {client?.email}
                  </p>
                </div>
                <div
                  className="client-details-card-row"
                  style={{
                    flexDirection: isMobile ? "column" : "row",
                    gap: isMobile ? "10px" : "",
                  }}
                >
                  <div className="client-details-card-info-spec-box">
                    <p className="client-details-card-info-spec-field">
                      Amount spent
                    </p>
                    <p className="client-details-card-info-spec-value">
                      € {transformBudget(Number(client?.amount_spent))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {client && (
            <ProposalStats
              proposal={{
                proposed_times: client?.proposal?.proposed_times
                  ? client.proposal.proposed_times
                  : 0,
                approved_times: client?.proposal?.approved_times
                  ? client.proposal.approved_times
                  : 0,
                success_rate: client?.proposal?.success_rate
                  ? client.proposal.success_rate
                  : 0,
                trend: client?.proposal?.trend ? client.proposal.trend : 0,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ClientDetailsPage;
