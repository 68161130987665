import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import "../../styles/ModelDetails.css";
import smallLeft_icon from "../../assets/ico/small-left.svg";
import lookbook_icon from "../../assets/ico/lookbook.svg";
import composite_icon from "../../assets/ico/composite.svg";
import plus_icon from "../../assets/ico/plus.svg";
import edit_icon from "../../assets/ico/edit.svg";
import { paths } from "../../consts/URLs";
import MainContext from "../../context/MainContext";

const InfluencerDetailsHeader = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const { setInfluencer } = useContext(MainContext);

  const handelClickOnEdit = () => {
    setInfluencer(props.influencer);
    navigate(`${paths.pages.influencers}/${props.influencer.id}/edit`);
  };

  return (
    <div
      className="general-header"
      style={{
        margin: isMobile ? "30px auto 0" : "",
        width: "90%",
      }}
    >
      <div className="Oval" onClick={() => navigate(paths.pages.influencers)}>
        <img src={smallLeft_icon} />
      </div>
      <div
        className="general-header-right"
        style={{
          marginLeft: isMobile ? "21px" : "",
        }}
      >
        <div className="header-right-box1">
          <div>
            <a href={props.influencer?.mediaKit} download target={"_blank"}>
              <div className="Oval">
                <img
                  className={`${
                    props.influencer?.mediaKit ? "offMainColor" : "grey"
                  } `}
                  src={lookbook_icon}
                />
              </div>
            </a>
          </div>
        </div>
        <div className="header-right-box2" style={{ gap: isMobile ? "0" : "" }}>
          <button
            className="button3"
            onClick={props.togglerProposalModal.toggle}
            style={{ width: isMobile ? "58px" : "122px" }}
          >
            {isMobile ? (
              <img className="offLightBlue" src={plus_icon} />
            ) : (
              <p className="button3-text">Add to proposal</p>
            )}
          </button>
          <button
            className="button1"
            onClick={handelClickOnEdit}
            style={{ width: isMobile ? "58px" : "122px" }}
          >
            <img className="white" src={edit_icon} />
            {!isMobile && <p className="button1-text">Edit</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
export default InfluencerDetailsHeader;
