import createSubject from "react-hook-form/dist/utils/createSubject";
import { LogMessage } from "../interfaces";
import { logMsgBgColors } from "./misc";

export const possible_statuses = {
  offer: {
    approved: "approved",
    open: "open",
    closed: "closed",
    draft: "draft",
    substatus: {
      confirmed: "confirmed",
      no_answer: "no_answer",
      rejected: "rejected",
    },
  },
  influencer: {
    pending: "pending",
    approved: "approved",
    rejected: "rejected",
  },
  userStatus: {
    pending: "pending",
    active: "active",
  },
  userType: {
    admin: 1 /* "admin" */,
    normal: 0 /* "normal" */,
  },
  token: {
    expired: "Token is Expired",
    not_found: "Authorization Token not found",
  },
  post_type: {
    image: "image",
    video: "video",
  },
};

export const subjects = {
  register: "Register",
  login: "Login",
  recovery: "Recovery",
  change: "Change",
  reset: "Reset",
  logout: "Logout",
  influencer: "Influencer",
  influencer_update: "Influencer-Update",
  influencer_delete: "Influencer-Delete",
  influencer_create: "Influencer-Create",
  offer: "Offer",
  offer_update: "Offer-Update",
  offer_delete: "Offer-Delete",
  offer_create: "Offer-Create",
  offer_share: "Offer-Share",
  client: "Client",
  client_update: "Client-Update",
  client_delete: "Client-Delete",
  client_create: "Client-Create",
  user: "User",
  user_update: "User-Update",
  user_delete: "User-Delete",
  user_create: "User-Create",
};

export const apiMessages: LogMessage[] = [
  {
    name: subjects.register,
    text: {
      subject: "User ",
      other_text: " registration",
      complement: " success!",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.login,
    text: {
      subject: "Login",
      other_text: " ...",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.recovery,
    text: {
      subject: "",
      other_text: "",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.change,
    text: {
      subject: "",
      other_text: "",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.reset,
    text: {
      subject: "",
      other_text: "",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.logout,
    text: {
      subject: "",
      other_text: "",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.influencer_update,
    text: {
      subject: "Influencer",
      other_text: " modified!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.influencer_delete,
    text: {
      subject: "Influencer",
      other_text: " has been deleted!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.influencer_create,
    text: {
      subject: "Influencer",
      other_text: " has been created!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.offer_update,
    text: {
      subject: "Offer",
      other_text: " modified!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.offer_delete,
    text: {
      subject: "Offer",
      other_text: " has been deleted!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.offer_create,
    text: {
      subject: "Offer",
      other_text: " has been created!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.offer_share,
    text: {
      subject: "Offer",
      other_text: " has been shared!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.client_update,
    text: {
      subject: "Client",
      other_text: " modified!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.client_delete,
    text: {
      subject: "Client",
      other_text: " has been deleted!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.client_create,
    text: {
      subject: "Client",
      other_text: " has been created!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.user_update,
    text: {
      subject: "User",
      other_text: " modified!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.user_delete,
    text: {
      subject: "User",
      other_text: " has been deleted!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
  {
    name: subjects.user_create,
    text: {
      subject: "User",
      other_text: " has been created!",
      complement: "",
    },
    bgColor: logMsgBgColors.correct,
  },
];
