import instagram from "../assets/illustration/instagram.svg";
import tiktok from "../assets/illustration/tiktok.svg";
import facebook from "../assets/illustration/facebook.svg";
import linkedin from "../assets/illustration/linkedin.svg";
import twitch from "../assets/illustration/twitch.svg";
import youtube from "../assets/illustration/youtube.svg";
import twitter from "../assets/illustration/twitter.svg";
import influencers from "../assets/news/influencers.jpg";
import offers from "../assets/news/offers.jpg";
import clients from "../assets/news/clients.jpg";

export const toAssets = "/assets";
export const toIcons = "/assets/ico";
export const toIllustrations = "/assets/illustration";

export const paths = {
  pages: {
    login: "/",
    first_access: "/firstaccess",
    recovery_password: `/recoverypassword`,
    reset_password: `/resetpassword`,
    verify: "/verify",
    overview: "/overview",
    influencers: "/influencers",
    influencer_detail: "/influencers/:id",
    influencer_edit: "/influencers/:id/edit",
    influencer_create: "/influencers/create",
    offers: `/offers`,
    offer_detail: "/offers/:id",
    offer_share: "/offers/share/:id",
    clients: `/clients`,
    client_detail: `/clients/:id`,
    settings: `/settings`,
    user_detail: `/settings/user/:id`,
  },
  social_icons: [
    { name: "instagram", path: instagram },
    { name: "tiktok", path: tiktok },
    { name: "facebook", path: facebook },
    { name: "linkedin", path: linkedin },
    { name: "twitch", path: twitch },
    { name: "youtube", path: youtube },
    { name: "twitter", path: twitter },
  ],
  news_images: {
    influencers: influencers,
    offers: offers,
    clients: clients,
  },
};

export const apiURL =
  /* "http://127.0.0.1:8000/api"; */ "https://www.api.stardustrecords.it/api";

export const appHostURL = window.location.host;
