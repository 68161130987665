export const validatePassword = (
  password: string,
  confirmedPassword: string
) => {
  let regExpWeak = /[a-z]/;
  let regExpMedium = /\d+/;
  let regExpStrong = /.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/;
  let inputWeek = password.match(regExpWeak);
  let inputMedium = password.match(regExpMedium);
  let inputStrong = password.match(regExpStrong);

  const condition1 = password === confirmedPassword;
  const condition2 = password.length >= 6;

  if (condition1) {
    if (condition2) {
      if (inputWeek && inputMedium && inputStrong) {
        return {
          isValid: true,
          securityLevel: "strong",
          msg: "Password Strong.",
        };
      }
      if (
        (inputWeek && inputMedium) ||
        (inputMedium && inputStrong) ||
        (inputWeek && inputStrong)
      ) {
        return {
          isValid: true,
          securityLevel: "medium",
          msg: "Password is Valid.",
        };
      }
      if (inputWeek || inputMedium || inputStrong) {
        return {
          isValid: false,
          securityLevel: "weak",
          msg: "Password must contains at least one letter and one number.",
        };
      }
    } else {
      return {
        isValid: false,
        securityLevel: "not valid",
        msg: "Password length must be minimum 6 character",
      };
    }
  } else {
    return {
      isValid: false,
      securityLevel: "not valid",
      msg: "Passwords must match",
    };
  }
};

export const validateEmail = (email: string) => {
  let validation = { isValid: false, message: "" };

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    validation.message = "Valid email address!";
    validation.isValid = true;
  } else {
    validation.message = "Please provide a properly formatted e-mail address";
    validation.isValid = false;
  }

  return validation;
};
