import { useEffect } from "react";

const SelectInput = (props: any) => {
  const renderedOptions = props.options?.map((option: any, key: number) => {
    const isSelected = props.idValue && props.defaultValue === option.id;
    if(isSelected){
      
    }
    return (
      <option
        key={key}
        value={props.idValue ? option.id : option.brand_name}
        selected={isSelected}
      >
        {option.brand_name}
      </option>
    );
  });

  return (
    <div className={`customInputText-box`} style={{ width: `${props.width}` }}>
      <p className={`customInputText-title`}>{props.title}</p>
      <select
        className={`customInputText-input ${
          props.errors[props.name] && "onError"
        }`}
        /* style={{ width: " 100% " }} */
        {...props.register(props.name, props.constraints)}
        defaultValue={props.defaultValue}
      >
        {!props.idValue && <option value=""></option>}
        {renderedOptions}
      </select>
    </div>
  );
};
export default SelectInput;
