import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import useOffer from "../../api/hooks/useOffers";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";
/* import { offerRemappingFromResult } from "../../utils/offerRemapping"; */
/* import ModelsConfirmTag from "../selector/ModelsConfirmTag"; */
/* import OfferCloseModalsOptions from "./OfferCloseModalsOptions"; */
import close_icon from "../../assets/ico/close.svg";
import smallLeft_icon from "../../assets/ico/small-left.svg";
import congrats_icon from "../../assets/illustration/congrats.svg";
import OfferCloseModalsOptions from "./OfferCloseModalsOptions";
import MainContext from "../../context/MainContext";
import InfluencerConfirmTag from "../selector/InfluencerConfirmTag";
import { influencersMoc } from "../../consts/moc";
import { possible_statuses, subjects } from "../../consts/statuses";
import { offerRemappingFromResult } from "../../utils/remapping";

const OfferCloseModal = (props: any) => {
  const { isMobile } = useIsMobile();
  const closeOfferForm = useForm();
  const responseIsOk = useToggle(false);

  const { offer, setOffer, influencerSelector, token } =
    useContext(MainContext);

  const updateOfferHook = props.updateOfferHook;
  const updateOfferInfluencersHook = props.updateOfferInfluencersHook;

  const [influencerChosenList, setModelChosenList] = useState<number[]>([]);

  const [modalDescription, setModalDescription] = useState("");

  const [offerStatus, setOfferStatus] = useState<string>("");

  const congrats = useToggle(false);
  const goBack = useToggle(false);
  const confirmed = useToggle(false);

  const togglers = { confirmed, goBack };

  useEffect(() => {
    getModalDescription();
  });

  useEffect(() => {
    closeOfferForm.reset({ status: offerStatus });
  }, [offerStatus]);

  useEffect(() => {
    if (confirmed.isToggled) {
      setOfferStatus(possible_statuses.offer.substatus.confirmed);
    }
  }, [confirmed.isToggled]);

  useEffect(() => {
    if (responseIsOk.isToggled) {
      congrats.toggle();
      confirmed.setIsToggled(false);
      goBack.setIsToggled(true);
    }
  }, [responseIsOk.isToggled]);

  const onCloseOfferSubmit = (data: any) => {
    let finalObj = { ...data };
    const offerId = offer?.id;
    if (confirmed.isToggled && influencerChosenList) {
      offer?.proposed_list?.forEach((item) => {
        const isPresent = influencerChosenList.filter(
          (id) => id === item.talent_id
        )[0]
          ? true
          : false;
        if (isPresent) {
          updateOfferInfluencersHook.CTAs.updateOfferInfluencers(offerId, {
            proposed_talents: [
              {
                talent_id: item.talent_id,
                status: possible_statuses.influencer.approved,
              },
            ],
          });
        } else if (!isPresent) {
          updateOfferInfluencersHook.CTAs.updateOfferInfluencers(offerId, {
            proposed_talents: [
              {
                talent_id: item.talent_id,
                status: possible_statuses.influencer.rejected,
              },
            ],
          });
        }
      });
    }
    updateOfferHook.CTAs.updateOffer(offerId!, finalObj);
  };

  useEffect(() => {
    /* console.log(
      updateOfferInfluencersHook.ApiB.response,
      updateOfferInfluencersHook.ApiB.result
    ); */
    if (updateOfferInfluencersHook.ApiB.response?.ok) {
    }
  }, [updateOfferInfluencersHook.ApiB.response]);

  useEffect(() => {
    /* console.log(updateOfferHook.ApiB.response, updateOfferHook.ApiB.result); */
    if (updateOfferHook.ApiB.response?.ok) {
      const tOffer = offerRemappingFromResult(
        updateOfferHook.ApiB.result.offer
      );
      setOffer(tOffer);
      responseIsOk.toggle();
    }
  }, [updateOfferHook.ApiB.response]);

  const handleAddInfluencer = (influencerTreatedId: number) => {
    setModelChosenList((prevList) => {
      const finalList: number[] = [];
      prevList.forEach((id) => {
        finalList.push(id);
      });
      finalList.push(influencerTreatedId);
      return finalList;
    });
  };

  const handleRemoveInfluencer = (influencerTreatedId: number) => {
    setModelChosenList((prevList) => {
      const finalList: number[] = [];
      prevList.forEach((id) => {
        if (id === influencerTreatedId) {
        } else {
          finalList.push(id);
        }
      });
      return finalList;
    });
  };

  const influencersList = influencerSelector.influencerList.map(
    (influencer, key) => {
      return (
        <InfluencerConfirmTag
          key={key}
          influencer={influencer}
          addModel={handleAddInfluencer}
          removeModel={handleRemoveInfluencer}
          chosenList={influencerChosenList}
        />
      );
    }
  );

  const handleGoBack = () => {
    if (confirmed.isToggled) {
      confirmed.toggle();
      goBack.toggle();
    } else {
      props.closeOfferModalToggler.toggle();
    }
  };

  const getModalDescription = () => {
    if (confirmed.isToggled) {
      setModalDescription(
        `${influencersList.length} of ${props.offer.requested_influencers} influencers were proposed. Select confirmed influencers`
      );
    } else if (congrats.isToggled) {
      setModalDescription("");
    } else setModalDescription("Select an option to close the offer");
  };

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        className={`offer-close-modal svroll-y ${
          isMobile && "general-modal-dimension-Mob"
        }`}
        style={{
          width: isMobile ? "345px" : "",
          height: isMobile ? "544px" : "" /* 
          left: isMobile ? "2.25vh" : "",
          top: isMobile ? "20.25vh" : "", */,
          paddingBottom: isMobile ? "55px" : "",
        }}
      >
        <form onSubmit={closeOfferForm.handleSubmit(onCloseOfferSubmit)}>
          <input
            style={{ display: "none" }}
            type="text"
            {...closeOfferForm.register("status")}
            value={offerStatus}
          />
          <div className="offer-close-modal-content">
            <div className="modal-header">
              <div>
                <p className="modal-title">Close offer</p>
                <p
                  className="modal-description"
                  style={{
                    width: isMobile ? "180px" : "",
                  }}
                >
                  {modalDescription}
                </p>
              </div>
              <div className="Oval" onClick={handleGoBack}>
                <img
                  src={
                    confirmed.isToggled && !congrats.isToggled
                      ? smallLeft_icon
                      : close_icon
                  }
                />
              </div>
            </div>
            <div
              className={`offer-close-modal-inner scrollable-element ${
                confirmed.isToggled && "scroll-y"
              }`}
              style={{
                height: isMobile ? "auto" : "",
                marginTop: isMobile ? "22px" : "",
              }}
            >
              {!goBack.isToggled && (
                <OfferCloseModalsOptions
                  togglers={togglers}
                  offerStatus={offerStatus}
                  setOfferStatus={setOfferStatus}
                />
              )}
              {confirmed.isToggled && goBack.isToggled && (
                <div
                  className={`scrollable-element`}
                  style={{
                    height: isMobile ? "350px" : "",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  {influencersList}
                </div>
              )}
              {congrats.isToggled && (
                <div className="congrats-box">
                  <div
                    className="congrats-image"
                    style={{
                      backgroundImage: `url(${congrats_icon})`,
                    }}
                  ></div>
                  <p className="congrats-title">Congrats</p>
                  <p className="congrats-description">
                    The offer has been confirmed. Breathe! Now you are free to
                    take a break
                  </p>
                </div>
              )}
            </div>
            {!congrats.isToggled && (
              <button
                className="offer-close-modal-confirm"
                type="submit"
                disabled={
                  offerStatus === "" ||
                  (influencerChosenList.length !==
                    offer!.requested_influencers! &&
                    confirmed.isToggled)
                }
                style={{
                  margin: isMobile ? "5px 0 0 0" : "",
                  width: isMobile ? "110%" : "",
                }}
              >
                <p className="offer-close-modal-confirm-text">Confirm</p>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfferCloseModal;
