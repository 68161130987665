import { useContext } from "react";
import { useNavigate } from "react-router-dom";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import smallLeft_icon from "../../assets/ico/small-left.svg";
import more_icon from "../../assets/ico/more.svg";
import send_icon from "../../assets/ico/send.svg";
import edit_icon from "../../assets/ico/edit.svg";
import MainContext from "../../context/MainContext";

const OfferCreateHeader = (props: any) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const { setOffer } = useContext(MainContext);

  const handleGoBack = () => {
    setOffer(null);
    navigate(`/offers`);
  };

  return (
    <div
      className="general-header"
      style={{
        marginTop: isMobile ? "30px" : "",
        width: isMobile ? "345px" : "",
      }}
    >
      <div className="Oval" onClick={handleGoBack}>
        <img src={smallLeft_icon} />
      </div>
      <div className="general-header-right">
        <div className="header-right-box1">
          {/* <div className="Oval">
            <img className="offMainColor" src={more_icon} />
          </div> */}
          <div>
            <div className="Oval" onClick={props.shareToggler.toggle}>
              <img
                className={`offMainColor`}
                src={send_icon}
                style={{ opacity: "0.37" }}
              />
            </div>
          </div>
        </div>
        <div className="header-right-box2" style={{ marginLeft: "23px" }}>
          <button
            className="button1"
            type="button"
            style={{ width: isMobile ? "58px" : "122px" }}
            disabled={props.readOnly}
            onClick={props.editToggler.toggle}
          >
            <img className="white" src={edit_icon} />
            {!isMobile && <p className="button1-text">Edit</p>}
          </button>
        </div>
      </div>
    </div>
  );
};
export default OfferCreateHeader;
