import Footer from "../../../components/Footer";
import { authInputs } from "../../../consts/inputs";
import { getInputs } from "../../../utils/getMethods";
import "../../../styles/Access.css";
import "../../../styles/General.css";
import FeedMessage from "../../../components/misc/FeedMessage";
import useToggle from "../../../hooks/useToggle";
import CustomInput from "../../../components/inputs/CustomInput";
import { useForm } from "react-hook-form";
import useIsMobile from "../../../hooks/useIsMobile";
import useResetPassword from "../../../api/hooks/password/useResetPassword";
import { useEffect, useState } from "react";
import { validatePassword } from "../../../utils/validations";
import useLogMessage from "../../../hooks/useLogMessage";
import { logMsgBgColors } from "../../../consts/misc";
import LogMessage from "../../../components/misc/Toast";
import logo_main from "../../../assets/logo_main.png";
import congrats_illustration from "../../../assets/illustration/congrats.svg";
import { paths } from "../../../consts/URLs";

const ResetPasswordPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const { resetPassword, ApiB } = useResetPassword();

  const resetForm = useForm();

  const [tempToken, setTempToken] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tToken = url.searchParams.get("token");
    const tEmail = url.searchParams.get("email");

    if (tToken) {
      setTempToken(tToken);
      setEmail(tEmail);
    } else if (!tToken && window.location.pathname === paths.pages.reset_password) {
      window.location.href = window.location.origin + "/";
    }
  }, []);

  const onSubmit = (data: any) => { 
    const objToSend = {
      email: email,
      token: tempToken,
      password: data.new_password,
      password_confirmation: data.confirmed_new_password,
    };
    const validation = validatePassword(
      data.new_password,
      data.confirmed_new_password
    );
    const validationMsg = validation?.msg;
    const isPasswordValid = validation?.isValid;
    if (isPasswordValid) {
      resetPassword(objToSend);
    } else {
      ApiB.write({
        text: {
          subject: validationMsg!,
          other_text: "",
          complement: "",
        },
        bgColor: logMsgBgColors.error,
      });
    }
  };
/* 
  useEffect(() => {
    console.log(ApiB.response);
  }, [ApiB.response]); */

  const chosenInputs = getInputs(authInputs, [
    "new_password",
    "confirmed_new_password",
  ]);
  const renderInputs = chosenInputs.map((input, key) => {
    return (
      <CustomInput
        key={key}
        width={isMobile ? 305 : 460}
        {...input}
        register={resetForm.register}
        errors={resetForm.formState.errors}
      />
    );
  });

  return (
    <div className="access-background">
      {ApiB.isToastVisible && ApiB.toast}
      <img className="logo-main" src={logo_main} />
      {ApiB.response?.ok ? (
        <FeedMessage
          imageBgClassName="mailImage-background"
          imageSrc={congrats_illustration}
          title="E-mail sent"
          description={`You have successfully reset your password`}
          link={{ path: "/", text: "Return to Log In" }}
        />
      ) : (
        <div className={`access-content-box ${isMobile && "a-c-b-Mob"}`}>
          <div className="access-content-box-inner-wrapper">
            <form onSubmit={resetForm.handleSubmit(onSubmit)}>
              <div className="access-contentBox-header">
                <div className="cornerTitle">
                  {"Reset password"}
                  <p className="cornerSubTitle">Enter the new password</p>
                </div>
              </div>
              <div
                className="input-positioner"
                style={{ gap: "32px", marginTop: "26px" }}
              >
                {renderInputs}
              </div>
              <div
                className="contentBox-confirmationBox"
                style={{ marginTop: "52px" }}
              >
                <span></span>
                <button className="button1 b-short" type="submit">
                  <p className="button1-text">{"Reset"}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};
export default ResetPasswordPage;
