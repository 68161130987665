import { logMsgBgColors } from "../consts/misc";
import { apiMessages } from "../consts/statuses";
import { Influencer } from "../interfaces";

export const backToTopOfPage = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const removeFromFilesArray = (index: number, obj: any, array: any[]) => {
  const finalArray: typeof array = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i]; /* 
    const c1 = obj.name === element.name;
    const c2 = obj.size === element.size;
    const c3 = obj.type === element.type; */
    const c4 = index === i;
    /* console.log( new File([obj], "name"),element,index,i ) */
    if (c4) {
      /* console.log("match") */
    } else {
      finalArray.push(element);
    }
  }
  return finalArray;
};

export const waitTo = (milliseconds: number, action: () => void) => {
  setTimeout(() => {
    action();
  }, milliseconds);
};

export const getApiMessage = (subject: string) => {
  const result = apiMessages.filter((o) => o.name === subject)[0];
  return result;
};

const bgColors = [
  "rgba(103, 136, 161, 0.2)",
  "rgba(110, 179, 232, 0.2)",
  "rgba(120, 23, 234, 0.2)",
  "rgba(247, 86, 124, 0.2)",
  "rgba(18, 125, 82, 0.2)",
  "rgba(109, 193, 162, 0.2)",
  "rgba(199, 234, 23, 0.2)",
  "rgba(248, 155, 255, 0.2)",
];

export const getRandomBgColor = () => {
  const picker = Math.floor(Math.random() * (bgColors.length - 0 + 1));
  return bgColors[picker];
};

export const getBase64 = async (file: File) => {
  let b64 = "";
  if (file) {
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }).then((data) => {
      const s: any = data;
      b64 = s; /* .replace("data:", "").replace(/^.+,/, ""); */
    });
  }
  return b64;
};

export function fnBrowserDetect() {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }
  return browserName;
}

export const transformBudget = (value: number) => {
  const [whole, dec] = value.toFixed(2).split(".");

  const res = whole
    .split("")
    .reverse()
    .reduce(
      (acc, cur) => {
        if (acc[0].length === 3) {
          acc.unshift("");
        }
        acc[0] = cur + acc[0];
        return acc;
      },
      [""]
    )
    .join(".")
    .concat("," + dec);

  return res;
};

export function nFormatter(num: number, digits: number) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: " K" },
    { value: 1e6, symbol: " MLN" },
    { value: 1e9, symbol: " G" },
    { value: 1e12, symbol: " T" },
    { value: 1e15, symbol: " P" },
    { value: 1e18, symbol: " E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export const socialRemoveAt = (url: string, char: string) => {
  if (url.includes(char)) {
    const s = url.split(char);
    return s[0] + s[1];
  } else return url;
};

export const usernameRemoveAt = (url: string, char: string) => {
  if (url.includes(char)) {
    const s = url.replace(char, "");
    return s.trim();
  } else return url;
};

export const sortByParamStringAscending = (list: Influencer[]) => {
  return list.sort(function (a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
};
