import overview_icon from "../assets/ico/overview.svg";
import influencer_icon from "../assets/ico/influencer.svg";
import offers_icon from "../assets/ico/offers.svg";
import clients_icon from "../assets/ico/clients.svg";
import settings_icon from "../assets/ico/settings.svg";
import { paths, toIcons } from "./URLs";
import { Category, PageInterface } from "../interfaces";
import { theme } from "./palette";

/* import { Category, HourMinute } from "../interfaces";



export const modelMeasures = [
  { name: "height", displayName: "Height" },
  { name: "bust", displayName: "Bust" },
  { name: "waist", displayName: "Waist" },
  { name: "hips", displayName: "Hips" },
  { name: "shoes", displayName: "Shoes" },
  { name: "eyes", displayName: "Eyes" },
  { name: "hair", displayName: "Hair" },
];

export const days_of_the_week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
*/
export const month_names = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
/*
export const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const hours_minutes = () => {
  const finalArray: HourMinute[] = [];
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m++) {
      const d = new Date();
      d.setHours(h);
      d.setMinutes(m);
      const hour_minutes: HourMinute = { hour: h, minute: m, date: d };
      if (m === 0 || m % 10 === 0) {
        finalArray.push(hour_minutes);
      }
    }
  }
  return finalArray;
};

export const roundToNearestMinute = (date: Date, minute: number) => {
  const minutes = minute;
  const ms = 1000 * 60 * minutes;

  return new Date(Math.round(date.getTime() / ms) * ms);
}; */

export const logMsgBgColors = {
  correct: "#62ffb1",
  error: "#f88",
  loading: theme.secondary_bg_color,
};

export const pages: PageInterface[] = [
  {
    name: "overview",
    pathName: paths.pages.overview,
    displayName: "Overview",
    iconPath: overview_icon,
  },
  {
    name: "influencers",
    pathName: paths.pages.influencers,
    displayName: "Influencers",
    iconPath: influencer_icon,
    subsection: [
      {
        name: "influencer_details",
        pathName: paths.pages.influencer_detail,
        displayName: "Talent Details",
      },
      {
        name: "edit_influencer",
        pathName: paths.pages.influencer_edit,
        displayName: "Edit Talents",
      },
      {
        name: "create_influencer",
        pathName: paths.pages.influencer_create,
        displayName: "Create Talent",
      },
    ],
  },
  {
    name: "offers",
    pathName: "/offers",
    displayName: "Offers",
    iconPath: offers_icon,
    subsection: [
      {
        name: "offer_details",
        pathName: "/offers/:id",
        displayName: "Offer Details",
      },
      {
        name: "share_offer",
        pathName: "/offers/share/:id",
        displayName: "Offer Share",
      },
    ],
  },
  {
    name: "clients",
    pathName: "/clients",
    displayName: "Clients",
    iconPath: clients_icon,
    subsection: [
      {
        name: "client_details",
        pathName: "/clients/:id",
        displayName: "Client Details",
      },
    ],
  },
  {
    name: "settings",
    pathName: "/settings",
    displayName: "Settings",
    iconPath: settings_icon,
    subsection: [
      {
        name: "user_details",
        pathName: "/settings/user/:id",
        displayName: "User Details",
      },
    ],
  },
];
