import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useOffer from "../../../../api/hooks/useOffers";
import useSoacialPost from "../../../../api/hooks/useSoacialPost";
import Contacts from "../../../../components/influencers/Contacts";
import InfluencerDetailsCard from "../../../../components/influencers/InfluencerDetailsCard";
import InfluencerDetailsHeader from "../../../../components/influencers/InfluencerDetailsHeader";
import InfluencerSpecsBar from "../../../../components/influencers/InfluencerSpecsBar";
import NavBar from "../../../../components/misc/NavBar";
import PostsController from "../../../../components/misc/PostsController";
import ProposalStats from "../../../../components/misc/ProposalStats";
import SideBar from "../../../../components/misc/SideBar";
import ProposalModal from "../../../../components/modals/ProposalModal";
import { paths } from "../../../../consts/URLs";
import { possible_statuses, subjects } from "../../../../consts/statuses";
import MainContext from "../../../../context/MainContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import useToggle from "../../../../hooks/useToggle";
import "../../../../styles/Content.css";
import "../../../../styles/Models.css";
import { backToTopOfPage } from "../../../../utils/others";
import { offerRemappingFromResult } from "../../../../utils/remapping";

const TalentDetailPage = (props: any) => {
  const { isMobile } = useIsMobile();

  const navigate = useNavigate();

  const { token, influencer, setRefresh, setOffer } = useContext(MainContext);
  const useOfferInfluencerHookCreate = useOffer(token, subjects.offer_create);
  const useOfferInfluencerHookUpdate = useOffer(token, subjects.offer_update);
  const useSocialPostsHook = useSoacialPost(token);

  const [socialPosts, setSocialPosts] = useState<any | null>(
    null /* moc_posts_res.data.posts.slice(0, 6) */
  );
  const [socialPostsVisible, setSocialPostsVisible] = useState(
    false /* true */
  );

  useEffect(() => {
    if (influencer && influencer.social_infos.length > 0) {
      const instragramSocial = influencer.social_infos.filter(
        (el) => el.social_name === "instagram"
      )[0];
      if (instragramSocial && instragramSocial.id) {
        useSocialPostsHook.getSocialPosts(influencer.id, instragramSocial.id);
      }
    }
  }, [influencer]);

  useEffect(() => {
    /* console.log(
      useSocialPostsHook.ApiB.response,
      useSocialPostsHook.ApiB.result
    ); */
    if (useSocialPostsHook.ApiB.response?.ok) {
      setSocialPostsVisible(true);
      if (useSocialPostsHook.ApiB.result.data.posts.length > 6) {
        setSocialPosts(useSocialPostsHook.ApiB.result.data.posts.slice(0, 6));
      } else if (
        useSocialPostsHook.ApiB.result.data.posts.length <= 6 &&
        useSocialPostsHook.ApiB.result.data.posts.length > 0
      ) {
        setSocialPosts(useSocialPostsHook.ApiB.result.data.posts);
      }
    } else {
      setSocialPostsVisible(false);
    }
  }, [useSocialPostsHook.ApiB.response]);

  /* togglers */
  const menuToggler = useToggle(false);
  const togglerProposalModal = useToggle(false);
  const locationModalToggler = useToggle(false);
  const eventModalToggler = useToggle(false);
  const deleteModalToggler = useToggle(false);

  const anyModalOpen =
    togglerProposalModal.isToggled ||
    locationModalToggler.isToggled ||
    eventModalToggler.isToggled ||
    deleteModalToggler.isToggled;

  const onDeleteModelSubmit = (data: any) => {
    /*    if (deleteType === "event" && eventSelected) {
      eventHook.CTAs.deleteEvents(data.id);
    } else if (deleteType === "location" && locationSelected) {
      locationHook.CTAs.deleteLocation(data.id);
    } */
  };

  /*   useEffect(() => {
    if (eventModalToggler.isToggled) {
      backToTopOfPage();
    }
  }, [eventModalToggler]); */

  useEffect(() => {
    if (
      influencer === null &&
      window.location.pathname.includes(paths.pages.influencers)
    ) {
      navigate(paths.pages.influencers);
    } else backToTopOfPage();
  }, []);

  useEffect(() => {
    /* console.log(
      useOfferInfluencerHookUpdate.ApiB.response,
      useOfferInfluencerHookUpdate.ApiB.result
    ); */
    if (useOfferInfluencerHookUpdate.ApiB.response?.ok) {
      const remappedOffer = offerRemappingFromResult(
        useOfferInfluencerHookUpdate.ApiB.result.talent
      );
      setOffer(remappedOffer);
      setTimeout(() => {
        togglerProposalModal.toggle();
      }, 3000);
    }
  }, [useOfferInfluencerHookUpdate.ApiB.response]);

  useEffect(() => {
    /* console.log(
      useOfferInfluencerHookCreate.ApiB.response,
      useOfferInfluencerHookCreate.ApiB.result
    ); */
    if (
      useOfferInfluencerHookCreate.ApiB.response?.ok &&
      useOfferInfluencerHookCreate.ApiB.result?.talent
    ) {
      const remappedOffer = offerRemappingFromResult(
        useOfferInfluencerHookCreate.ApiB.result.talent
      );
      setOffer(remappedOffer);
      setTimeout(() => {
        togglerProposalModal.toggle();
      }, 3000);
    }
  }, [useOfferInfluencerHookCreate.ApiB.response]);

  /* ----------------------------------------- */

 
  const renderPosts = socialPosts?.map((post: any, key: number) => {
    if (post.video_versions && post.video_versions[0].url) {
      let url = post.video_versions[0].url;

      /* const finalUrl = url.replaceAll("&", "%26"); */

      return (
        <PostsController
          key={key}
          type={possible_statuses.post_type.video}
          url={url}
        />
      );
    } else if (
      post.carousel_media &&
      post.carousel_media[0].image_versions2.candidates &&
      post.carousel_media[0].image_versions2.candidates[0]
    ) {
      let url: string =
        post.carousel_media[0].image_versions2.candidates[0].url;
      /* const finalUrl = url.replaceAll("&", "%26"); */
      return (
        <PostsController
          key={key}
          type={possible_statuses.post_type.image}
          url={url}
        />
      );
    }
  });

  /* ----------------------------------------- */

  return (
    <div className={`page-wrapper`}>
      {useOfferInfluencerHookCreate.ApiB.isToastVisible &&
        useOfferInfluencerHookCreate.ApiB.toast}
      {useOfferInfluencerHookUpdate.ApiB.isToastVisible &&
        useOfferInfluencerHookUpdate.ApiB.toast}

      {togglerProposalModal.isToggled && (
        <ProposalModal
          togglerProposalModal={togglerProposalModal}
          influencer={influencer}
          createHook={useOfferInfluencerHookCreate}
          updateHook={useOfferInfluencerHookUpdate}
        />
      )}
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}

      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "1150px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display: anyModalOpen && isMobile ? "none" : "",
          }}
        >
          {
            <InfluencerDetailsHeader
              togglerProposalModal={togglerProposalModal}
              influencer={influencer}
            />
          }
          <div
            className="model-info-upper-container"
            style={{
              flexDirection: isMobile ? "column" : "row",
              width: isMobile ? "345px" : "",
              justifyContent: isMobile ? "center" : "",
              margin: isMobile ? "0 auto 30px" : "",
            }}
          >
            <div>
              <InfluencerDetailsCard influencer={influencer} />
              <InfluencerSpecsBar influencer={influencer} />
            </div>

            <Contacts influencer={influencer} />
          </div>

          {
            <div
              className="social-post-preview-container shadow"
              style={{
                width: isMobile ? "325px" : "",
                display: isMobile ? "flex" : socialPostsVisible ? "" : "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: isMobile
                  ? "center"
                  : socialPostsVisible
                  ? ""
                  : "center",
                alignItems: isMobile
                  ? "center"
                  : socialPostsVisible
                  ? ""
                  : "center",
                padding: isMobile ? "20px 10px" : "",
              }}
            >
              {socialPostsVisible ? (
                renderPosts
              ) : (
                <h2 className="no-post">NO POSTS AVILABLE</h2>
              )}
            </div>
          }
          <ProposalStats
            proposal={{
              proposed_times: influencer?.proposal?.proposed_times
                ? influencer.proposal.proposed_times
                : 0,
              approved_times: influencer?.proposal?.approved_times
                ? influencer.proposal.approved_times
                : 0,
              success_rate: influencer?.proposal?.success_rate
                ? influencer.proposal.success_rate
                : 0,
              trend: influencer?.proposal?.trend
                ? influencer.proposal.trend
                : 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default TalentDetailPage;
