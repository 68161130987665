import { useState } from "react";
import { apiURL } from "../../consts/URLs";
import { INFLUENCERS, POSTS, SOCIAL } from "../endpointList";
import { fetchRequest, getOptions } from "../utils";
import useApiBuilder from "./useApiBuilder";

const useSocials = (token?: string | null) => {
  const ApiB = useApiBuilder();

  const createSocial = (influencerId: number, obj: any) => {
    const url = `${apiURL}/${INFLUENCERS}/${influencerId}/${SOCIAL}`;
    const options = getOptions({
      method: "POST",
      body: {
        ...obj,
      },
      token: token,
    });
    ApiB.call(url, options);
  };

  const updateSocial = (influencerId: number, socialId: number, obj: any) => {
    const url = `${apiURL}/${INFLUENCERS}/${influencerId}/${SOCIAL}/${socialId}`;
    const options = getOptions({
      method: "PUT",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const deleteSocial = (influencerId: number, socialId: number) => {
    const url = `${apiURL}/${INFLUENCERS}/${influencerId}/${SOCIAL}/${socialId}`;
    const options = getOptions({ method: "DELETE", token: token });
    ApiB.call(url, options);
  };
 

  const CTAs = {
    createSocial,
    updateSocial,
    deleteSocial,
  };

  return { CTAs, ApiB };
};
export default useSocials;
