import { paths } from "../../consts/URLs";
import useIsMobile from "../../hooks/useIsMobile";
import { nFormatter } from "../../utils/others";

const Contacts = (props: any) => {
  const { isMobile } = useIsMobile();
  return (
    <div
      className="contacts"
      style={{
        width: isMobile ? "345px" : "",
        margin: isMobile ? "0" : "",
        height: isMobile ? "fit-content" : "",
        justifyContent: isMobile ? "center" : "",
        alignItems: isMobile ? "center" : "",
      }}
    >
      <div
        className="contacts-wrapper"
        style={{
          padding: isMobile ? "20px 0" : "",
        }}
      >
        <p className="contacts-title">Contacts</p>
        <div className="info-spec">
          <p className="info-spec-field">E-mail</p>
          <p className="info-spec-value">{props.influencer?.email}</p>
        </div>
        <div className="info-spec" style={{ gap: "10px" }}>
          <p className="info-spec-field">Social</p>
          {props.influencer?.social_infos.map(
            (social: /* Social */ any, key: number) => {
              const chosen_icon = paths.social_icons.filter(
                (icon) => icon.name === social.social_name.toLowerCase()
              )[0]?.path;
              return (
                <div className="info-social-box" key={key}>
                  <span className="info-social-link-box">
                    <div>
                      <img className="info-social-img" src={chosen_icon} />
                    </div>
                    <p
                      className="info-social-profileLink"
                      onClick={() => {
                        window.open(social.link, "blank");
                      }}
                    >
                      {social.username}
                    </p>
                  </span>
                  <p className="info-social-followers">
                    {nFormatter(social.followers, 2)}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
export default Contacts;
