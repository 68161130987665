import { CDN_URL } from "../../api/endpointList";
import { apiURL } from "../../consts/URLs";
import { possible_statuses } from "../../consts/statuses";
import useIsMobile from "../../hooks/useIsMobile";
import CustomVideo from "./CustomVideo";
import { useState, useEffect } from "react";
import logo from "../../assets/logo_only_image.png";

const PostsController = (props: any) => {
  const { type, url } = props;
  const isMobile = useIsMobile();

  const [link, setLink] = useState("");
  useEffect(() => {
    if (url && type) {
      const s = url.split("?");
      fetch(`${apiURL}/${CDN_URL}?${s[1]}&url=${s[0]} `)
        .then((res) => { 
          return res.json();
        })
        .then((data) => {
          const url = data.url;
          setLink(url);
        });
    }
  }, [url]);

  if (type === possible_statuses.post_type.image && link) {
    return (
      <div
        className="post-preview-card shadow"
        style={{
          height: isMobile ? "400px" : "",
          width: isMobile ? "90%" : "",
        }}
      >
        {
          <div
            className="post-preview-content "
            style={{
              width: "85%",
              height: "90%",
              backgroundImage: `url(${link})`,
              backgroundPosition: `center`,
              backgroundSize: "cover",
            }}
          />
        }
      </div>
    );
  } else if (type === possible_statuses.post_type.video && link) {
    return (
      <div
        className="post-preview-card shadow"
        style={{
          height: isMobile ? "400px" : "",
          width: isMobile ? "90%" : "",
        }}
      >
        <CustomVideo src={link} />
      </div>
    );
  } else
    return (
      <div
        className="post-preview-card shadow"
        style={{
          height: isMobile ? "400px" : "",
          width: isMobile ? "90%" : "",
        }}
      >
        <img
          className="loading-logo"
          src={logo}
          alt="stardust_logo"
          style={{ marginLeft: "10px" }}
        />
      </div>
    );
};
export default PostsController;
