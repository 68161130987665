/* import EmojiPicker from "emoji-picker-react"; */
import { useContext } from "react";

/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";
import italic_icon from "../../assets/ico/italic.svg";
import bold_icon from "../../assets/ico/bold.svg";
import emoticon_icon from "../../assets/ico/emoticon.svg";
import MainContext from "../../context/MainContext";

const MyPreviewConfig = {
  showPreview: false,
};

const OfferDescriptionInput = (props: any) => {
  const { isMobile } = useIsMobile();
  const bold = useToggle(false);
  const italic = useToggle(false);
  const emoji = useToggle(false);

  const { offer } = useContext(MainContext);

  const handleEmojiSelection = (e: any) => {
    const { emoji } = e;
    const formField = props.getValues("long_description");
    const finalString = formField?.concat(`${emoji}`);
    props.setValue("long_description", finalString);
  };

  const emojiPicker = (
    <div className="emoji-picker-wrapper">
      {/* <EmojiPicker
        onEmojiClick={(e) => handleEmojiSelection(e)}
        width={"100%"}
        height={245}
        previewConfig={MyPreviewConfig}
      /> */}
    </div>
  );

  return (
    <div
      className="offer-create-description-area shadow"
      style={{ width: isMobile ? "345px" : "" }}
    >
      <p
        className="offer-create-description-title"
        style={{ margin: isMobile ? "24px 156px 16px 24px" : "" }}
      >
        Offer description
      </p>
      <div
        className="offer-create-description-input-box"
        style={{
          width: isMobile ? "305px" : "", 
          position: "relative",
        }}
      >
        {/* <div className="offer-create-description-input-options-box">
          <div
            className="offer-create-dedcription-option-box"
            style={{ backgroundColor: emoji.isToggled ? "#1b1d21" : "" }}
            onClick={emoji.toggle}
          >
            <img
              className={`offer-create-dedcription-option-icon ${
                emoji.isToggled && "white"
              }`}
              src={emoticon_icon}
            />
          </div>
          <div
            className="offer-create-dedcription-option-box"
            style={{ backgroundColor: bold.isToggled ? "#1b1d21" : "" }}
            onClick={bold.toggle}
          >
            <img
              className={`offer-create-dedcription-option-icon ${
                bold.isToggled && "white"
              }`}
              src={bold_icon}
            />
          </div>
          <div
            className="offer-create-dedcription-option-box"
            style={{ backgroundColor: italic.isToggled ? "#1b1d21" : "" }}
            onClick={italic.toggle}
          >
            <img
              className={`offer-create-dedcription-option-icon ${
                italic.isToggled && "white"
              }`}
              src={italic_icon}
            />
          </div>
        </div>
        <div className="divider" style={{ width: "100%" }}></div> */}
        {emoji.isToggled && emojiPicker}
        <textarea
          className={`offer-create-description-input`}
          disabled={props.readOnly}
          style={{
            fontWeight: bold.isToggled ? "bold" : "",
            fontStyle: italic.isToggled ? "italic" : "",
            minHeight: isMobile ? "201px" : "",
            overflowY: "none",
          }}
          {...props.register("long_description")}
          defaultValue={offer?.long_description}
          placeholder="Write a description that will be visible by the client…"
        />
      </div>
    </div>
  );
};
export default OfferDescriptionInput;
