import { useState } from "react";
import { apiURL } from "../../consts/URLs";
import { CLIENTS } from "../endpointList";
import { fetchRequest, getOptions } from "../utils";
import useApiBuilder from "./useApiBuilder";
import { subjects } from "../../consts/statuses";

const useClient = (token?: string | null, subject?: string) => {
  const ApiB = useApiBuilder(subject ? subject : subjects.client);

  const createClient = (obj: any) => {
    const url = `${apiURL}/${CLIENTS}`;
    const options = getOptions({
      method: "POST",
      body: { ...obj },
      token: token,
    });

    ApiB.call(url, options);
  };

  const updateClient = (id: number, obj: any) => {
    const url = `${apiURL}/${CLIENTS}/${id}`;
    const options = getOptions({
      method: "PUT",
      body: { ...obj },
      token: token,
    });

    ApiB.call(url, options);
  };

  const deleteClient = (id: number) => {
    const url = `${apiURL}/${CLIENTS}/${id}`;
    const options = getOptions({ method: "DELETE", token: token });
    ApiB.call(url, options);
  };

  const getClientById = (id: number) => {
    const url = `${apiURL}/${CLIENTS}/${id}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const getListPaginated = () => {
    const url = `${apiURL}/${CLIENTS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const getListAll = () => {
    const url = `${apiURL}/${CLIENTS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const CTAs = {
    getListAll,
    createClient,
    updateClient,
    deleteClient,
    getListPaginated,
    getClientById,
  };

  return { CTAs, ApiB };
};
export default useClient;
