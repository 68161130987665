import { useEffect, useRef, useState } from "react";
import useToggle from "../../hooks/useToggle";

const CheckboxInput = (props: any) => {
  const [isChecked, setIsChecked] = useState<boolean>(props.defaultValue);
  const renderCount = useRef(0);
  renderCount.current = renderCount.current + 1;

  useEffect(() => {
    props.setValue(`${props.name}`, isChecked.toString());
  }, [isChecked]);
 

  const handleOnChange = () => {
    setIsChecked((prevState) => {
      /* console.log(prevState, " into: " + !prevState); */
      if (props.onDeselect) {
        props.onDeselect();
      }
      return !prevState;
    });
  };

  return (
    <label
      className={`container`}
      style={{
        width: `${props.width}px`,
      }}
    >
      <p className={`checkbox-value`}>{props.title}</p>
      <input
        type="checkbox"
        name={props.name}
        checked={isChecked}
        onChange={(e) => {
          handleOnChange();
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
};
export default CheckboxInput;
