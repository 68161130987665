import { apiURL } from "../../consts/URLs";
import { subjects } from "../../consts/statuses";
import { USER, USERS } from "../endpointList";
import { getOptions } from "../utils";
import useApiBuilder from "./useApiBuilder";

const useUser = (token?: string | null, subject?: string) => {
  const ApiB = useApiBuilder(subject ? subject : subjects.user);

  const getUserByToken = () => {
    const url = `${apiURL}/${USER}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };
  const updateUser = (obj: any, id: number) => { 
    const url = `${apiURL}/${USER}/${id}`;
    const options = getOptions({
      method: "PUT",
      body: { ...obj },
      token: token,
    });
    ApiB.call(url, options);
  };

  const deleteUser = (id: number) => {
    const url = `${apiURL}/${USER}/${id}`;
    const options = getOptions({
      method: "DELETE",
      token: token,
    });
    ApiB.call(url, options);
  };

  const getUsersList = () => {
    const url = `${apiURL}/${USERS}`;
    const options = getOptions({ method: "GET", token: token });
    ApiB.call(url, options);
  };

  const CTAs = {
    getUserByToken,
    getUsersList,
    updateUser,
    deleteUser,
  };

  return { CTAs, ApiB };
};
export default useUser;
