import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import close_icon from "../../assets/ico/close.svg";
import { paths } from "../../consts/URLs";
import MainContext from "../../context/MainContext";
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";
import { getUserStatusStyle } from "../../utils/getMethods";
import Avatar from "../misc/Avatar";

const UserCard = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const deleteToggler = useToggle(false);

  const { setUserTreated } = useContext(MainContext);

  const handleOnCardClick = () => {
    setUserTreated(props.user);
    navigate(paths.pages.user_detail.replace(":id", props.user.id));
  };

  const handelOnDeleteClick = () => {
    setUserTreated(props.user);
    props.deleteToggler.toggle();
  };

  return (
    <div
      className="user-card"
      onMouseEnter={deleteToggler.toggle}
      onMouseLeave={deleteToggler.toggle}
      style={{ cursor: "pointer" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "90%",
          justifyContent: isMobile ? "space-between" : "",
        }}
        onClick={handleOnCardClick}
      >
        {!isMobile && (
          <div>
            <Avatar user={props.user} size={56} bgColor={props.bgColor} />
          </div>
        )}
        <p
          className="user-card-field"
          style={{
            cursor: "pointer",
            marginLeft: isMobile ? "" : "35px",
          }}
        >
          {`${props.user.name} ${props.user.surname}`}
        </p>
        {!isMobile && (
          <p
            className="user-card-field"
            style={{
              cursor: "pointer",
            }}
          >
            {props.user.email}
          </p>
        )}
        <div
          className="user-card-field"
          style={{
            marginLeft: isMobile ? "70px" : "",
            marginRight: isMobile ? "" : "30px",
            display: isMobile ? "" : "flex",
            justifyContent: isMobile ? "" : "center",
            cursor: "pointer",
          }}
        >
          <div
            className="user-card-status-box"
            style={{
              backgroundColor: getUserStatusStyle(props.user.status)?.bgColor,
              cursor: "pointer",
              padding: isMobile ? "0" : "0",
            }}
          >
            <p
              className="user-card-status-text"
              style={{
                color: getUserStatusStyle(props.user.status)?.textColor,
                cursor: "pointer",
              }}
            >
              {props.user.status}
            </p>
          </div>
        </div>
      </div>
      {(deleteToggler.isToggled || isMobile) && (
        <img
          className="user-card-delete-cta"
          style={{
            marginRight: isMobile ? "10px" : "20px",
          }}
          src={close_icon}
          onClick={handelOnDeleteClick}
        />
      )}
    </div>
  );
};
export default UserCard;
