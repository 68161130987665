import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "../../../../components/inputs/CustomInput";
import NavBar from "../../../../components/misc/NavBar";
import SideBar from "../../../../components/misc/SideBar";
import { editInputs } from "../../../../consts/inputs";
import useIsMobile from "../../../../hooks/useIsMobile";
import useLoader from "../../../../hooks/useLoader";
import useToggle from "../../../../hooks/useToggle";
import { Input, Social, Verticality } from "../../../../interfaces";
import "../../../../styles/Edit.css";
import {
  getDefaultValue,
  getInputs,
  getSocials,
  getVerticalities
} from "../../../../utils/getMethods";
import {
  backToTopOfPage,
  socialRemoveAt,
  usernameRemoveAt,
} from "../../../../utils/others";
import { formatDateYYYMMGG } from "../../../../utils/reformatDate";
/* import {
  modelRemappingFromEditInputs,
  modelRemappingFromResult,
} from "../../../utils/modelRemapping"; */
import { useNavigate } from "react-router-dom";
import useInfluencers from "../../../../api/hooks/useInfluencers";
import useSocials from "../../../../api/hooks/useSocials";
import useVerticalities from "../../../../api/hooks/useVerticalities";
import close_icon from "../../../../assets/ico/close.svg";
import delete_icon from "../../../../assets/ico/delete.svg";
import download_icon from "../../../../assets/ico/download.svg";
import smallLeft_icon from "../../../../assets/ico/small-left.svg";
import DeleteModal from "../../../../components/modals/DeleteModal";
import { paths } from "../../../../consts/URLs";
import { subjects } from "../../../../consts/statuses";
import MainContext from "../../../../context/MainContext";
import {
  influencerRemappingFromEditInputs,
  influencerRemappingFromGetAPI,
} from "../../../../utils/remapping";

const InfluencerEditPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const menuToggler = useToggle(false);
  const { influencer, setInfluencer, token, refresh, setRefresh } =
    useContext(MainContext);
  const onCreateInfluencer = influencer === null;

  const getHooK = useInfluencers(token);
  const updateHook = useInfluencers(token, subjects.influencer_update);
  const deleteHook = useInfluencers(token, subjects.influencer_delete);
  const createHook = useInfluencers(token, subjects.influencer_create);

  const verticalityCreateHook = useVerticalities(token);
  const verticalityDeleteHook = useVerticalities(token);
  const socialCreateHook = useSocials(token);
  const socialUpdateHook = useSocials(token);
  const socialDeleteHook = useSocials(token);

  const [socialInfos, setSocialInfos] = useState<Social[] | null>(
    influencer?.social_infos ? influencer.social_infos : null
  );

  const [verticalities, setVerticalities] = useState<Verticality[] | null>(
    influencer?.verticalities ? influencer.verticalities : null
  );

  /* Loaders */
  const profileImageLoader = useLoader(influencer, refresh);
  const mediaKitLoader = useLoader(influencer, refresh);

  /* Modal */
  const deleteInfluencerToggler = useToggle(false);
  /* Forms */
  const deleteInfluencerForm = useForm();
  const edit_create_InfluencerForm = useForm();

  /* ---Media kit handler---- */
  const downloadMediaKit = (link: string | null | undefined) => {
    if (link != null || link != undefined) {
      window.open(link, "blank");
    }
  };
  const handleOnFileChange = (e: any) => {
    let input: any = document.getElementById("file-upload");
    let infoArea = document.getElementById("file-upload-filename");
    input = e.srcElement;

    if (infoArea && input) {
      infoArea.textContent = "File: " + input.files[0].name;
    }
    mediaKitLoader.process(e, "mediaKit");
  };

  /* ---Reset Mediakit Input----- */
  const [isMediaKitVisible, setIsMediaKitVisible] = useState(true);
  const blink = () => {
    mediaKitLoader.reset();
    setIsMediaKitVisible(false);
  };
  useEffect(() => {
    if (!isMediaKitVisible) {
      setIsMediaKitVisible(true);
    }
  }, [isMediaKitVisible]);
  /* 
  console.log(mediaKitLoader.filesOnInput); */
  /* ------------------------------ */

  /* -----custom input mediakit show file name---- */

  /* let input = document.getElementById("file-upload");
  let infoArea = document.getElementById("file-upload-filename");
  input?.addEventListener("change", showFileName);
  function showFileName(event: any) {
    // the change event gives us the input it occurred in
    let input = event.srcElement;
    // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
    let fileName = input.files[0].name;
    // use fileName however fits your app best, i.e. add it into a div
    if (infoArea) infoArea.textContent = "File: " + fileName;
  } */
  /* ----------------------------------------------- */

  /* ----------------------------- */

  const goBack = () =>
    setTimeout(() => {
      navigate(paths.pages.influencers);
    }, 3000);

  useEffect(() => {
    if (deleteInfluencerToggler.isToggled) {
      backToTopOfPage();
    }
  }, [deleteInfluencerToggler]);

  /* ------GET INFLUENCERS------ */
  useEffect(() => {
    if (influencer) {
      getHooK.CTAs.getInfluencerById(influencer.id);
    }
  }, [refresh]);
  /* ---------------------------- */

  /* ----------------On Get Influencer Response------------------ */
  useEffect(() => {
    /* console.log(getHooK.ApiB.response, getHooK.ApiB.result);  */
    if (getHooK.ApiB.response?.ok) {
      const remappedObj = influencerRemappingFromGetAPI(
        getHooK.ApiB.result.talent
      );

      setInfluencer(remappedObj);
    }
  }, [getHooK.ApiB.response]);

  /*-----Fill Fields----- */
  useEffect(() => {
    if (influencer) {
      edit_create_InfluencerForm.reset(influencer);
    }
  }, [influencer]);
  /* --------------------- */
  /* ------------------------------------------------------------- */

  const handleOnClickDelete = () => {
    deleteInfluencerToggler.toggle();
  };

  const handleAction = (data: any) => {
    const profileImage = profileImageLoader.filesBase64[0]?.profileImage;
    const mediaKit = mediaKitLoader.filesBase64[0]?.mediaKit;
    const finalObj = influencerRemappingFromEditInputs(
      data,
      profileImage,
      mediaKit
    );
    if (onCreateInfluencer) {
      onCreateInfluencerSubmit(data, finalObj);
    } else {
      onEditInfluencerSubmit(data, finalObj);
    }
  };

  /*---------------------Edit Handler----------------------------*/
  const onEditInfluencerSubmit = (data: any, obj: any) => {
    /* console.log(finalObj, JSON.stringify(finalObj)); */
    if (influencer) {
      setVerticalities(getVerticalities(data));
      setSocialInfos(getSocials(data));
      updateHook.CTAs.updateInfluencers(influencer?.id, obj);
    }
  };
  /*----------------------------------------------------------------------*/

  /*---------------------Create Handler----------------------------*/
  const onCreateInfluencerSubmit = (data: any, obj: any) => {
    /* console.log(JSON.stringify(obj), "CREATE INFLUENCER"); */
    setVerticalities(getVerticalities(data));
    setSocialInfos(getSocials(data));
    createHook.CTAs.createInfluencers(obj);
  };
  /*----------------------------------------------------------------------*/

  /*------------On Create Influencer Response------------- */
  useEffect(() => {
    /* console.log(createHook.ApiB.response, createHook.ApiB.result); */
    if (createHook.ApiB.response?.ok === true) {
      if (verticalities) {
        const influencerId = createHook.ApiB.result.talent.id;
        /* console.log(
          influencerId,
          JSON.stringify({ verticalities: verticalities })
        ); */
        verticalityCreateHook.CTAs.createVerticality(influencerId, {
          verticalities: verticalities,
        });
        if (socialInfos) {
          /*  console.log(influencerId, JSON.stringify({ socials: socialInfos })); */
          socialCreateHook.CTAs.createSocial(influencerId, {
            socials: socialInfos,
          });
        }
      } else {
        goBack();
      }
    }
  }, [createHook.ApiB.response]);
  /*--------------------------------------------------- */

  /*---------------On Update Influencer Response------------------ */
  useEffect(() => {
    /* console.log(updateHook.ApiB.response, updateHook.ApiB.result); */
    if (updateHook.ApiB.response?.ok === true) {
      if (verticalities && influencer) {
        const influencerId = influencer.id;
        verticalityCreateHook.CTAs.createVerticality(influencerId, {
          verticalities: verticalities,
        });
        /* console.log(socialInfos, influencer.social_infos);   */
        if (socialInfos) {
          socialInfos.forEach((social) => {
            let id = null;
            let obj = null;
            for (let i = 0; i < influencer.social_infos.length; i++) {
              if (
                social.social_name === influencer.social_infos[i].social_name
              ) {
                id = influencer.social_infos[i].id;
              }
            }
            obj = {
              social_name: social.social_name,
              username: usernameRemoveAt(social.username!, "@"),
              link: socialRemoveAt(social.link, "@"),
            };
            if (id && obj?.link != null) {
              socialUpdateHook.CTAs.updateSocial(influencerId, id, {
                username: obj.username,
                link: obj.link,
              });
            } /* else if (id && obj?.link === null) {
              socialUpdateHook.CTAs.updateSocial(influencerId, id, {
                username: obj.username,
                link: obj.link,
              });
            }  */ else if (!id && obj?.link != null) {
              socialCreateHook.CTAs.createSocial(influencerId, {
                socials: [{ ...obj }],
              });
            }
          });
        }
      } else setRefresh((prevState) => !prevState);
    }
  }, [updateHook.ApiB.response]);
  /* --------------------------------------------------------------------- */

  /*---------------On Create Verticalities Response------------------ */
  useEffect(() => {
    /* console.log(
      verticalityCreateHook.ApiB.response,
      verticalityCreateHook.ApiB.result
    ); */
    if (verticalityCreateHook.ApiB.response?.ok === true) {
      if (onCreateInfluencer) {
        goBack();
      }
    }
  }, [verticalityCreateHook.ApiB.response]);
  /* --------------------------------------------------------------------- */

  /* -----------------On Social Create Response------------------------ */
  useEffect(() => {
    /* console.log(
      "Create Socials",
      socialCreateHook.ApiB.response,
      socialCreateHook.ApiB.result
    ); */
    if (socialCreateHook.ApiB.response?.ok === true) {
      if (onCreateInfluencer) {
        goBack();
      }
    }
  }, [socialCreateHook.ApiB.response]);
  /* ------------------------------------------------------------------ */
  /* -----------------On Social Update Response------------------------ */
  useEffect(() => {
    /* console.log(
      "Update Socials",
      socialUpdateHook.ApiB.response,
      socialUpdateHook.ApiB.result
    ); */
    if (socialUpdateHook.ApiB.response?.ok === true) {
    }
  }, [socialUpdateHook.ApiB.response]);
  /* ------------------------------------------------------------------ */
  /* -----------------On Social Delete Response------------------------ */
  useEffect(() => {
    /* console.log(
      "Delete Socials",
      socialDeleteHook.ApiB.response,
      socialDeleteHook.ApiB.result
    ); */
    if (socialDeleteHook.ApiB.response?.ok === true) {
    }
  }, [socialDeleteHook.ApiB.response]);
  /* ------------------------------------------------------------------ */

  /*------------On Delete Influencer Response------------- */
  const onDeleteInfluencerSubmit = (data: any) => {
    if (influencer) {
      deleteHook.CTAs.deleteInfluencers(influencer?.id);
    }
  };

  useEffect(() => {
    /*  console.log(deleteHook.ApiB.response, deleteHook.ApiB.result); */
    if (deleteHook.ApiB.response?.ok === true) {
      setRefresh((prevState) => !prevState);
      goBack();
    }
  }, [deleteHook.ApiB.response]);
  /*--------------------------------------------------- */

  const bio_Inputs = getInputs(editInputs, ["bio"]);
  const render_bio_Inputs = bio_Inputs[0].fields!.map(
    (item: Input, key: number) => {
      let defaultValue: string | number | Date = getDefaultValue(
        influencer!,
        item
      );
      if (item.type === "select") {
        return (
          <CustomInput
            key={key}
            {...item}
            register={edit_create_InfluencerForm.register}
            errors={edit_create_InfluencerForm.formState.errors}
            options={item.fields}
            defaultValue={defaultValue}
          />
        );
      } else
        return (
          <CustomInput
            width={"100%"}
            key={key}
            {...item}
            register={edit_create_InfluencerForm.register}
            errors={edit_create_InfluencerForm.formState.errors}
            defaultValue={
              item.type === "Date"
                ? formatDateYYYMMGG(new Date(defaultValue))
                : defaultValue
            }
          />
        );
    }
  );

  const contacts_Inputs = getInputs(editInputs, ["contacts"]);
  const render_contacts_Inputs = contacts_Inputs[0].fields!.map(
    (item: Input, key: number) => {
      let defaultValue: string | number | Date = getDefaultValue(
        influencer!,
        item
      );
      return (
        <CustomInput
          width={"100%"}
          key={key}
          {...item}
          register={edit_create_InfluencerForm.register}
          errors={edit_create_InfluencerForm.formState.errors}
          defaultValue={defaultValue}
        />
      );
    }
  );

  const socials_inputs = getInputs(editInputs, ["social_info"]);
  const render_socials_inputs = socials_inputs[0].fields!.map(
    (item: Input, key: number) => {
      const chosen_icon = influencer?.social_infos.filter(
        (icon) => icon.social_name.toLowerCase() === item.name
      )[0];
      let defaultValue: string | undefined = chosen_icon?.link;
      return (
        <CustomInput
          width={"100%"}
          key={key}
          {...item}
          register={edit_create_InfluencerForm.register}
          errors={edit_create_InfluencerForm.formState.errors}
          defaultValue={defaultValue}
        />
      );
    }
  );

  const verticalities_inputs = getInputs(editInputs, ["verticalities"]);
  const render_verticalities_inputs = verticalities_inputs[0].fields!.map(
    (item: Input, key: number) => {
      let vert_id: number | null | undefined = null;
      let defaultValue: boolean = false;
      influencer?.verticalities.forEach((vert) => {
        if (vert.description === item.name) {
          defaultValue = true;
          vert_id = vert.id;
        }
      });

      const deleteVert = () => {
        if (influencer && vert_id) {
          /* console.log("DELETED"); */
          return verticalityDeleteHook.CTAs.deleteVerticality(
            influencer?.id,
            vert_id
          );
        } /* else console.log("DESELECTED") */
      };

      return (
        <CustomInput
          width={isMobile ? 305 : 290}
          key={key}
          {...item}
          register={edit_create_InfluencerForm.register}
          errors={edit_create_InfluencerForm.formState.errors}
          setValue={edit_create_InfluencerForm.setValue}
          defaultValue={defaultValue}
          onDeselect={deleteVert}
        />
      );
    }
  );

  const mediaKit_input = getInputs(editInputs, ["files"]);
  const render_mediaKit_input = mediaKit_input[0].fields!.map(
    (item: any, key: number) => {
      /* let defaultValue: string | number | Date = getDefaultValue(
        influencer!,
        item
      ); */
      return (
        <div
          key={key}
          className="mediakit-input-box shadow"
          style={{ width: isMobile ? "345px" : "" }}
        >
          <p
            className="box-title"
            style={{
              width: isMobile ? "85%" : "86%",
              padding: isMobile ? "0 0 20px" : "0 0 26px",
            }}
          >
            Media Kit
          </p>
          <div className="mediakit-input-box-inner">
            {isMediaKitVisible && (
              <>
                <input
                  id="file-upload"
                  name={item.name}
                  type={item.type}
                  accept={item.accept} /* 
                  style={{ display: "none" }} */
                  onChange={(e: any) => handleOnFileChange(e)}
                />

                {/*  <label
                  htmlFor="file-upload"
                  id="file-upload-filename"
                  className="mediakit-input"
                >
                  <p id="file-upload-filename">Upload</p>
                </label> */}
              </>
            )}
            {mediaKitLoader.filesOnInput.length == 0 &&
              influencer?.mediaKit && (
                <img
                  className="download-mediakit-icon"
                  src={download_icon}
                  onClick={() => downloadMediaKit(influencer?.mediaKit)}
                />
              )}
            {mediaKitLoader.filesOnInput.length > 0 && (
              <img
                className="download-mediakit-icon"
                src={close_icon}
                onClick={() => blink()}
              />
            )}
          </div>
        </div>
      );
    }
  );

  return (
    <div className="page-wrapper">
      {updateHook.ApiB.isToastVisible && updateHook.ApiB.toast}
      {deleteHook.ApiB.isToastVisible && deleteHook.ApiB.toast}
      {createHook.ApiB.isToastVisible && createHook.ApiB.toast}
      {deleteInfluencerToggler.isToggled && (
        <form
          onSubmit={deleteInfluencerForm.handleSubmit(onDeleteInfluencerSubmit)}
        >
          <DeleteModal
            toggler={deleteInfluencerToggler}
            register={deleteInfluencerForm.register}
            reset={deleteInfluencerForm.reset}
            influencer={influencer}
            deleteType={"influencer"}
          />
        </form>
      )}

      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 256px)",
            minWidth: isMobile ? "" : "690px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
            display:
              deleteInfluencerToggler.isToggled && isMobile ? "none" : "",
          }}
        >
          <div
            className="edit-header"
            style={{
              margin: isMobile ? "30px auto 0" : "",
              width: "90%",
              textAlign: isMobile ? "center" : "left",
            }}
          >
            {!onCreateInfluencer && (
              <div
                className="Oval"
                onClick={() =>
                  navigate(
                    paths.pages.influencer_detail.replace(
                      ":id",
                      influencer.id.toString()
                    )
                  )
                }
              >
                <img src={smallLeft_icon} />
              </div>
            )}

            <p
              className="edit-header-title"
              style={{
                fontSize: isMobile ? "32px" : "",
                width: onCreateInfluencer ? "" : "70%",
              }}
            >
              {onCreateInfluencer ? "Create new influencer" : "Edit Profile"}{" "}
            </p>
          </div>

          <form
            style={{ width: "100%" }}
            onSubmit={edit_create_InfluencerForm.handleSubmit(handleAction)}
            /* onChange={() =>
            setEditedFieldValues(edit_create_InfluencerForm.getValues())
          } */
          >
            <div
              className="edit-box1 shadow"
              style={{
                width: isMobile ? "345px" : "",
                padding: isMobile ? "0" : "",
              }}
            >
              {profileImageLoader.filesPreviewSrc[0] ? (
                <div
                  className="profile-img-preview shadow"
                  style={{
                    backgroundImage: `url(${profileImageLoader.filesPreviewSrc[0]})`,
                  }}
                />
              ) : influencer?.profileImage ? (
                <div
                  className="profile-img-preview shadow"
                  style={{
                    backgroundImage: `url(${influencer?.profileImage})`,
                  }}
                />
              ) : (
                <div className="media-input-box">
                  <p
                    className="media-input-text"
                    style={{
                      height: "43px",
                      margin: "49px 40px 0px",
                    }}
                  >
                    Upload pofile image
                  </p>
                  <label
                    className="media-input-button"
                    style={{
                      margin: "16px 0 0",
                    }}
                    htmlFor="profile_img_input"
                  >
                    <p className="media-input-button-text">Add image</p>
                  </label>
                </div>
              )}
              <input
                id="profile_img_input"
                type="file"
                accept={"image/*"}
                style={{ display: "none" }}
                onChange={(e: any) => {
                  profileImageLoader.process(e, "profileImage");
                }}
              />
              {(profileImageLoader.filesPreviewSrc[0] ||
                influencer?.profileImage) && (
                <label htmlFor="profile_img_input">
                  <p className="change-image">Change image</p>
                </label>
              )}
              <div className="edit-input-wrapper">
                <p className="box-title">Personal Information</p>
                {render_bio_Inputs}
              </div>
            </div>
            <div
              className="edit-skills-box shadow"
              style={{
                width: isMobile ? "345px" : "",
              }}
            >
              <div className="edit-input-wrapper">
                <p
                  className="box-title"
                  style={{
                    width: isMobile ? "85%" : "86%",
                  }}
                >
                  Verticalities
                </p>
                <div
                  className="grid"
                  style={{
                    width: isMobile ? "" : "100%",
                    display: isMobile ? "flex" : "grid",
                    flexDirection: isMobile ? "column" : "row" /* 
                    gap: isMobile ? "10px" : "", */,
                  }}
                >
                  {render_verticalities_inputs}
                </div>
              </div>
            </div>
            <div
              className="edit-box2 shadow"
              style={{ width: isMobile ? "345px" : "" }}
            >
              <div className="edit-input-wrapper">
                <p className="box-title">Contacts</p>
                {render_contacts_Inputs}
                <p className="customInputText-title">Social</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  {render_socials_inputs}
                </div>
              </div>
            </div>

            {render_mediaKit_input}

            <div
              className="edit-bottom-cta-box"
              style={{
                flexDirection: isMobile ? "column" : "row",
                width: isMobile ? "345px" : "",
                marginTop: isMobile ? "46px" : "",
                gap: isMobile ? "29px" : "",
                /* justifyContent: onCreateInfluencer ? "right" : "space-between", */
              }}
            >
              {!onCreateInfluencer && (
                <div className="delete-model-box">
                  <img
                    className="delete-model-icon offMainColor"
                    src={delete_icon}
                  />
                  <p
                    className="delete-model-text offMainColor"
                    onClick={handleOnClickDelete}
                  >
                    Delete influencer
                  </p>
                </div>
              )}
              <button
                className="button1"
                style={{ width: isMobile ? "345px" : "101px" }}
                type="submit" /* 
              disabled={ctaToggler.isToggled} */
              >
                <p className="button1-text">
                  {onCreateInfluencer ? "Create" : "Save"}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default InfluencerEditPage;
