/* import MainContext from "../../context/MainContext"; */
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import MainContext from "../../context/MainContext";
import { paths } from "../../consts/URLs";
import { theme } from "../../consts/palette";
import { transformBudget } from "../../utils/others";

const ClientsCard = (props: any) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const { setClient } = useContext(MainContext);

  const handleOnclick = () => {
    setClient(props.client);
    navigate(
      paths.pages.client_detail.replace(":id", props.client.id.toString())
    );
  };

  return (
    <div
      className="client-card"
      onClick={handleOnclick}
      style={{
        margin: isMobile ? "" : "0 23px" /* 
        justifyContent:isMobile?"space-around":"" */,
      }}
    >
      <p
        className="client-card-field"
        style={{
          fontSize: "16px",
          width: isMobile ? "42%" : "",
        }}
      >
        {props.client.brand_name}
      </p>
      {!isMobile && (
        <>
          <p className="client-card-field">{props.client.referent_name}</p>
          <p className="client-card-field">{props.client.email}</p>
          <p className="client-card-field" style={{ textAlign: "center" }}>
            {props.client.final_client}
          </p>
        </>
      )}
      {
        <p
          className="client-card-field"
          style={{
            width: "135px",
            color: theme.main_color,
            textAlign: "right",
          }}
        >
          {props.client.amount_spent && "€ "}
          {transformBudget(Number(props.client.amount_spent))}
        </p>
      }
    </div>
  );
};
export default ClientsCard;
