import { useState } from "react";
import { apiURL } from "../../../consts/URLs";
import { LOGIN } from "../../endpointList";
import { fetchRequest, getOptions } from "../../utils";
import useApiBuilder from "../useApiBuilder";
import { subjects } from "../../../consts/statuses";

const useLogin = () => {
  const ApiB = useApiBuilder(subjects.login);

  const login = (obj: any) => {
    const url = `${apiURL}/${LOGIN}`;
    const options = getOptions({ method: "POST", body: { ...obj } });
    ApiB.call(url, options);
  };

  return { login, ApiB };
};
export default useLogin;
