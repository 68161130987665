import useIsMobile from "../../hooks/useIsMobile";

const CustomVideo = (props: any) => {
  const isMobile = useIsMobile();
  return (
    <video 
      width={isMobile ? "100%" : "90%"}
      height={isMobile ? "90%" : "90%"}
      loop
      muted
      controls
      playsInline
      style={{borderRadius:"24px"}}
    >
      <source id="video" src={props.src + "#t=0.001"} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
export default CustomVideo;
