import { useContext, useEffect, useState } from "react";
import useOffer from "../../../api/hooks/useOffers";
import EarningsChart from "../../../components/chart/EarningsChart";
import NavBar from "../../../components/misc/NavBar";
import SideBar from "../../../components/misc/SideBar";
import EarningsSideBar from "../../../components/overview/EarningsSideBar";
import OpenOfferTag from "../../../components/overview/OpenOfferTag";
import OverviewHeader from "../../../components/overview/OverviewHeader";
import OverviewNews from "../../../components/overview/OverviewNews";
import { cookieName } from "../../../consts/cookie";
import { possible_statuses } from "../../../consts/statuses";
import MainContext from "../../../context/MainContext";
import useIsMobile from "../../../hooks/useIsMobile";
import useToggle from "../../../hooks/useToggle";
import { Offer } from "../../../interfaces";
import "../../../styles/General.css";
import "../../../styles/News.css";
import "../../../styles/Overview.css";
import { getCookie } from "../../../utils/handleCookie";
import { offerRemappingFromResult } from "../../../utils/remapping";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../consts/URLs";
import Toast from "../../../components/misc/Toast";
import { logMsgBgColors } from "../../../consts/misc";
/* import { deleteCookie, getCookie } from "../../utils/handleCookie"; */

const OverviewPage = (props: any) => {
  // unmute / mute these, or delete browser chronology to test tips card workflow
  /*  deleteCookie(cookieName.tip_1);
  deleteCookie(cookieName.tip_2);
  deleteCookie(cookieName.tip_3);   */

  const tipsRemoveCondition =
    getCookie(cookieName.tip_1) === "false" &&
    getCookie(cookieName.tip_2) === "false" &&
    getCookie(cookieName.tip_3) === "false";

  const { isMobile } = useIsMobile();
  const menuToggler = useToggle(false);
  const newsToggler = useToggle(false);
  const navigate = useNavigate();

  const { token, refresh } = useContext(MainContext);

  const offerHook = useOffer(token);

  const [allOffers, setAllOffers] = useState<Offer[]>([]);
  const [offerFetched, setOfferFetched] = useState<Offer[]>([]);

  useEffect(() => {
    offerHook.CTAs.getOffers();
  }, [refresh]);

  useEffect(() => {
    /* console.log(offerHook.ApiB.response, offerHook.ApiB.result);   */
    if (offerHook.ApiB.response?.ok && token) {
      setAllOffers(offerHook.ApiB.result.offers);
      const tempArray = offerHook.ApiB.result.offers
        .filter(
          (offer: any) =>
            offer.status === possible_statuses.offer.open ||
            offer.status === possible_statuses.offer.draft ||
            offer.status === null
        )
        .slice(0, 3);
      setOfferFetched(tempArray);
    }
  }, [offerHook.ApiB.response]);

  const renderOpenOffersTags = offerFetched.map((offer, key) => {
    const offerRemapped = offerRemappingFromResult(offer);
    return <OpenOfferTag key={key} offer={offerRemapped} />;
  });

  return (
    <div className="page-wrapper">
      {(!isMobile || menuToggler.isToggled) && (
        <SideBar toggler={menuToggler} />
      )}
      <div>
        {isMobile && <NavBar toggler={menuToggler} />}
        <div
          className={`page-content ${
            isMobile && menuToggler.isToggled
              ? menuToggler.isToggledSecondary
                ? "slide-right"
                : "slide-left"
              : ""
          }`}
          style={{
            maxWidth: isMobile ? "" : "calc(100vw - 656px)",
            minWidth: isMobile ? "" : "600px",
            marginLeft: isMobile
              ? menuToggler.isToggled
                ? "256px"
                : ""
              : "256px",
          }}
        >
          <OverviewHeader />
          {!newsToggler.isToggled && !tipsRemoveCondition && (
            <OverviewNews toggler={newsToggler} />
          )}
          <div
            className="overview-offers-earning-container"
            style={{
              flexDirection: isMobile ? "column" : "row",
              margin: isMobile
                ? "38.3px auto "
                : `${
                    !newsToggler.isToggled && !tipsRemoveCondition
                      ? "386px"
                      : "0"
                  } auto 44px`,
              padding: isMobile ? "0" : "",
              width: isMobile ? "345px" : "",
              gap: isMobile ? "38.3px" : "",
            }}
          >
            <div
              className={`overview-offers shadow`}
              style={{
                width: isMobile ? "345px" : "",
                height: isMobile ? "fit-content" : "",
                padding: isMobile ? "0 0 30px 0" : "",
              }}
            >
              <p className="overview-offers-earning-title">Open offers</p>
              {renderOpenOffersTags}
            </div>
            <div
              className={`overview-earning shadow`}
              style={{ width: isMobile ? "345px" : "" }}
            >
              <p className="overview-offers-earning-title">Earning</p>
              <div
                className={`overview-earning-inner-wrapper`} /* 
              style={{ width: isMobile ? "345px" : "" }} */
              >
                <div className="overview-earning-chart-wrapper">
                  {
                    <EarningsChart
                      containerClassName={"overview-earning-chart-wrapper"}
                      height={200}
                      allOffers={allOffers}
                    />
                  }
                </div>
                <button
                  className="overview-earning-button"
                  onClick={() => navigate(paths.pages.offers)}
                >
                  <p className="overview-earning-button-text">View Offers</p>
                </button>
              </div>
            </div>
          </div>
          {isMobile && <EarningsSideBar allOffers={allOffers} />}
        </div>
      </div>
      {!isMobile && <EarningsSideBar allOffers={allOffers} />}
    </div>
  );
};
export default OverviewPage;
