import { Client } from "../../interfaces";

export const filterByName = (list: Client[], inputValue: string) => {
  let finalArray: Client[] = [];
  list.forEach((client) => {
    if (
      client.brand_name &&
      client.brand_name.toLowerCase().includes(inputValue.toLowerCase())
    ) {
      finalArray.push(client);
    }
  });
  return finalArray;
};
