import useIsMobile from "../../hooks/useIsMobile";

const FeedMessage = (props: any) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className="mail-sent"
      style={{
        width: isMobile ? "345px" : "",
        justifyContent: "center",
      }}
    >
      <div className={props.imageBgClassName}>
        <img src={props.imageSrc} />
      </div>
      <p className="cornerTitle">{props.title}</p>
      <p
        className="cornerTitle"
        style={{
          fontSize: "14px",
          width: isMobile ? "245px" : "",
          textAlign: "center",
        }}
      >
        {props.description}
      </p>
      <p
        className="forgotpassword-link"
        style={{ textAlign: "center", margin: "0", marginTop: "20px" }}
        onClick={() => {
          if (props.link?.path) window.location.href = props.link.path;
        }}
      >
        {props.link?.text}
      </p>
    </div>
  );
};
export default FeedMessage;
