import { useEffect } from "react";
import { clientInputs } from "../../consts/inputs";
import useIsMobile from "../../hooks/useIsMobile";
import { getDefaultValue } from "../../utils/getMethods";
import CustomInput from "../inputs/CustomInput";
import close_icon from "../../assets/ico/close.svg";
import delete_icon from "../../assets/ico/delete.svg";

const ClientCreateModal = (props: any) => {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (props.onEdit) props.reset(props.offer);
  }, []);

  const handleClickOnDelete = () => {
    props.deleteToggler.toggle();
    props.toggler.toggle();
  };

  const renderInputs = clientInputs.map((field, key) => {
    const defaultValue = getDefaultValue(props.client, field);
    return (
      <CustomInput
        key={key}
        title={field.title}
        name={field.name}
        type={field.type}
        register={props.register}
        errors={props.errors}
        defaultValue={props.onEdit && defaultValue}
        constraints={field.constraints}
      />
    );
  });

  return (
    <div
      className="modal-wrapper"
      style={{
        height: `100vh`,
      }}
    >
      <div
        className={`client-create-modal scroll-y  ${
          isMobile && "general-modal-dimension-Mob"
        }`}
        style={{
          width: isMobile ? "calc(345px - 64px)" : "",
          height: isMobile ? "75vh" : "",
          padding: isMobile ? "25px 19px 0 21px" : "",
        }}
      >
        <div>
          <div className="modal-header">
            <p className="modal-title">
              {props.onEdit ? "Edit" : "New"} Client
            </p>
            <div className="Oval" onClick={props.toggler.toggle}>
              <img src={close_icon} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              marginTop: "42px",
            }}
          >
            {renderInputs}
          </div>
        </div>
        {props.onEdit && (
          <div
            className="offer-ctas-delete-box offLightBlue"
            style={{
              margin: "48px auto 0",
              /* 
            width: isMobile ? "100%" : "", */
            }}
            onClick={handleClickOnDelete}
          >
            <img
              className="offer-ctas-delete-icon offMainColor"
              src={delete_icon}
            />
            <p className="offer-ctas-delete-text">Delete client</p>
          </div>
        )}
        <button
          style={{
            marginTop: isMobile ? "" : "48px",
            marginRight: "0",
            width: isMobile ? "100%" : "",
          }}
          className="modal-button"
          type="submit"
        >
          <p className="modal-button-text">
            {props.onEdit ? "Save Client" : "Create client"}
          </p>
        </button>
      </div>
    </div>
  );
};
export default ClientCreateModal;
