import { useContext, useEffect } from "react";
import close_icon from "../../assets/ico/close.svg";
import menu_icon from "../../assets/ico/menu.svg";
import search_icon from "../../assets/ico/search.svg";
import notifications_icon from "../../assets/ico/notifications.svg";
/* import MainContext from "../../context/MainContext"; */
/* import Avatar from "./Avatar"; */
import "../../styles/Content.css";
import Avatar from "./Avatar";
import MainContext from "../../context/MainContext";

const NavBar = (props: any) => {
  const { user } = useContext(MainContext);
  const { isToggled, toggle, isToggledSecondary, toggleSecondary } =
    props.toggler;

  useEffect(() => {
    if (isToggled) {
      toggle();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (isToggled && isToggledSecondary) {
      setTimeout(() => {
        toggle();
        toggleSecondary();
      }, 500);
    }
  }, [isToggledSecondary]);

  useEffect(() => {
    const body = document.getElementById("body");
    if (body && isToggled) {
      body.style.overflowY = "hidden";
    } else if (body && !isToggled) {
      body.style.overflowY = "scroll";
    }
  }, [isToggled]);

  return (
    <div
      className="navbar"
      style={{
        width: isToggled ? "" : `${document.documentElement.offsetWidth}px`,
        marginLeft: isToggled ? "256px" : "",
      }}
    >
      <div
        className={`navbar-content ${
          isToggled ? (isToggledSecondary ? "slide-right" : "slide-left") : ""
        } `}
      >
        <img
          className={`navbar-icon`}
          style={
            {
              /* 
            marginRight: isToggled ? "150px" : "51px",
            marginLeft: isToggled ? "20px" : "", */
            }
          }
          src={isToggled && !isToggledSecondary ? close_icon : menu_icon}
          alt=""
          onClick={() => {
            if (isToggled) {
              toggleSecondary();
            } else if (!isToggled) {
              toggle();
            }
          }}
        />
        <Avatar user={user} size={40} />
        {/* <div
          style={{
            width: "180px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img className="navbar-icon" src={search_icon} alt="" />
          <img className="navbar-icon" src={notifications_icon} alt="" /> 
        </div> */}
      </div>
    </div>
  );
};
export default NavBar;
