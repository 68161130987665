import { useContext, useEffect } from "react";
import useOffer from "../../api/hooks/useOffers";
import { logMsgBgColors } from "../../consts/misc";
import MainContext from "../../context/MainContext";
import { Influencer } from "../../interfaces";
import close_icon from "../../assets/ico/close.svg";
import { possible_statuses } from "../../consts/statuses";
import { offerRemappingFromResult } from "../../utils/remapping";
/* import { offerRemappingFromResult } from "../../utils/offerRemapping"; */

const InfluencerSelectorTag = (props: any) => {
  const { influencerSelector, offer, setOffer, token } =
    useContext(MainContext);
  const { setInfluencerList, influencerList } = influencerSelector;

  useEffect(() => {
    if (offer && offer.proposed_list && offer.proposed_list?.length > 0) {
      let temp: any[] = [];
      offer.proposed_list.forEach((item) => {
        if (item.status != possible_statuses.influencer.rejected) {
          temp.push({
            id: item.talent_id,
            status: item.status,
          });
        }
      });
      setInfluencerList(temp);
    }
  }, [offer]);

  const removeInfluencerWithCTA = () => {
    /* console.log(influencerList); */
    const finalList: Influencer[] = [];
    influencerList.forEach((influencer) => { 
      if (influencer.id === props.influencer.id && offer?.id) {
        props.updateOfferListHook.CTAs.updateOfferInfluencers(offer.id, {
          proposed_talents: [
            {
              talent_id: influencer.id,
              status: possible_statuses.influencer.rejected,
            },
          ],
        });
      } else {
        finalList.push(influencer);
      }
    });
    setInfluencerList(finalList);
  };

  useEffect(() => {
    /* console.log(
      props.updateOfferListHook.ApiB.response,
      props.updateOfferListHook.ApiB.result
    ); */
    if (props.updateOfferListHook.ApiB.response?.ok) {
      const offerRemapped = offerRemappingFromResult(
        props.updateOfferListHook.ApiB.result.talent
      );
      setOffer(offerRemapped);
    }
  }, [props.updateOfferListHook.ApiB.response]);

  return (
    <div className="models-selctor-model-tag">
      <div
        className="models-selector-model-tag-image"
        style={{ backgroundImage: `url(${props.influencer.profileImage})` }}
      />
      <p className="models-selector-model-tag-fullname">
        {props.influencer.name + " " + props.influencer.surname}
      </p>
      {!props.readOnly && (
        <img
          className="models-selector-model-tag-icon"
          src={close_icon}
          onClick={removeInfluencerWithCTA}
        />
      )}
    </div>
  );
};
export default InfluencerSelectorTag;
