import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useLogin from "../../../api/hooks/authentication/useLogin";
import useUser from "../../../api/hooks/useUser";
import Footer from "../../../components/Footer";
import CustomInput from "../../../components/inputs/CustomInput";
import LogMessage from "../../../components/misc/Toast";
import { paths } from "../../../consts/URLs";
import { cookieName } from "../../../consts/cookie";
import { authInputs } from "../../../consts/inputs";
import logo_main from "../../../assets/logo_main.png";
import useIsMobile from "../../../hooks/useIsMobile";
import "../../../styles/Access.css";
import "../../../styles/Content.css";
import "../../../styles/General.css";
import { getInputs } from "../../../utils/getMethods";
import { getCookie, setCookie } from "../../../utils/handleCookie";
import CheckboxInput from "../../../components/inputs/CheckboxInput";
import MainContext from "../../../context/MainContext";
import { subjects } from "../../../consts/statuses";

const LoginPage = (props: any) => {
  const { isMobile } = useIsMobile();
  const { setUser, user, setToken, token } = useContext(MainContext);
  const { login, ApiB } = useLogin();
  const userHook = useUser(token, subjects.user);
  const registerForm = useForm();

  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie(cookieName.rememberMe)) {
      registerForm.setValue("remember_me", true);
    } else registerForm.setValue("remember_me", false);
  }, []);

  useEffect(() => {
    if (token && window.location.pathname === paths.pages.login) {
      userHook.CTAs.getUserByToken();
    }
  }, [token]);

  useEffect(() => {
    /* console.log(ApiB.response, ApiB.result); */
    if (ApiB.response?.ok) {
      setUser(ApiB.result.user);
      setToken(ApiB.result.token);
      if (rememberMe === true) {
        setCookie(
          cookieName.user_essential,
          JSON.stringify({
            id: ApiB.result.user.id,
            type: ApiB.result.user.role,
          })
        );
        setCookie(cookieName.token, ApiB.result.token);
      }

      navigate(paths.pages.overview);
    }
  }, [ApiB.response]);

  const onSubmit = (data: any) => {
    if (data.remember_me) {
      setRememberMe(true);
    } else if (!data.remember_me) {
      setRememberMe(false);
    }
    login({ email: data.email, password: data.password });
  };

  const chosenInputs = getInputs(authInputs, ["email", "password"]);
  const renderInputs = chosenInputs.map((input: any, key: any) => {
    const isPasswordType = input.name.includes("password");

    return (
      <CustomInput
        width={
          isMobile
            ? isPasswordType
              ? "90%"
              : "100%"
            : isPasswordType
            ? "95%"
            : "100%"
        }
        key={key}
        {...input}
        register={registerForm.register}
        errors={registerForm.formState.errors}
      />
    );
  });

  /*  const onCheckboxTitleClick = () => {
    const checkboxValue = registerForm.getValues("remember_me");
    registerForm.setValue("remember_me", !checkboxValue);
  }; */

  return (
    <div id="bgAll" className="access-background">
      {ApiB.isToastVisible && ApiB.toast}

      <img className="logo-main" src={logo_main} />
      <div className={`access-content-box ${isMobile && "a-c-b-Mob"}`}>
        <div className="access-content-box-inner-wrapper">
          <div className="access-contentBox-header">
            <div className="cornerTitle">
              {"Log in"}
              <p className="cornerSubTitle">Enter email and password</p>
            </div>
          </div>
          <div className="input-positioner" style={{ gap: "32px" }}>
            <form onSubmit={registerForm.handleSubmit(onSubmit)}>
              {renderInputs}
              <p
                className="forgotpassword-link"
                onClick={() => {
                  window.location.href = `/recoverypassword`;
                }}
              >
                Forgot password?
              </p>
              <div className="contentBox-confirmationBox">
                <span className="rememberMeBox">
                  <CheckboxInput
                    name="remember_me"
                    title="Remember me"
                    type="checkobox"
                    register={registerForm.register}
                    setValue={registerForm.setValue}
                    defaultValue={false}
                  />
                  {/*  <input
                    type={"checkbox"}
                    {...registerForm.register("remember_me")}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="rememberMe-text" onClick={onCheckboxTitleClick}>
                    Remember Me
                  </p> */}
                </span>
                <button
                  className="button1 b-medium"
                  type="submit"
                  style={{ width: isMobile ? "125px" : "" }}
                >
                  <p className="button1-text">{"Log In"}</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {ApiB.callToggler.isToggled && ApiB.toast} */}
      <Footer />
    </div>
  );
};
export default LoginPage;
