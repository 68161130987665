import { useContext, useEffect, useState } from "react";
/* import useDeleteFile from "../../api/hooks/useDeleteFile"; */
import { toIcons } from "../../consts/URLs";
/* import MainContext from "../../context/MainContext"; */
import useIsMobile from "../../hooks/useIsMobile";
import useToggle from "../../hooks/useToggle";

const FileInput = (props: any) => {
  const { isMobile } = useIsMobile();
  /* const { token, setRefresh } = useContext(MainContext); */
 /*  const { deleteFileResponse, deleteFileResult, deleteFile } =
    useDeleteFile(token); */
  const { isToggled, toggle, setIsToggled } = useToggle(false);
  const [elemenetTreated, setElementTreated] = useState<string | null>(null);
  const docCondition = props.name === "documents";
  const vidCondition = props.name === "videos";
  const files = props.loader.filesOnInput;
  const previews = props.loader.filesPreviewSrc;
  const oldFiles = props.links;

  useEffect(() => {
    props.register(props.name, props.loader.filesBase64);
  }, [props.loader.filesBase64]);

  const handleShowDelete = (e: any, itemName: any) => {
    setIsToggled(true);
    setElementTreated(itemName);
  };
  const handleHideDelete = (e: any, itemName: any) => {
    setIsToggled(false);
    setElementTreated(itemName);
  };

  const handleRemoveFileSRC = async (index: number, src: any) => {
    let blob: any;
    await fetch(src)
      .then((r) => r.blob())
      .then((data) => (blob = data));
    props.remove(index, blob);
  };

  const handleRemoveFileDOC = async (index: number, file: any) => {
    props.remove(index, file);
  };

  const handleDeleteFile = (id: number) => {
    const fileType = props.subType;
    /* console.log(fileType); */
    /* deleteFile(id, fileType); */
  };

  useEffect(() => {
    /* console.log(deleteFileResponse, deleteFileResult); */
    /* if (deleteFileResponse?.ok) {
      setRefresh((prevState) => !prevState);
    } */
  }, [/* deleteFileResponse */]);

  const previewOldItems = oldFiles?.map((item: any, key: number) => {
    const src = `${item[props.subType]}`;
    const itemName = props.name + "" + key + "a";
    return (
      <div
        key={key + "a"}
        onMouseEnter={(e: any) => handleShowDelete(e, itemName)}
        onMouseLeave={(e: any) => handleHideDelete(e, itemName)}
        className={"preview-others"}
        style={{
          backgroundImage: vidCondition ? "" : `url(${src})`,
        }}
      >
        {vidCondition && (
          <video
            id="myVid"
            src={src}
            width={"100%"}
            height={"100%"}
            onMouseOver={(e: any) => {
              e.target.play();
            }}
            onMouseOut={(e: any) => {
              e.target.pause();
            }}
          />
        )}
        {isToggled && itemName === elemenetTreated && (
          <div className="close-wrapper">
            <img
              src={`${toIcons}/close.svg`}
              alt=""
              onClick={() => {
                handleDeleteFile(item.id);
              }}
            />
          </div>
        )}
      </div>
    );
  });

  const previewNewItems = previews?.map((src: any, key: number) => {
    const itemName = props.name + "" + key;
    return (
      <div
        key={key}
        onMouseEnter={(e: any) => handleShowDelete(e, itemName)}
        onMouseLeave={(e: any) => handleHideDelete(e, itemName)}
        className={
          "preview-others" /* key === 0 && !isMobile ? "preview-main" : "preview-others" */
        }
        style={{ backgroundImage: vidCondition ? "" : `url(${src})` }}
      >
        {vidCondition && (
          <video
            id="myVid"
            src={src}
            width={"100%"}
            height={"100%"}
            onMouseOver={(e: any) => {
              e.target.play();
            }}
            onMouseOut={(e: any) => {
              e.target.pause();
            }}
          />
        )}
        {isToggled && itemName === elemenetTreated && (
          <div className="close-wrapper">
            <img
              src={`${toIcons}/close.svg`}
              alt=""
              onClick={() => {
                handleRemoveFileSRC(key, src);
              }}
            />
          </div>
        )}
      </div>
    );
  });

  const previewOldDocuments = oldFiles?.map((item: any, key: number) => {
    const itemName = props.name + "" + key;
    return (
      <div
        key={key}
        className="document-wrapper"
        style={{
          position: "relative",
          width: isMobile ? "280px" : "450px",
          height: "75px",
          margin: 0,
          padding: 0,
          justifyContent: "left",
        }}
        onMouseEnter={(e: any) => handleShowDelete(e, itemName)}
        onMouseLeave={(e: any) => handleHideDelete(e, itemName)}
      >
        <div
          className="document-name-box"
          style={{
            marginLeft: "10px",
          }}
        >
          <div className="document-icon-wrapper">
            <img
              className="document-icon"
              src={`${toIcons}/iconly-light-document.svg`}
              style={{ cursor: "pointer" }}
              onClick={() => window.open(`${item[props.subType]}`)}
            />
          </div>
          <p
            className="document-name"
            style={{
              fontSize: isMobile ? "14px" : "",
            }}
          >
            {item.name ? item.name : "id: " + item.id}
          </p>
          {isToggled && itemName === elemenetTreated && (
            <img
              src={`${toIcons}/close.svg`}
              alt=""
              style={{
                position: "absolute",
                right: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleDeleteFile(item.id);
              }}
            />
          )}
        </div>
      </div>
    );
  });

  /* window.open(document_path) */

  const previewNewDocuments = files?.map((file: any, key: number) => {
    const itemName = props.name + "" + key + "b";
    const docPath = URL.createObjectURL(file);
    return (
      <div
        key={key + "b"}
        className="document-wrapper"
        style={{
          position: "relative",
          width: isMobile ? "280px" : "450px",
          height: "75px",
          margin: 0,
          padding: 0,
          justifyContent: "left",
        }}
        onMouseEnter={(e: any) => handleShowDelete(e, itemName)}
        onMouseLeave={(e: any) => handleHideDelete(e, itemName)}
      >
        <div
          className="document-name-box"
          style={{
            marginLeft: "10px",
          }}
        >
          <div className="document-icon-wrapper">
            <img
              className="document-icon"
              src={`${toIcons}/iconly-light-document.svg`}
              style={{ cursor: "pointer" }}
              onClick={() => window.open(`${docPath}`)}
            />
          </div>
          <p className="document-name" style={{ width: "fit-content" }}>
            {file.name}
          </p>
          {isToggled && itemName === elemenetTreated && (
            <img
              src={`${toIcons}/close.svg`}
              alt=""
              style={{
                position: "absolute",
                right: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleRemoveFileDOC(key, file);
              }}
            />
          )}
        </div>
      </div>
    );
  });

  const noNewFiles = files?.length === 0 || files === null;

  const noOldFiles =
    oldFiles?.length === 0 || oldFiles === null || oldFiles === undefined;

  /*   useEffect(() => {
    console.log(props.subType, noNewFiles, noOldFiles);
  }, []); */

  return (
    <div>
      <p className="box-title">{props.title}</p>
      <div
        className="loadBox"
        style={{
          width: isMobile ? "305px" : "",
          minHeight: docCondition ? "113px" : "180px",
        }}
      >
        <div
          className={
            !noNewFiles || !noOldFiles
              ? docCondition
                ? "preview-doc-wrapper"
                : "preview-wrapper"
              : ""
          }
          style={{
            gridTemplateColumns:
              (!noNewFiles || !noOldFiles) && !isMobile ? "" : "auto auto",
          }}
        >
          {(!noNewFiles || !noOldFiles) && docCondition
            ? !noOldFiles
              ? previewOldDocuments.concat(previewNewDocuments)
              : previewNewDocuments
            : !noOldFiles
            ? previewOldItems.concat(previewNewItems)
            : previewNewItems}
          <div className="loadbox-input-box">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height:
                  (!noNewFiles || !noOldFiles) && !docCondition ? "122px" : "",
                width: docCondition ? (isMobile ? "280px" : "450px") : "122px",
                paddingTop: docCondition ? "16px" : "",
                paddingBottom: docCondition ? "16px" : "",
                borderRadius: "12px",
                border:
                  docCondition || !noNewFiles || !noOldFiles
                    ? "dashed 1px #979797"
                    : "",
              }}
            >
              {props.description && (noNewFiles || noOldFiles) && (
                <p className="media-input-text">{props.description}</p>
              )}
              <label
                className="media-input-button"
                htmlFor={props.id}
                style={{ width: !noNewFiles || !noOldFiles ? "68px" : "" }}
              >
                <p className="media-input-button-text">{props.buttonText}</p>
              </label>
              <input
                id={props.id}
                type="file"
                multiple={props.multiple}
                /* {...props.register(props.name)} */
                style={{ display: "none" }}
                accept={props.accept}
                onChange={(e) => props.onChange(e, props.subType)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FileInput;
