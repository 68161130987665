import { useState } from "react";

const useToggle = (intialValue: boolean) => {
  const [isToggled, setIsToggled] = useState<boolean>(intialValue);
  const [isToggledSecondary, setIsToggledSecondary] =
    useState<boolean>(intialValue);

  const toggle = () => {
    setIsToggled((prevIsToggled: boolean) => {
      return !prevIsToggled;
    });
  };

  const toggleSecondary = () => {
    setIsToggledSecondary((prevIsToggled: boolean) => {
      return !prevIsToggled;
    });
  };

  return {
    isToggled,
    toggle,
    setIsToggled,
    isToggledSecondary,
    toggleSecondary,
    setIsToggledSecondary,
  };
};
export default useToggle;
