import { useContext, useEffect, useState } from "react";
import useClient from "../../api/hooks/useClients";
import MainContext from "../../context/MainContext";
import useIsMobile from "../../hooks/useIsMobile";
import { Client } from "../../interfaces";
import { filterByName } from "../../utils/filters/clientFilter";
import { clientRemappedFromGetApi } from "../../utils/remapping";
import ClientsCard from "./ClientsCard";

const ClientsTable = (props: any) => {
  const { isMobile } = useIsMobile();

  const { clientOnSearchName } = props;

  const { token } = useContext(MainContext);
  const getClientsHook = useClient(token);

  const [clientsFetched, setCientsFetched] = useState<Client[]>([]);
  const [clientsFiltered, setCientsFiltered] = useState<Client[]>([]);
  const [clientsDisplayed, setClientsDisplayed] = useState<Client[]>([]);

  const { setIsToggled } = props.loadMoreToggler; 

  /* ---GET CLIENTS---- */
  useEffect(() => {
    getClientsHook.CTAs.getListAll();
  }, [props.resetToggler.isToggled]);
  /* ------------------ */

  /* -------On Get Clients Response----------- */
  useEffect(() => {
    /* console.log(ApiB.response, ApiB.result); */
    if (getClientsHook.ApiB.response?.ok) {
      let finaArray: any = [];
      getClientsHook.ApiB.result?.clients.forEach((client: any) => {
        const remappedClient = clientRemappedFromGetApi(client);
        finaArray.push(remappedClient);
      });
      setCientsFetched(finaArray);
    }
  }, [getClientsHook.ApiB.response]);

  useEffect(() => {
    if (clientsFetched) {
      setCientsFiltered(clientsFetched);
    }
  }, [clientsFetched]);

  useEffect(() => {
    if (clientsFiltered) {
      const clientsOnDisplay = clientsFiltered; /* .slice(
        0,
        clientsPerLoad * props.loadMoreCounter
      ); */
      setClientsDisplayed(clientsOnDisplay);
    }
  }, [clientsFiltered /* , props.loadMoreCounter */]);

  useEffect(() => {
    if (clientsDisplayed.length < clientsFiltered.length) {
      setIsToggled(false);
    } else if (clientsDisplayed.length === clientsFiltered.length) {
      setIsToggled(true);
    }
  }, [clientsDisplayed]);

  /* ------------------------------------------- */

  useEffect(() => {
    if (clientOnSearchName !== "") {
      const filteredList = filterByName(clientsFetched, clientOnSearchName);
      setCientsFiltered(filteredList);
    } else setCientsFiltered(clientsFetched);
  }, [clientOnSearchName]);

  const renderClients = clientsDisplayed.map((client, key) => { 
    return (
      <div
        key={key}
        className="clients-table-row"
        style={{ cursor: "pointer" }}
      >
        <ClientsCard client={client} />
      </div>
    );
  });

  return (
    <div
      className="clients-table"
      style={{
        width: isMobile ? "calc(345px - 66px)" : "",
      }}
    >
      <div
        className="clients-table-fields"
        style={{
          margin: isMobile ? "0 28px 0 0" : "",
        }}
      >
        <div className="c-name" style={{ display: "flex" }}>
          <p className="field-label">Name</p>
        </div>
        {!isMobile && (
          <>
            <div className="c-referent" style={{ display: "flex" }}>
              <p className="field-label">Referent</p>
            </div>
            <div
              className="c-mail"
              style={{ display: "flex", justifyContent: "left" }}
            >
              <p className="field-label">E-mail</p>
            </div>
            <div
              className="c-location"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <p className="field-label">Final client</p>
            </div>
          </>
        )}
        {
          <div
            className="c-spent "
            style={{ display: "flex", justifyContent: "right" }}
          >
            <p className="field-label">Spent</p>
          </div>
        }
      </div>
      <div className="divider" style={{ width: "100%", marginTop: "10px" }} />
      <div className="offers-table-results scrollable-element">
        {renderClients}
      </div>
    </div>
  );
};
export default ClientsTable;
